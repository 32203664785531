import { accountGetDetails, accountResetForm, requestIcpException, showAccountTab } from './js/portal/accounts';
import { addButtonSpinner, addSpinner } from './js/portal/sidebar';
import AccountDropdown from './jsx/components/forms/AccountDropdown';
import { hasAccess } from './js/portal/main';

export default function AccountIcpExceptionPage() {
  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form name="icp-exception-account-form" id="icp-exception-account-form" method="POST">
          <div class="card form-card">
            <div class="card-header">Request AWS Account ICP Exception</div>
            <div class="card-body">
              <div id="form-error" class="alert alert-danger" style="display: none">
                <ul />
              </div>

              <div id="infobox" class="card infobox">
                <div class="card-body">
                  <p>
                    Due to the regulation requirement from AWS China, all AWS China account was requested to do ICP
                    exception before expose application to internet via TCP 80//8080/443. Find more information in our{' '}
                    <a
                      class="nobr"
                      href="https://developer.bmwgroup.net/docs/public-cloud-platform-aws/1_beforeyoustart/faq/faq_fpc/faq_fpc/#what-is-an-icp-and-do-i-need-to-file-for-icp-recordal-or-icp-license-if-i-want-to-host-public-content-on-aws-china-beijing-region-or-aws-china-ningxia-region"
                      target="_blank"
                      rel="noopener noreferrer">
                      <i class="fas fa-book" /> Developer Portal
                    </a>
                    &nbsp;
                    <small>(internal Documentation)</small>
                  </p>
                </div>
              </div>

              <div id="tabs" class="tab-content">
                {/* TAB 1: Select AWS Account */}
                <div id="account-selection" class="tab">
                  <AccountDropdown
                    id="aws-account-id"
                    short
                    lookup
                    onchange={(_eventTypeId, _eventCategory, _selectedOption, accountId) => {
                      document.getElementById('submitButton').disabled = !hasAccess(accountId, [
                        'manage_users',
                        'manage_organizations',
                      ]);
                    }}
                  />
                </div>

                {/* TAB 2: View AWS Account Information */}
                <div id="account-confirmation" class="tab">
                  <div class="form-group row">
                    <label class="col-form-label" />
                    <div class="col" id="account-details" />
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer" align="right">
              <input
                type="button"
                name="resetFormBtn"
                class="btn"
                onclick={() => accountResetForm('#icp-exception-account-form')}
                value="Reset"
              />
              <button id="submitButton" type="submit" class="btn btn-success" value="Continue">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                Continue
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  let currentTab = 0;

  /**
   * Load data as soon as the whole page is loaded
   */
  function onPageReady() {
    const submit_button = document.getElementById('submitButton');
    $('#icp-exception-account-form')
      .on('submit', function (e) {
        e.preventDefault();
        const account_id = $('#aws-account-id')[0].value.split(';')[0];

        addSpinner();
        addButtonSpinner();

        if (!account_id) {
          $('#account-selector').addClass('has-error');
        } else {
          $('#account-selector').removeClass('has-error');
        }
        $('#form-error').hide();
      })
      .validate({
        errorContainer: '#form-error',
        errorLabelContainer: '#form-error ul',
        wrapper: 'li',
        submitHandler: function () {
          const task = submit_button.value;

          switch (task) {
            case 'Continue':
              submit_button.value = 'Request ICP Exception';
              submit_button.innerHTML =
                '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Request ICP Exception';
              submit_button.setAttribute('class', 'btn btn-danger');
              showAccountTab(currentTab + 1);
              accountGetDetails('icp-exception-account-form');
              break;

            case 'Request ICP Exception':
              addSpinner();
              addButtonSpinner();
              requestIcpException();
              break;

            default:
              submit_button.value = 'Continue';
              submit_button.setAttribute('class', 'btn btn-success');
              showAccountTab(0);
          }
        },
      });

    showAccountTab(currentTab);
  }

  return [page, onPageReady];
}
