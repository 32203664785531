/**
 * Check if there's at least one setting enabled in the provided settings block.
 * Returns true if at least one setting is enabled, returns False if not.
 *
 * @param {object} settings contains an object with settings
 * @param {Array} ignoredSettings contains a list of setting items to skip
 * @returns boolean
 */
function settingsAreActive(settings, ignoredSettings) {
  for (const [key, value] of Object.entries(settings)) {
    // Skip all ignored settings as they might be always true (e.g. "send_email_notifications")
    if (ignoredSettings && ignoredSettings.includes(key)) {
      continue;
    }

    // Only if booleans are true or if lists are not empty, our automation will execute tasks
    if ((Array.isArray(value) && value.length) || (typeof value === 'boolean' && value)) {
      return true;
    }
  }

  // Finally return false if none of the settings are enabled
  return false;
}

/**
 * Returns some information about enabled settings for user creation or updates
 *
 * @param {object} props
 * @returns
 */
export default function IamUserSettings(props) {
  // Check if there are any active settings.
  const iamUserCreateConfig = settingsAreActive(props.iam_user_create_config);
  const iamUserUpdateConfig = settingsAreActive(props.iam_user_update_config, ['send_email_notifications']);

  // We only want to show the information/notes if settings are enabled for the selected AWS account.
  return (
    <>
      {(iamUserCreateConfig || iamUserUpdateConfig) && (
        <div class="col-md-12 col-lg-9 col-xl-10" id="iam-user-settings-accordion">
          <div class="accordion infobox mt-3 mb-1" id="create-user-infobox">
            {/* Contains all actions that will be executed for new IAM users */}
            {iamUserCreateConfig && (
              <div class="accordion-item">
                <h4 class="accordion-header" id="createIamUserSettings">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#createIamUserSettingsCollapse"
                    aria-expanded="true"
                    aria-controls="createIamUserSettingsCollapse">
                    <i class="fas fa-info-circle" /> &nbsp; Create new IAM user in your AWS account
                  </button>
                </h4>
                <div
                  id="createIamUserSettingsCollapse"
                  class="accordion-collapse collapse show"
                  aria-labelledby="createIamUserSettings"
                  data-bs-parent="#create-user-infobox">
                  <div class="accordion-body">
                    <p>
                      We'll create a new IAM user in your selected AWS account but we won't create any credentials
                      (access keys or login profile) for this user. Beside of the user creation, we will execute the
                      following tasks:
                    </p>
                    <ul>
                      <li>
                        Add the IAM user to the following IAM groups:{' '}
                        <strong class="portal-danger">
                          {props.iam_user_create_config.add_user_to_groups.join(', ')}
                        </strong>
                      </li>
                    </ul>
                    <p>
                      For more information please have a look in our{' '}
                      <a
                        href="https://developer.bmwgroup.net/docs/public-cloud-platform-aws/1_beforeyoustart/platformdoc/overview_iam/iam/#iam-service-users"
                        target="_blank"
                        rel="noopener noreferrer">
                        Developer Portal
                        <i class="px-1 fas fa-external-link-alt" />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            )}

            {/* Contains all actions that will be executed via update-iam-user-worker once a week */}
            {iamUserUpdateConfig && (
              <div class="accordion-item">
                <h4 class="accordion-header" id="updateIamUserSettings">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#updateIamUserSettingsCollapse"
                    aria-expanded="false"
                    aria-controls="updateIamUserSettingsCollapse">
                    <i class="fas fa-info-circle" /> &nbsp; Scheduled updates of all IAM users in your AWS account (once
                    a week on Monday 10:00am UTC)
                  </button>
                </h4>
                <div
                  id="updateIamUserSettingsCollapse"
                  class={iamUserCreateConfig ? 'accordion-collapse collapse' : 'accordion-collapse collapse show'}
                  aria-labelledby="updateIamUserSettings"
                  data-bs-parent="#create-user-infobox">
                  <div class="accordion-body">
                    <p>On a weekly schedule we will execute the following tasks:</p>
                    <ul>
                      {/* Login Profile Settings */}
                      {props.iam_user_update_config.login_profile_delete_unused && (
                        <li>
                          Delete login profiles if they are unused for{' '}
                          <strong class="portal-danger">
                            {String(props.iam_user_update_config.login_profile_delete_unused_days)} days
                          </strong>{' '}
                          or more
                        </li>
                      )}

                      {props.iam_user_update_config.login_profile_delete_force && (
                        <li>
                          Delete login profiles if they are{' '}
                          <strong class="portal-danger">
                            {String(props.iam_user_update_config.login_profile_delete_force_days)} days
                          </strong>{' '}
                          old or older
                        </li>
                      )}

                      {/* Access Key Settings */}
                      {props.iam_user_update_config.access_keys_deactivate_unused && (
                        <li>
                          Deactivate access keys if they are{' '}
                          <strong class="portal-danger">
                            unused for {String(props.iam_user_update_config.access_keys_deactivate_unused_days)} days
                          </strong>{' '}
                          or more
                        </li>
                      )}

                      {props.iam_user_update_config.access_keys_deactivate_force && (
                        <li>
                          Deactivate access keys if they are{' '}
                          <strong class="portal-danger">
                            {String(props.iam_user_update_config.access_keys_deactivate_force_days)} days
                          </strong>{' '}
                          old or older
                        </li>
                      )}

                      {props.iam_user_update_config.access_keys_delete_unused && (
                        <li>
                          Delete access keys if they are{' '}
                          <strong class="portal-danger">
                            unused for {String(props.iam_user_update_config.access_keys_delete_unused_days)} days
                          </strong>{' '}
                          or more
                        </li>
                      )}

                      {props.iam_user_update_config.access_keys_delete_force && (
                        <li>
                          Delete access keys if they are{' '}
                          <strong class="portal-danger">
                            {String(props.iam_user_update_config.access_keys_delete_force_days)} days
                          </strong>{' '}
                          old or older
                        </li>
                      )}

                      {/* IAM Group Settings */}
                      {props.iam_user_update_config.add_user_to_groups_force &&
                        props.iam_user_update_config.add_user_to_groups.length > 0 && (
                          <li>
                            Add all IAM users to the following IAM groups:{' '}
                            <strong class="portal-danger">
                              {props.iam_user_update_config.add_user_to_groups.join(', ')}
                            </strong>
                          </li>
                        )}

                      {props.iam_user_update_config.add_user_to_groups_permissions.length > 0 &&
                        props.iam_user_update_config.add_user_to_groups.length > 0 && (
                          <li>
                            Add all IAM users to the following IAM groups if they have highly elevated permissions:{' '}
                            <strong class="portal-danger">
                              {props.iam_user_update_config.add_user_to_groups.join(', ')}
                            </strong>
                          </li>
                        )}
                    </ul>
                    <p>
                      We will inform the AWS account responsibles for every executed task. For more information please
                      have a look in our{' '}
                      <a
                        href="https://developer.bmwgroup.net/docs/public-cloud-platform-aws/1_beforeyoustart/platformdoc/overview_iam/iam/#iam-service-users"
                        target="_blank"
                        rel="noopener noreferrer">
                        Developer Portal
                        <i class="px-1 fas fa-external-link-alt" />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
