import { hideApiKeyEditForm, validateApiKeyEditForm } from './js/portal/apikeys';

export default function ApiKeyEditForm() {
  return (
    <>
      {/* EDIT API KEY FORM */}
      <div class="col-lg-12">
        <form name="edit-form" id="edit-form" method="POST">
          <div class="card form-card">
            <div id="card-heading-text" class="card-header">
              Edit Api Key Record in DynamoDB
            </div>

            <div class="card-body">
              {/* WARNING AND SUCCESS */}
              <div>
                <div id="form-error" class="alert alert-danger" style="display: none">
                  <ul />
                </div>
                <div id="form-success" class="alert alert-success" style="display: none">
                  <ul />
                </div>
              </div>

              <div>
                {/* FIRST COLUMN OF THE FORM */}
                <div class="col-lg-6 col-md-12 col-12">
                  {/* API KEY ID */}
                  <div class="form-group row" id="form_group_key_id">
                    <label for="api_key_id" class="col-form-label">
                      Key Id
                    </label>
                    <div class="col">
                      <input type="text" class="form-control" id="api_key_id" name="api_key_id" disabled />
                    </div>
                  </div>
                  {/* FRIEDLY NAME */}
                  <div class="form-group row" id="form_group_custom_name">
                    <label for="custom_name" class="col-form-label">
                      Friendly Name
                    </label>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        id="custom_name"
                        name="custom_name"
                        placeholder="e.g. Friendly API Key Name"
                      />
                    </div>
                  </div>
                  {/* FRIENDLY DESCRIPTION */}
                  <div class="form-group row" id="form_group_custom_description">
                    <label for="custom_description" class="col-form-label">
                      Friendly Description
                    </label>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        id="custom_description"
                        name="custom_description"
                        placeholder="e.g. Friendly API Key Description"
                      />
                    </div>
                  </div>
                  {/* USAGE PLAN ID */}
                  <div class="form-group row" id="form_group_usage_plan_id" style="display: none">
                    <label for="usage_plan_id" class="col-form-label">
                      Usage Plan Id
                    </label>
                    <div class="col">
                      <input type="text" class="form-control" id="usage_plan_id" name="usage_plan_id" disabled />
                    </div>
                  </div>
                  {/* TARGET API ONLY FOR CONTROL */}
                  <div class="form-group row" id="form_group_target_api" style="display: none">
                    <label for="target_api" class="col-form-label">
                      Target Api
                    </label>
                    <div class="col">
                      <input type="text" class="form-control" id="target_api" name="target_api" disabled />
                    </div>
                  </div>
                  {/* ACCOUNT ID ONLY FOR CONTROL */}
                  <div class="form-group row" id="form_group_provider_id" style="display: none">
                    <label for="provider_id" class="col-form-label">
                      Accont Id
                    </label>
                    <div class="col">
                      <input type="text" class="form-control" id="provider_id" name="provider_id" disabled />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer" align="right">
              <input
                type="button"
                class="btn btn-custom"
                name="hide-edit-form"
                id="hide-edit-form"
                value="Cancel"
                onclick={() => hideApiKeyEditForm()}
              />
              <input
                type="button"
                class="btn btn-success"
                name="update-api-key"
                id="update-api-key"
                value="Update Api Key"
                onclick={() => validateApiKeyEditForm()}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
