import TrainingTypeDropdown from './jsx/components/forms/TrainingTypeDropdown';
import UserDropdown from './jsx/components/forms/UserDropdown';
import {
  setTrainingAccountCount,
  planTraining,
  initPlanTrainingForm,
  setTrainingEndDate,
  updatePreparationAndCleanupDates,
} from './js/portal/trainingsPlan';
import { generateRandom } from './js/portal/main';
import { addButtonSpinner, addSpinner, removeSpinners } from './js/portal/sidebar';

/**
 *
 * @param {string} label - name label on the left side of the dropdown menu
 * @param {string} idPrefix - the id prefix of the selectpicker
 * @param {string} id - the id of the selectpicker
 * @param {string} helpText - a helptext used as title for the label
 * @param {string} selectedValue - the target date which will be used as initial value
 * @param {function} onchange - a function that will be triggered once the date value changes
 * @param {boolean} disabled - defines if the input will be disabled
 *
 * @returns JSX.Element
 */
function DatePicker({ label, idPrefix, id, helpText, selectedValue, onchange, disabled }) {
  const labelText = label ? label : 'Date';
  const dateIdPrefix = idPrefix ? idPrefix : '';
  const dateId = id ? id : 'date';
  const input = (
    <input
      type="date"
      id={dateIdPrefix + dateId}
      name="date"
      class="form-control"
      value={selectedValue}
      required
      disabled={disabled ? true : false}
    />
  );
  if (onchange) {
    $(input).on('change', () => {
      onchange({ id });
    });
  }

  return (
    <div class="form-group row">
      <label for="startDate" class={helpText ? 'col-form-label cursor-help' : 'col-form-label'} title={helpText}>
        {labelText}
      </label>
      <div class="col">
        <div id="inputGroupActiveFrom" class="input-group datetime-input-group has-validation">
          {input}
        </div>
      </div>
    </div>
  );
}

/**
 *
 * @param {string} id - the id suffix for all the form components
 * @param {string} type - the target AWS training type
 * @param {type[]} trainers - a list with selected trainers
 * @param {string} startDate - a date string in iso format
 * @param {string} endDate - a date string in iso format
 * @param {number} accountsRequested - the number of required AWS accounts for the training
 * @param {string} notes - optional notes for the training
 *
 * @returns JSX.Element
 */
export function TrainingPlanFormBody({ id, type, trainers, startDate, endDate, accountsRequested, notes }) {
  return (
    <div id={'form-' + id}>
      {/* TRAINING TYPE */}
      <TrainingTypeDropdown
        id={id}
        idPrefix="type-"
        label="Type"
        selectedValue={type}
        onchange={updatePreparationAndCleanupDates}
      />

      {/* TRAINERS */}
      <UserDropdown id={'trainers-' + id} label="Trainers" selectedValues={trainers} deselect multiple />

      {/* PREPARATION DATE */}
      <DatePicker
        id={id}
        idPrefix="preparation-date-"
        label="Preparation Date"
        helpText="On this date we'll provide access to all training accounts, so you can start with your training preparation."
        disabled
      />

      {/* START DATE */}
      <DatePicker
        id={id}
        idPrefix="start-date-"
        label="Start Date"
        helpText="The first day of your actual training"
        selectedValue={startDate}
        onchange={setTrainingEndDate}
        required
      />

      {/* END DATE */}
      <DatePicker
        id={id}
        idPrefix="end-date-"
        label="End Date"
        helpText="The last day of your training. After this date, we'll start the deletion process of all training resources and remove account level access of trainers and participants"
        selectedValue={endDate}
        onchange={setTrainingAccountCount}
        required
      />

      {/* CLEANUP DATE */}
      <DatePicker
        id={id}
        idPrefix="cleanup-date-"
        label="Clean-up End Date"
        helpText="The time between the training end date and the clean-up end date is a safetey period between the end of an training and the beginning of a new training"
        disabled
      />

      {/* PARTICIPANT NUMBER */}
      <div class="form-group row">
        <label
          for="numberAccounts"
          class="col-form-label cursor-help"
          title="The number of required AWS accounts for your training. Must be at least 1 account. The maximum number of accounts depends on the availability of training accounts within the selected time period">
          AWS Accounts
        </label>
        <div class="col">
          <div id={'number-accounts-group-' + id} class="input-group has-validation">
            <input
              type="number"
              id={'number-accounts-' + id}
              name="numberAccounts"
              class="form-control has-validation"
              title="Number of required AWS accounts for the training"
              max="20"
              min="1"
              value={accountsRequested}
              required
            />
          </div>
          <div class="invalid-feedback" id="accounts-invalid-feedback">
            No AWS accounts available within the selected time period.
          </div>
        </div>
      </div>

      {/* NOTES */}
      <div class="form-group row">
        <label for="notes" class="col-form-label">
          Notes
        </label>
        <div class="col">
          <textarea rows="4" id={'notes-' + id} name="notes" class="form-control">
            {notes}
          </textarea>
        </div>
      </div>
    </div>
  );
}

/**
 *
 * @param {string} id
 *
 * @returns JSX.Element
 */
export function TrainingsPlanForm({ id }) {
  return (
    <div class="row">
      <div class="col-lg-12">
        <form name="plan-training-form" id="plan-training-form" class="needs-validation" method="POST">
          <div class="card form-card">
            {/* CARD HEADER */}
            <div class="card-header">Plan new AWS Training</div>

            {/* CARD BODY */}
            <div class="card-body">
              <TrainingPlanFormBody id={id} />
            </div>
            {/* CARD BODY END */}

            {/* CARD FOOTER */}
            <div class="card-footer">
              <div class="row px-2">
                <div class="col">
                  <button
                    type="button"
                    name="resetFormBtn"
                    class="btn btn-secondary"
                    onclick={() => initPlanTrainingForm(id)}
                    value="Reset">
                    Reset
                  </button>
                </div>
                <div class="col" align="right">
                  <button type="submit" class="btn btn-success" id="submitButton" value="Plan Training">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    Plan Training
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default function TrainingsPlanPage() {
  const id = generateRandom();
  const page = <TrainingsPlanForm id={id} />;

  function onPageReady() {
    initPlanTrainingForm(id);
    $('#plan-training-form').on('submit', function (e) {
      e.preventDefault();
      addSpinner();
      addButtonSpinner();
      const response = planTraining(id);
      response.finally(() => {
        removeSpinners();
      });
    });
  }

  return [page, onPageReady];
}
