import { InfoTable, InfoTableWide } from '../../../js/portal/main';

const SUBNET_INFO_FIELDS = [
  { Name: 'Subnet CIDR', Value: 'cidr' },
  { Name: 'Subnet Id', Value: 'subnet_id' },
  { Name: 'Subnet Name', Value: 'name' },
  { Name: 'Subnet Type', Value: 'subnet_type' },
  { Name: 'Availability Zone Id', Value: 'availability_zone_id' },
  { Name: 'Availability Zone Name', Value: 'availability_zone_name' },
  { Name: 'IPs', Value: 'ips_number' },
  { Name: 'IPs Free', Value: 'ips_free' },
];

export default function VPCCompare(props) {
  const { data, sections, column } = props;

  if (!data.network_item) data.network_item = {};
  if (!data.account_item) data.account_item = {};

  const { account_item, network_item } = data;

  let visibility = {
    vpc: false,
    subnets: false,
    peerings: false,
    dx: false,
    r53: false,
    logging: false,
  };

  for (const section of sections) {
    visibility[section] = true;
  }

  const subnetsData =
    Object.keys(network_item.subnets || {}).length &&
    (network_item.subnets.public.length || network_item.subnets.private.length || network_item.subnets.intranet.length);
  const peeringsData = Object.keys(network_item.vpc_peerings || {}).length;
  const dxData =
    Object.keys(network_item.dx_virtual_interfaces || {}).length ||
    Object.keys(network_item.dx_gw_associations || {}).length ||
    network_item.dx_association_proposals?.length;
  const r53Data =
    Object.keys(network_item.dhcp_options || {}).length || Object.keys(network_item.associated_r53r_rules || {}).length;
  const loggingData =
    Object.keys(network_item.flow_logs || {}).length || Object.keys(network_item.query_logging_configs || {}).length;

  const noData = () => {
    return (
      <div class="row">
        <div class="col-12">
          <div class="detailsContent">
            <h4 class="mb-0">No data available</h4>
          </div>
        </div>
      </div>
    );
  };

  // Subnets & Routing
  const subnetHelper = (subnet, prefix) => {
    return (
      <div class="row my-3">
        <div class="col-md-12 my-3 detailsSingleSubnetLeft">
          <InfoTable data={subnet} rowInfos={SUBNET_INFO_FIELDS} prefix={prefix} />
        </div>
        {Object.keys(subnet.routing_table?.Routes || {}).length > 0 && (
          <div class="col-md-12 my-3 detailsSingleSubnetRight compare-overflow-content">
            <InfoTableWide
              data={subnet.routing_table.Routes}
              colInfos={[{ Name: 'Destination' }, { Name: 'Target' }, { Name: 'State' }]}
              rowNumbers={true}
              prefix={`${prefix}-routing_table-Routes`}
            />
          </div>
        )}
      </div>
    );
  };

  // Peerings
  const peeringItems = network_item.vpc_peerings?.map(item => {
    return {
      VpcPeeringConnectionId: item.VpcPeeringConnectionId,
      Status: item.Status.Message,
      RequesterOwnerId: item.RequesterVpcInfo.OwnerId,
      RequesterCidr: item.RequesterVpcInfo.CidrBlock,
      RequesterRegion: item.RequesterVpcInfo.Region,
      RequesterVpcId: item.RequesterVpcInfo.VpcId,
      AccepterOwnerId: item.AccepterVpcInfo.OwnerId,
      AccepterCidr: item.AccepterVpcInfo.CidrBlock,
      AccepterRegion: item.AccepterVpcInfo.Region,
      AccepterVpcId: item.AccepterVpcInfo.VpcId,
    };
  });

  return (
    <>
      {visibility['vpc'] ? (
        <div
          class={`col-6 compare-section-container section-vpc content-block-${column}`}
          id={'vpc' + network_item.vpc_id}>
          <div class="row" name="vpc-container">
            <div class="col-12">
              <div class="detailsContent">
                <div>
                  <InfoTable
                    data={network_item}
                    rowInfos={[
                      { Name: 'Account Id', Value: 'aws_account_id' },
                      { Name: 'VPC ID', Value: 'vpc_id' },
                      { Name: 'Name', Value: 'name' },
                      { Name: 'Primary CIDR', Value: 'cidr' },
                      { Name: 'Secondary CIDRs', Value: 'secondary_cidrs' },
                      { Name: 'Region', Value: 'aws_region' },
                      { Name: 'Network Type', Value: 'network_type' },
                      { Name: 'Comment', Value: 'comment' },
                      { Name: 'Creation Date', Value: 'creation_date' },
                      { Name: 'Netbond CIDR', Value: 'netbond_cidr' },
                      { Name: 'On Premise Connection', Value: 'dx' },
                      { Name: 'Compliance Status', Value: 'compliance_status' },
                      { Name: 'Compliance Details', Value: 'compliance_details' },
                      { Name: 'VPC Status', Value: 'status' },
                      { Name: 'Order Id', Value: 'order_id' },
                      { Name: 'Error Details', Value: 'error_details' },
                      { Name: 'Last Status Update (UTC)', Value: 'status_update' },
                    ]}
                    prefix="network_item"
                  />
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="detailsContent">
                <h4>Account Details</h4>
                <div>
                  <InfoTable
                    data={account_item}
                    rowInfos={[
                      { Name: 'Account Id', Value: 'account_id' },
                      { Name: 'Root Account', Value: 'account_root_email' },
                      { Name: 'Friendly Name', Value: 'account_friendly_name' },
                      { Name: 'Description', Value: 'description' },
                      { Name: 'FPC Status', Value: 'fpc_status' },
                      { Name: 'Account Area', Value: 'account_area' },
                      { Name: 'Account Type', Value: 'account_type' },
                      { Name: 'Account Stage', Value: 'account_stage' },
                      { Name: 'AWS Support Plan', Value: 'support_plan' },
                      { Name: 'IT Responsible', Value: 'it_responsible' },
                      { Name: 'Primary Responsible', Value: 'primary_responsible' },
                      { Name: 'Secondary Responsible', Value: 'sec_responsible' },
                      { Name: 'APP ID', Value: 'appid' },
                      { Name: 'APPD ID', Value: 'appdid' },
                      { Name: 'Cost Center', Value: 'cost_center' },
                      { Name: 'PSO/PSP', Value: 'pso' },
                      { Name: 'Creation Date', Value: 'creation_date' },
                    ]}
                    prefix="account_item"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div class={`col-6 section-vpc content-block-${column}`} id={'vpc' + network_item.vpc_id} />
      )}

      {visibility['subnets'] ? (
        <div
          class={`col-6 compare-section-container section-subnets content-block-${column}`}
          id={'subnets' + network_item.vpc_id}>
          {subnetsData ? (
            <div class="row" name="subnets-container">
              <div class="col-12">
                <div class="detailsContent">
                  {network_item.subnets?.public?.length && (
                    <>
                      <h5 class="headline mt-3">Public Subnets</h5>
                      <p class="mb-2">
                        Public Subnets have a direct route to an Internet Gateway. All resources must have a public IP
                        address in order to have an Internet connection.
                      </p>
                      {network_item.subnets.public.map((item, idx) =>
                        subnetHelper(item, `network_item-subnets-public-${idx}`)
                      )}
                    </>
                  )}

                  {network_item.subnets?.private?.length && (
                    <>
                      <h5 class="headline mt-3">Private Subnets</h5>
                      <p class="mb-2">
                        Private Subnets don't have a direct connection to the Internet or to the BMW on premise network.
                      </p>
                      {network_item.subnets.private.map((item, idx) =>
                        subnetHelper(item, `network_item-subnets-private-${idx}`)
                      )}
                    </>
                  )}
                  {network_item.subnets?.intranet?.length && (
                    <>
                      <h5 class="headline mt-3">Intranet Subnets</h5>
                      <p class="mb-2">Intranet Subnets are directly connected to the BMW on premise network.</p>
                      {network_item.subnets.intranet.map((item, idx) =>
                        subnetHelper(item, `network_item-subnets-intranet-${idx}`)
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            noData()
          )}
        </div>
      ) : (
        <div class={`col-6 section-subnets content-block-${column}`} id={'subnets' + network_item.vpc_id} />
      )}

      {visibility['peerings'] ? (
        <div
          class={`col-6 compare-section-container section-peerings content-block-${column}`}
          id={'peerings' + network_item.vpc_id}>
          {peeringsData ? (
            <div class="row" name="peerings-container">
              <div class="col-12">
                <div class="detailsContent compare-overflow-content">
                  <div>
                    <InfoTableWide
                      data={peeringItems || []}
                      colInfos={[
                        { Name: 'VpcPeeringConnectionId', Ref: 'VpcPeeringConnectionId' },
                        { Name: 'Status', Ref: 'Status-Message' },
                        { Name: 'RequesterOwnerId', Ref: 'RequesterVpcInfo-OwnerId' },
                        { Name: 'RequesterCidr', Ref: 'RequesterVpcInfo-CidrBlock' },
                        { Name: 'RequesterRegion', Ref: 'RequesterVpcInfo-Region' },
                        { Name: 'RequesterVpcId', Ref: 'RequesterVpcInfo-VpcId' },
                        { Name: 'AccepterOwnerId', Ref: 'AccepterVpcInfo-OwnerId' },
                        { Name: 'AccepterCidr', Ref: 'AccepterVpcInfo-CidrBlock' },
                        { Name: 'AccepterRegion', Ref: 'AccepterVpcInfo-Region' },
                        { Name: 'AccepterVpcId', Ref: 'AccepterVpcInfo-VpcId' },
                      ]}
                      rowNumbers={true}
                      prefix="network_item-vpc_peerings"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            noData()
          )}
        </div>
      ) : (
        <div class={`col-6 section-peerings content-block-${column}`} id={'peerings' + network_item.vpc_id} />
      )}

      {visibility['dx'] ? (
        <div
          class={`col-6 compare-section-container section-dx content-block-${column}`}
          id={'dx' + network_item.vpc_id}>
          {dxData ? (
            <div class="row" name="dx-container">
              <div class="col-12">
                {Object.keys(network_item.dx_virtual_interfaces || {}).length > 0 && (
                  <div class="detailsContent compare-overflow-content">
                    <h4>Direct Connect Interfaces</h4>
                    <div>
                      <InfoTableWide
                        data={network_item.dx_virtual_interfaces}
                        colInfos={[
                          { Name: 'region' },
                          { Name: 'addressFamily' },
                          { Name: 'virtualGatewayId' },
                          { Name: 'virtualInterfaceId' },
                          { Name: 'virtualInterfaceName' },
                          { Name: 'virtualInterfaceState' },
                          { Name: 'virtualInterfaceType' },
                          { Name: 'amazonAddress' },
                          { Name: 'amazonSideAsn' },
                          { Name: 'customerAddress' },
                          { Name: 'asn' },
                          { Name: 'vlan' },
                          { Name: 'directConnectGatewayId' },
                        ]}
                        prefix="network_item-dx_virtual_interfaces"
                      />
                    </div>
                  </div>
                )}
                {Object.keys(network_item.dx_gw_associations || {}).length > 0 && (
                  <div class="detailsContent compare-overflow-content">
                    <h4>Direct Connect Gateways</h4>
                    <div>
                      <InfoTableWide
                        data={network_item.dx_gw_associations}
                        colInfos={[
                          { Name: 'associationId' },
                          { Name: 'associationState' },
                          { Name: 'directConnectGatewayId' },
                          { Name: 'directConnectGatewayOwnerAccount' },
                          { Name: 'virtualGatewayId' },
                          { Name: 'virtualGatewayOwnerAccount' },
                          { Name: 'virtualGatewayRegion' },
                        ]}
                        prefix="network_item-dx_gw_associations"
                      />
                    </div>
                  </div>
                )}
                {network_item.dx_association_proposals?.length &&
                  network_item.dx_association_proposals.map((proposal, idx) => (
                    <div class="detailsContent compare-overflow-content">
                      <h4>Direct Connect Gateway Association {JSON.stringify(proposal.proposalId)} Proposal</h4>
                      <div>
                        <InfoTableWide
                          data={[proposal]}
                          colInfos={[
                            { Name: 'directConnectGatewayId' },
                            { Name: 'directConnectGatewayOwnerAccount' },
                            { Name: 'proposalId' },
                            { Name: 'proposalState' },
                          ]}
                          prefix={`network_item-dx_association_proposals-${idx}`}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            noData()
          )}
        </div>
      ) : (
        <div class={`col-6 section-dx content-block-${column}`} id={'dx' + network_item.vpc_id} />
      )}

      {visibility['r53'] ? (
        <div
          class={`col-6 compare-section-container section-r53 content-block-${column}`}
          id={'r53' + network_item.vpc_id}>
          {r53Data ? (
            <div class="row" name="r53-container">
              <div class="col-12">
                {Object.keys(network_item.dhcp_options || {}).length > 0 && (
                  <div class="detailsContent compare-overflow-content">
                    <h4>DNS Configuration</h4>
                    <div>
                      <InfoTable
                        data={network_item.dhcp_options}
                        rowInfos={[
                          { Value: 'domain-name', Name: 'Domain Name' },
                          { Value: 'domain-name-servers', Name: 'Domain Name Server' },
                        ]}
                        prefix="network_item-dhcp_options"
                      />
                    </div>
                  </div>
                )}
              </div>
              <div class="col-12">
                {Object.keys(network_item.associated_r53r_rules || {}).length > 0 && (
                  <div class="detailsContent compare-overflow-content">
                    <h4>Route 53 Resolver Rules</h4>
                    <div>
                      <InfoTableWide
                        data={network_item.associated_r53r_rules}
                        colInfos={[
                          { Name: 'DomainName' },
                          { Name: 'RuleType' },
                          { Name: 'ShareStatus' },
                          { Name: 'OwnerId' },
                        ]}
                        rowNumbers={true}
                        prefix="network_item-associated_r53r_rules"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            noData()
          )}
        </div>
      ) : (
        <div class={`col-6 section-r53 content-block-${column}`} id={'r53' + network_item.vpc_id}></div>
      )}

      {visibility['logging'] ? (
        <div
          class={`col-6 compare-section-container section-logging content-block-${column}`}
          id={'logging' + network_item.vpc_id}>
          {loggingData ? (
            <div class="row" name="logging-container">
              <div class="col-12">
                {Object.keys(network_item.flow_logs || {}).length > 0 && (
                  <div class="detailsContent compare-overflow-content">
                    <h4>VPC Flow Logs</h4>
                    <div>
                      <InfoTableWide
                        data={network_item.flow_logs}
                        colInfos={[
                          { Name: 'LogDestinationType' },
                          { Name: 'TrafficType' },
                          { Name: 'CreationTime' },
                          { Name: 'FlowLogStatus' },
                          { Name: 'LogGroupName' },
                          { Name: 'LogDestination' },
                        ]}
                        prefix="network_item-flow_logs"
                      />
                    </div>
                  </div>
                )}
                {Object.keys(network_item.query_logging_configs || {}).length > 0 && (
                  <div class="detailsContent compare-overflow-content">
                    <h4>Route 53 Resolver Query Logging Config</h4>
                    <div>
                      <InfoTableWide
                        data={network_item.query_logging_configs}
                        colInfos={[
                          { Name: 'Name' },
                          { Name: 'Id' },
                          { Name: 'CreationTime' },
                          { Name: 'OwnerId' },
                          { Name: 'Status' },
                          { Name: 'AssociationCount' },
                          { Name: 'DestinationArn' },
                        ]}
                        prefix="network_item-query_logging_configs"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            noData()
          )}
        </div>
      ) : (
        <div class={`col-6 section-logging content-block-${column}`} id={'logging' + network_item.vpc_id} />
      )}
    </>
  );
}
