import swal from 'sweetalert';
import {
  showCompleteWorkOrderModal,
  updateIsoValue,
  updateIsoValueEOD,
  updateWorkOrder,
} from '../../../js/portal/work_orders';
import DatePickerField from './DatePickerField';
import UserDropdown from './UserDropdown';

function dropdownOptionHelper(selected_value, option_values) {
  let dropdown_options = [];
  for (const value of option_values) {
    if (value == selected_value) {
      dropdown_options.push(
        <option value={value} selected>
          {value}
        </option>
      );
    } else {
      dropdown_options.push(<option value={value}>{value}</option>);
    }
  }
  return dropdown_options;
}

function updateWorkOrderHandler(work_order_id) {
  const params = {
    work_order_id: work_order_id,
  };
  let update_payload = Object.fromEntries(
    new FormData(document.getElementById(`update-form-${work_order_id}`)).entries()
  );

  switch (update_payload['status']) {
    case 'Planning':
      update_payload['status_reason'] = 'Accepted';
      break;
    case 'In Progress':
      update_payload['status_reason'] = 'Assigned';
      break;
    case 'Cancelled':
      update_payload['status_reason'] = 'Cancelled by Requester';
      break;
    case 'Completed':
      if (!update_payload['status_reason']) {
        closeWorkOrderHandler(work_order_id);
        return;
      }
      break;
    default:
      break;
  }

  swal({
    title: 'Are you sure you want to update this Work Order?',
    text: `Id: ${work_order_id}`,
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: false,
        visible: true,
      },
      okay: {
        text: 'Accept',
        value: true,
        visible: true,
      },
    },
  }).then(function (response) {
    if (response) {
      updateWorkOrder(params, update_payload);
    }
  });
}

function closeWorkOrderHandler(work_order_id) {
  showCompleteWorkOrderModal(updateWorkOrder, { work_order_id: work_order_id });
}

export default function WorkOrderTabs(props) {
  const { data } = props;

  const work_order_id = data.work_order_id;

  const status_options = {
    Planning: ['Planning', 'In Progress', 'Cancelled', 'Completed'],
    'In Progress': ['In Progress', 'Cancelled', 'Completed'],
    Completed: ['Planning', 'Completed'],
    Cancelled: ['Cancelled'],
  };

  const now_iso = new Date().toISOString().replace('T', ' ');
  const now_date_value = now_iso.slice(0, 10);

  return (
    <div class="detailsContainer">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a
            class="nav-link active"
            data-bs-target={'#project_' + work_order_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="true"
            aria-expanded="true">
            Work Order
          </a>
        </li>
      </ul>
      <div class="tab-content detailsTab">
        <div class="tab-pane fade active show" id={'project_' + work_order_id} role="tabpanel">
          <div class="row" name="project-container">
            <div class="col-lg-12 col-xl-12">
              <div class="detailsContent">
                <h4>Work Order Details</h4>
                <div>
                  <form id={`update-form-${work_order_id}`}>
                    {/* WORK ORDER ID */}
                    <div class="form-group row">
                      <label for="work-order-id" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                        <span class="float-md-start float-lg-end">Work Order Id</span>
                      </label>
                      <div class="col-md-12 col-lg-9 col-xl-10">
                        <input
                          type="text"
                          id="work-order-id"
                          name="work-order-id"
                          class="form-control"
                          title="Work Order Id"
                          value={data.work_order_id}
                          disabled
                        />
                      </div>
                    </div>

                    {/* PRIORITY */}
                    <div class="form-group row portal-dropdown no-buttons">
                      <label for="priority" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                        <span class="float-md-start float-lg-end">Priority</span>
                      </label>
                      <div class="col-md-12 col-lg-9 col-xl-10">
                        <select class="form-control form-select" id="priority" name="priority" disabled>
                          {dropdownOptionHelper(data.priority, ['Low', 'Medium', 'High', 'Critical'])}
                        </select>
                      </div>
                    </div>

                    {/* SUMMARY */}
                    <div class="form-group row">
                      <label for="summary" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                        <span class="float-md-start float-lg-end">Summary</span>
                      </label>
                      <div class="col-md-12 col-lg-9 col-xl-10">
                        <input
                          type="text"
                          id="summary"
                          name="summary"
                          class="form-control"
                          title="Summary of the Work Order"
                          value={data.summary}
                          maxlength="95"
                          disabled={data.status === 'Cancelled'}
                        />
                      </div>
                    </div>

                    {/* DESCRIPTION */}
                    <div class="form-group row">
                      <label for="description" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                        <span class="float-md-start float-lg-end">Description</span>
                      </label>
                      <div class="col-md-12 col-lg-9 col-xl-10">
                        <textarea
                          rows="4"
                          id="description"
                          name="description"
                          class="form-control"
                          disabled={data.status === 'Cancelled'}>
                          {data.description}
                        </textarea>
                      </div>
                    </div>

                    {/* CREATED DATE */}
                    <div class="form-group row">
                      <label for="audit-created-at" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                        <span class="float-md-start float-lg-end">Creation Date</span>
                      </label>
                      <div class="col-md-12 col-lg-9 col-xl-10">
                        <input
                          type="text"
                          id="audit-created-at"
                          class="form-control"
                          title="Creation Date of the Work Order"
                          value={data.audit_created_at && data.audit_created_at.slice(0, 16)}
                          disabled
                        />
                      </div>
                    </div>

                    {/* UPDATED DATE */}
                    <div class="form-group row">
                      <label for="audit-created-at" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                        <span class="float-md-start float-lg-end">Last Update</span>
                      </label>
                      <div class="col-md-12 col-lg-9 col-xl-10">
                        <input
                          type="text"
                          id="audit-updated-at"
                          class="form-control"
                          title="Last Update of the Work Order"
                          value={data.audit_updated_at && data.audit_updated_at.slice(0, 16)}
                          disabled
                        />
                      </div>
                    </div>

                    {/* ACTUAL START DATE */}
                    <div class="form-group row">
                      <label for="actual-start-date" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                        <span class="float-md-start float-lg-end">Actual Start Date</span>
                      </label>
                      <div class="col-md-12 col-lg-5 col-xl-5">
                        <DatePickerField
                          id="actual_start_date"
                          selectedValue={
                            data.actual_start_date && new Date(data.actual_start_date).toISOString().slice(0, 10)
                          }
                          minValue={now_date_value}
                          onchange={(id, selectedValue) => {
                            updateIsoValue(id, selectedValue);
                            $('#actual_start_time').val('00:00');
                            const end_date_field = $('#actual_end_date');
                            end_date_field.attr('min', selectedValue);
                            if (new Date(selectedValue).getTime() > new Date(end_date_field.val()).getTime()) {
                              end_date_field.val(selectedValue);
                              updateIsoValueEOD('actual_end_date', selectedValue);
                            }
                          }}
                          disabled={data.status === 'Cancelled'}
                        />
                        <input type="hidden" name="actual_start_date" value={data.actual_start_date} />
                      </div>
                      <div class="col-md-12 col-lg-4 col-xl-5">
                        <input
                          type="text"
                          id="actual_start_time"
                          class="form-control"
                          value={data.actual_start_date && new Date(data.actual_start_date).toISOString().slice(11, 16)}
                          disabled
                        />
                      </div>
                    </div>

                    {/* ACTUAL END DATE */}
                    <div class="form-group row">
                      <label for="actual_end_date" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                        <span class="float-md-start float-lg-end">Actual End Date</span>
                      </label>
                      <div class="col-md-12 col-lg-5 col-xl-5">
                        <DatePickerField
                          id="actual_end_date"
                          selectedValue={
                            data.actual_start_date && new Date(data.actual_end_date).toISOString().slice(0, 10)
                          }
                          minValue={now_date_value}
                          onchange={(id, selectedValue) => {
                            updateIsoValueEOD(id, selectedValue);
                            $('#actual_end_time').val('23:59');
                          }}
                          disabled={data.status === 'Cancelled'}
                        />
                        <input type="hidden" name="actual_end_date" value={data.actual_end_date} />
                      </div>
                      <div class="col-md-12 col-lg-4 col-xl-5">
                        <input
                          type="text"
                          id="actual_end_time"
                          class="form-control"
                          value={data.actual_end_date && new Date(data.actual_end_date).toISOString().slice(11, 16)}
                          disabled
                        />
                      </div>
                    </div>

                    {/* ASSIGNEE */}
                    <div class="form-group row">
                      <label for="assignee" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                        <span class="float-md-start float-lg-end">Assignee</span>
                      </label>
                      <div class="col-md-12 col-lg-9 col-xl-10">
                        {data.status === 'Cancelled' ? (
                          <input type="text" class="form-control" value={data.assignee} disabled />
                        ) : (
                          <UserDropdown label="none" selectedValues={[data.assignee]} disableUrlParams id="assignee" />
                        )}
                      </div>
                    </div>

                    {/* WORK ORDER STATUS */}
                    <div class="form-group row portal-dropdown no-buttons">
                      <label for="status" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                        <span class="float-md-start float-lg-end">Work Order Status</span>
                      </label>
                      <div class="col-md-12 col-lg-9 col-xl-10">
                        <select
                          class="form-control form-select"
                          id="status"
                          name="status"
                          disabled={data.status === 'Cancelled'}>
                          {dropdownOptionHelper(data.status, status_options[data.status])}
                        </select>
                        {data.status === 'Completed' && (
                          <input type="hidden" name="status_reason" value={data.status_reason} />
                        )}
                      </div>
                    </div>

                    <div class="col-lg-12 col-xl-12">
                      <div class="detailsContent">
                        {data.status != 'Cancelled' && (
                          <div class="btn-group detail-actions-btn-group">
                            <button
                              type="button"
                              id="btn-update-work-order"
                              class="btn btn-success"
                              onclick={() => {
                                updateWorkOrderHandler(work_order_id);
                              }}>
                              <span class="fas fa-sync-alt"></span> Update Work Order
                            </button>
                            <button
                              type="button"
                              id="btn-close-work-order"
                              class="btn btn-secondary"
                              onclick={() => {
                                closeWorkOrderHandler(work_order_id);
                              }}>
                              <span class="fas fa-check"></span> Close Work Order
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
