import { baseApiAxios, showError } from './js/portal/api';
import { Datatable } from './Datatable';
import { addOrgDetails, loadOrgAccountData, initOrganizationsAccountsTable, ouUpdate } from './js/portal/organizations';
import { addButtonSpinner, addSpinners, removeSpinners } from './js/portal/sidebar';
import { showAccountDetailsModal, showErrorFromApiOperation } from './js/portal/main';

const tableId = 'table-organizations-accounts';

export default function AdminOrganizationsListPage() {
  const switchButton = (
    <button
      class="btn btn-light-grey input-group-btn portal-selectpicker-end"
      type="button"
      id="it_responsible_edit"
      onclick={() => replaceOuDropdown('ou-ids')}>
      <i class="fas fa-pencil-alt" />
    </button>
  );

  function selectPicker() {
    return (
      <select
        required
        class="form-control form-select selectpicker"
        data-live-search="true"
        data-show-subtext="true"
        id="ou-ids"
        name="ou-ids"
        data-size="10">
        <option value="" disabled selected>
          - Select the Target OU -
        </option>
      </select>
    );
  }

  function inputField(value) {
    return (
      <input
        type="text"
        class="form-control button-control"
        id="ou-ids"
        name="ou-ids"
        value={value ?? ''}
        placeholder="ou-abcd-123456789"
        required
      />
    );
  }

  function replaceOuDropdown(id) {
    const component = document.getElementById(id);
    const currentValue = component.value;

    if (component.classList.contains('selectpicker')) {
      // If the component is a selectpicker, we replace it with a textfield to enter the target OU id manually
      const replaceObject = component.parentNode;
      const parentObject = replaceObject.parentNode;
      parentObject.replaceChild(inputField(currentValue), replaceObject);
    } else {
      // If the component is a textfield, we replace it with a selectpicker and trigger the ouUpdate
      const replaceObject = component;
      const parentObject = replaceObject.parentNode;
      parentObject.replaceChild(selectPicker(), replaceObject);
      ouUpdate();
    }
  }

  const page = (
    <>
      <div class="row">
        <div class="col-lg-12">
          <form name="ou-list-form" id="ou-list-form" method="POST">
            <div class="card form-card">
              <div class="card-header">List AWS Account from Organizations OU</div>
              <div class="card-body">
                <div id="form-error" class="alert alert-danger" style="display: none">
                  <ul />
                </div>
                <div id="form-success" class="alert alert-success" style="display: none">
                  <ul />
                </div>

                <div id="infobox" class="card infobox">
                  <div class="card-body">
                    With this form you can list all AWS Accounts which are member of a chosen AWS Master Account /
                    Organization and Organizational Unit
                  </div>
                </div>

                <div class="form-group row">
                  <label for="aws-account-id" class="col-form-label">
                    AWS Master Account ID
                  </label>
                  <div class="col input-group">
                    <select
                      required
                      class="form-control form-select selectpicker"
                      data-live-search="true"
                      data-show-subtext="true"
                      id="aws-account-id"
                      name="aws-account-id"
                      data-size="10"
                      onchange={() => ouUpdate()}>
                      <option value="" disabled selected>
                        - Select an AWS Master Account ID -
                      </option>
                    </select>
                    <button type="button" class="btn btn-light-grey input-group-btn" id="show-account-details">
                      <i class="fas fa-search" />
                      <span class="ms-2">Show account details</span>
                    </button>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="ou-ids" class="col-form-label">
                    Organizational Unit
                  </label>
                  <div class="col input-group">
                    {selectPicker()}
                    {switchButton}
                  </div>
                </div>
              </div>
              <div class="card-footer" align="right">
                <button id="submitButton" type="submit" class="btn btn-success" value="View Accounts">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                  View Accounts
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Datatable tableId={tableId} />
    </>
  );

  function onPageReady() {
    initOrganizationsAccountsTable(tableId);

    // Load the organizations_details from the portal config DB
    addButtonSpinner();
    baseApiAxios
      .getSettingsByKey(['organizations_details'])
      .then(settings => {
        addOrgDetails(settings);
      })
      .catch(showError)
      .finally(removeSpinners);

    // Add a click listener to show the account details of the currently selected account
    $('#show-account-details').on('click', () => {
      const selectedOption = $('#aws-account-id').find(':selected');
      const selectedAccountId = selectedOption.val();
      addSpinners();
      baseApiAxios
        .getAccount(selectedAccountId)
        .then(showAccountDetailsModal)
        .catch(showErrorFromApiOperation('Failed to fetch account details'))
        .finally(removeSpinners);
    });

    // The form validator which triggers the load accounts method
    $('#ou-list-form')
      .on('submit', function (e) {
        e.preventDefault();
        $('#form-error').hide();
        $('#form-success').hide();
        const submitButton = document.getElementById('submitButton');
        submitButton.classList.add('loading-animation');
      })
      .validate({
        errorContainer: '#form-error',
        errorLabelContainer: '#form-error ul',
        wrapper: 'li',
        submitHandler: function () {
          loadOrgAccountData(tableId);
        },
      });
  }

  return [page, onPageReady];
}
