export default function AdminFeatureFlagsPage() {
  const page = (
    <>
      <script
        defer="defer"
        src="./micro_services/aws-customer-portal-feature-flags-frontend/static/js/main.js"></script>
      <link
        rel="stylesheet"
        type="text/css"
        href="./micro_services/aws-customer-portal-feature-flags-frontend/static/css/main.css"
      />
      <div id="aws-customer-portal-feature-flags-frontend"></div>
    </>
  );

  function onPageReady() {}

  return [page, onPageReady];
}
