import axios from 'axios';
import { CONF } from './environment';
import { removeSpinners } from './sidebar';
import { authInterceptor } from './auth';
import swal from 'sweetalert';
import { addToast, displayErrorPopup, displaySuccessPopup } from './main';

export function showError(result, callback, optInfo) {
  removeSpinners();
  console.error(result);

  let errorText;
  if (typeof result === 'string') {
    errorText = result;
  } else if (result.isAxiosError && result.response && result.response.data) {
    errorText = result.response.data.message;
  } else if (result.data && result.data.message) {
    errorText = result.data.message;
  }

  if (!errorText) {
    errorText = String(result);
  }

  const errorContent = document.createElement('div');
  errorContent.innerText = errorText;

  swal({
    title: 'Error',
    content: errorContent,
    icon: 'error',
  }).then(function () {
    removeSpinners();
    if (callback) {
      callback(result, optInfo);
    } else {
      return result;
    }
  });
}

const apiAxios = axios.create({
  baseURL: CONF.apiEndpoint,
});

apiAxios.interceptors.request.use(authInterceptor);

export { apiAxios };

export function loadPermissions() {
  return apiAxios.get('/permissions').then(response => response.data.permissions);
}

/**
 * Retrieves settings for the given key or keys.
 * @param {string | string[] | undefined} key a single key or array of keys to retrieve; if not set then
 *        all settings will be retrieved
 * @returns {Promise<object>} a promise that returns the settings object when completed
 */
export function loadSettings(key) {
  if (!key) {
    // Load all settings
    return apiAxios.get('/settings').then(response => response.data);
  } else if (typeof key === 'string') {
    // Load settings for a single key
    return apiAxios.get('/settings/' + key).then(response => response.data);
  } else if (Array.isArray(key)) {
    // Load settings for multiple keys in parallel, then merge them
    return Promise.all(
      key.map(singleKey => apiAxios.get('/settings/' + singleKey).then(response => response.data)),
    ).then(results => Object.assign({}, ...results));
  } else {
    return Promise.reject('Invalid key for settings');
  }
}

/**
 * @class
 * @param {Axios} apiAxiosInstance
 */
class BaseApiAxios {
  constructor(apiAxiosInstance) {
    // if we want to log in developer console all the request and response,
    // but I personally prefer to check in the network tab
    // apiAxiosInstance.interceptors.request.use(request => {
    //   console.log('Starting Request', JSON.stringify(request, null, 2));
    //   return request;
    // });
    // apiAxiosInstance.interceptors.response.use(response => {
    //   console.log('Response:', JSON.stringify(response, null, 2));
    //   return response;
    // });
    this.axiosInstance = apiAxiosInstance;
    this.invalidateCacheHeaders = { 'Cache-Control': 'max-age=0, must-revalidate' };
  }

  /**
   * @async
   * @name getFeatureFlag
   * @description This call will return the feature flag data for the given feature flag
   * @param {string} featureFlag
   * @returns {Promise<object>} feature flag data
   */
  getFeatureFlag = async featureFlag => {
    const data = await this.get(`feature-flag-state-frontend?name=${encodeURIComponent(featureFlag)}`);
    let featureFlagData = data['feature-flag'];
    if (!featureFlagData) featureFlagData = {};
    return featureFlagData;
  };

  /**
   * @async
   * @name createOrder
   * @description
   * @param {object} payload
   * @returns {Promise<object>} create-order response
   */
  createOrder = async payload => {
    const combined_payload = Object.assign({
      action: 'create-order',
      payload,
    });
    return await this.post('orders', combined_payload);
  };

  /**
   * @async
   * @name deleteHostedZone
   * @description
   * @param {string} domain
   * @param {object} payload
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} hosted zone dataset
   */
  deleteHostedZone = async (domain, payload, headers, params) => {
    return await this.delete(`dns/domain/${domain}`, payload, headers, params);
  };

  /**
   * @async
   * @name deleteNetworkItem
   * @description
   * @param {string} id
   * @param {string} uuid
   * @returns {Promise<object>} The delete network item result from the backend
   */
  deleteNetworkItem = async (id, uuid) => {
    const payload = {
      action: 'delete-network-item',
      network_information: {
        id,
        uuid,
      },
    };
    return await this.post('networkdb', payload, {}, {});
  };

  /**
   * @async
   * @name getAccount
   * @description
   * @param {string} account_id
   * @returns {Promise<object>} account details
   */
  getAccount = async account_id => {
    const data = await this.get('accounts/' + account_id);
    return data?.account_details;
  };

  /**
   * @async
   * @name getAccountPermissions
   * @description This call will return the temporary and permanent permissions of the current user for a given account
   * @param {string} account_id
   * @returns {Promise<object>} temporary and permanent permissions
   */
  getAccountPermissions = async account_id => {
    const data = await this.get('accounts/' + account_id + '/permissions');
    return data;
  };

  /**
   * @async
   * @name getAccounts
   * @description
   * @param principal_types
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} account datasets
   */

  getAccessibleAccounts = async (principal_types, headers, params) => {
    let url = 'accounts/accessible';
    if (principal_types) {
      url += '?principal_types=' + principal_types.join(',');
    }
    return await this.get(url, headers, params);
  };

  getAccounts = async (headers, params) => {
    const data = await this.get('accounts', headers, params);
    return data?.account_data;
  };

  /**
   * @async
   * @name getCluster
   * @description
   * @param {String>} project_name
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} cluster datasets
   */
  getCluster = async (project_name, headers, params) => {
    const payload = {
      action: 'get-4wheels-cluster',
      project_name,
    };
    return await this.post('fourwheels', payload, headers, params);
  };

  /**
   * @async
   * @name getClusters
   * @description
   * @param {Array<string>} keys
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} cluster datasets
   */
  getClusters = async (keys, listAll, headers, params) => {
    return await this.get(
      `fourwheels?attribute_names=${keys.join(',')}${listAll ? '&list_all=true' : ''}`,
      headers,
      params,
    );
  };

  /**
   * @async
   * @name getDXGWs
   * @description
   * @param {Array<string>} attribute_names
   * @returns {Promise<object>} The vpc retrieved from the backend
   */
  getDXGWs = async attribute_names => {
    const payload = {
      action: 'list-network-items',
      attributes: attribute_names,
      item_type: 'DX_GW',
      table_data: {
        length: 500,
      },
    };
    return await this.post('networkdb', payload, {}, {});
  };

  /**
   * @async
   * @name getHostedZone
   * @description
   * @param {string} domain
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} hosted zone dataset
   */
  getHostedZone = async (domain, headers, params) => {
    return await this.get(`dns/domain/${domain}`, headers, params);
  };

  /**
   * @async
   * @name getHostedZones
   * @description
   * @param {Array<string>} keys
   * @param {object} headers
   * @param {object} params
   * @param {boolean} list_all
   * @returns {Promise<object>} hosted zone datasets
   */
  getHostedZones = async (urlSuffix, headers, params) => {
    return await this.get(`dns?${urlSuffix}`, headers, params);
  };

  /**
   * @async
   * @name getProvider
   * @description
   * @param {string} arn
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} idp datasets
   */
  getProvider = async (arn, headers, params) => {
    const arnEncoded = btoa(arn);
    return await this.get(`idp/${arnEncoded}`, headers, params);
  };

  /**
   * @async
   * @name getLogGroups
   * @description
   * @param {string} account_id
   * @param {string} region
   * @param {number} limit
   * @param {string} next_token
   * @param {string} log_group_prefix
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} log group datasets
   */
  getLogGroups = async (account_id, region, limit, next_token, log_group_prefix, headers, params) => {
    return await this.get(
      `/accounts/${account_id}/log-groups?region_name=${region}${limit ? '&limit=' + limit : ''}${
        log_group_prefix ? '&log_group_name_prefix=' + log_group_prefix : ''
      }${next_token ? '&next_token=' + next_token : ''}`,
      headers,
      params,
    );
  };

  /**
   * @async
   * @name getLogGroupSubscriptionFilters
   * @description
   * @param {string} account_id
   * @param {string} log_group_name
   * @param {string} region
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} log group subscription filter datasets
   */
  getLogGroupSubscriptionFilters = async (account_id, log_group_name, region, headers, params) => {
    return await this.get(
      `/accounts/${account_id}/log-groups/subscription_filters?log_group_name=${log_group_name}&region_name=${region}`,
      headers,
      params,
    );
  };

  /**
   * @async
   * @name getNetworkItem
   * @description
   * @param {string} id
   * @param {string} uuid
   * @param {string} item_type
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} The delete network item result from the backend
   */
  getNetworkItem = async (id, uuid, item_type, headers, params) => {
    const payload = {
      action: 'get-network-item',
      id,
      uuid,
      item_type,
    };
    return this.post('networkdb', payload, headers, params);
  };

  /**
   * @async
   * @name getNetworkItems
   * @description
   * @param {object} payload
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} network items response
   */
  getNetworkItems = async (payload, headers, params) => {
    return this.post('networkdb', payload, headers, params);
  };

  /**
   * @async
   * @name getOrder
   * @description
   * @param {string} order_id
   * @param {object} headers
   * @param {object} params
   * @param {object} payload
   * @returns {Promise<object>} order details
   */
  getOrder = async (order_id, headers, params, payload = {}) => {
    const combined_payload = Object.assign(
      {
        action: 'get-order-item',
        id: order_id,
      },
      payload,
    );
    return await this.post(`orders`, combined_payload, headers, params);
  };

  /**
   * @name getOrders
   * @description
   * @param {object} payload
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} order datasets
   */
  getOrders = async (payload, headers, params) => {
    const combined_payload = Object.assign(
      {
        action: 'list-orders',
      },
      payload,
    );
    return await this.post(`orders`, combined_payload, headers, params);
  };

  /**
   * @name getOrdersOpenSearch
   * @description
   * @param {object} payload
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} order datasets from open search
   */
  getOrdersOpenSearch = async (payload, headers, params) => {
    console.debug(CONF.apiEndpoint + '/search/order');
    // align with getOrders but no need for payload
    return await this.get('search/orders', headers, params);
  };

  /**
   * @async
   * @name getRestrictions
   * @description
   * @param {string} account_id
   * @returns {Promise<object>} organizations response
   */
  getRestrictions = async account_id => {
    return await this.get('/accounts/' + account_id + '/scps');
  };

  /**
   * @async
   * @name getSettings
   * @description
   * @param {object} payload
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} settings response
   */
  getSettings = async (payload, headers, params) => {
    return await this.post('settings', payload, headers, params);
  };

  /**
   * @async
   * @name getSettingsByKey
   * @description
   * @param {Array<string>} keys
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} settings response
   */
  getSettingsByKey = async (keys, headers, params) => {
    if (Array.isArray(keys)) {
      return Promise.all(keys.map(key => this.get('/settings/' + key, headers, params))).then(results =>
        Object.assign({}, ...results),
      );
    } else {
      return Promise.reject('Invalid key for settings');
    }
  };

  /**
   * @name getTargetOUs
   * @description
   * @param {object} payload
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} target ous for account
   */
  getTargetOUs = async (payload, headers, params) => {
    return await this.post(`organizations/target-ous`, payload, headers, params);
  };

  /**
   * @async
   * @returns {Promise<object>} DNS domainconfig from the backend
   */
  getDnsConfig = async () => {
    return await this.get('dns/domainconfig', null, null);
  };

  /**
   * @async
   * @name getVPC
   * @description
   * @param {string} vpc_id
   * @param {string} account_id
   * @param {boolean} extended
   * @returns {Promise<object>} vpc details
   */
  getVPC = async (vpc_id, account_id, extended) => {
    const data = await this.get(
      account_id ? 'accounts/' + account_id + '/vpcs/' + vpc_id : 'vpcs/' + vpc_id,
      null,
      extended ? { extended: true } : null,
    );
    return data?.vpc;
  };

  /**
   * @async
   * @name getVPCs
   * @description
   * @param {Array<string>} attribute_names
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} The vpc retrieved from the backend
   */
  getVPCs = async (attribute_names, headers, params) => {
    const payload = {
      action: 'list-network-items',
      attributes: attribute_names,
      item_type: 'VPC',
      table_data: {
        length: 500,
      },
    };
    return this.post('networkdb', payload, headers, params);
  };

  /**
   * @async
   * @name getWhitelistedServices
   * @description
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} The whitelisted services
   */
  getWhitelistedServices = async (headers, params) => {
    return await this.get('marketplace-products', headers, params);
  };

  /**
   * @async
   * @name postApiKeyData
   * @description
   * @param {object} payload
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} api key datasets
   */
  postApiKeyData = async (payload, headers, params) => {
    return await this.post('keys', payload, headers, params);
  };

  /**
   * @async
   * @name postClusterData
   * @description
   * @param {object} payload
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} cluster datasets
   */
  postClusterData = async (payload, headers, params) => {
    return await this.post('fourwheels', payload, headers, params);
  };

  /**
   * @async
   * @name postDnsData
   * @description
   * @param {object} payload
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} dns response
   */
  postDnsData = async (payload, headers, params) => {
    return await this.post('dns', payload, headers, params);
  };

  /**
   * @async
   * @name postNetworkData
   * @description
   * @param {object} payload
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} network response
   */
  postNetworkData = async (payload, headers, params) => {
    return await this.post('networkdb', payload, headers, params);
  };

  /**
   * @async
   * @name searchADUser
   * @description
   * @param {object} search_params
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} search result
   */
  searchADUser = async (search_params, headers, params) => {
    const payload = {
      action: 'search-ad-user',
      payload: search_params,
    };
    return this.post('ldap', payload, headers, params);
  };

  /**
   * @async
   * @name triggerAcceptProposal
   * @description
   * @param {string} dx_gateway_id
   * @param {string} proposal_id
   * @param {string} associated_gateway_id
   * @returns {Promise<object>} The delete network item result from the backend
   */
  triggerAcceptProposal = async (dx_gateway_id, proposal_id, associated_gateway_id) => {
    const payload = {
      action: 'trigger-accept-dx-gateway-association-proposals',
      detailed: {
        directConnectGatewayId: dx_gateway_id,
        proposalId: proposal_id,
        associatedGateway: {
          id: associated_gateway_id,
        },
      },
    };
    return await this.post('networkdb', payload, {}, {});
  };

  /**
   * @async
   * @name triggerAccountUpdate
   * @description
   * @param {string} account_id
   * @returns {Promise<object>} account details
   */
  triggerAccountUpdate = async account_id => {
    return await this.post('accounts/' + account_id + '/trigger-update');
  };

  /**
   * @async
   * @name triggerHostedZoneUpdate
   * @description
   * @param {string} fqdn
   * @returns {Promise<object>} account details
   */
  triggerHostedZoneUpdate = async fqdn => {
    const payload = {
      action: 'trigger-update-hostedzone',
      fqdn,
    };
    return await this.post('dns', payload);
  };

  /**
   * @async
   * @name triggerVPCUpdate
   * @description
   * @param {string} vpc_id
   * @returns {Promise<object>} The delete network item result from the backend
   */
  triggerVPCUpdate = async vpc_id => {
    const payload = {
      action: 'trigger-update-vpc',
      vpc_id,
    };
    return await this.post('networkdb', payload, {}, {});
  };

  /**
   * @async
   * @name updateOrder
   * @description
   * @param {string} order_id
   * @param {object} payload
   * @returns {Promise<object>} update-order response
   */
  updateOrder = async (order_id, payload) => {
    const combined_payload = Object.assign(
      {
        action: 'update-order',
        order_id,
      },
      payload,
    );
    return await this.post('orders', combined_payload);
  };

  /**
   * @async
   * @name verifySIEMKey
   * @description
   * @param {string} siem_key
   * @param {string} account_id
   * @returns {Promise<object>} key verification response
   */
  verifySIEMKey = async (siem_key, account_id) => {
    const payload = {
      siem_key: siem_key,
      account_id: account_id,
    };
    return await this.post('siem/verify', payload);
  };

  /**
   * @async
   * @name createSIEMKey
   * @description
   * @param {string} ola_handle
   * @returns {Promise<object>} create key response
   */
  createSIEMKey = async ola_handle => {
    const payload = {
      ola_handle: ola_handle,
    };
    return await this.post('siem/create', payload);
  };

  /**
   * @async
   * @name displayResponseAsAlert
   * @description
   * @param {Promise} requestPromise
   * @param {function} optionalCallback
   */
  displayResponseAsAlert = async (requestPromise, optionalCallback) => {
    requestPromise
      .then(result => {
        displaySuccessPopup(result.message);
      })
      .catch(displayErrorPopup)
      .finally(() => {
        removeSpinners();
        if (typeof optionalCallback === 'function') optionalCallback();
      });
  };

  /**
   * @async
   * @name displayResponseAsToast
   * @description
   * @param {Promise} requestPromise
   * @param {string} toastTitle
   * @param {function} optionalCallback
   */
  displayResponseAsToast = async (requestPromise, toastTitle, optionalCallback) => {
    requestPromise
      .then(result => {
        addToast(toastTitle, result.message, 5000);
      })
      .catch(displayErrorPopup)
      .finally(() => {
        removeSpinners();
        if (typeof optionalCallback === 'function') optionalCallback();
      });
  };

  /**
   * @async
   * @name delete
   * @description
   * @param {string} endpoint
   * @param {object} payload
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} The response from the backend
   */
  delete = async (endpoint, payload, headers, params) => {
    let response;
    if (headers || params) {
      const config = Object.assign({}, headers && { headers }, params && { params });
      response = await this.axiosInstance.delete(endpoint, payload, config);
    } else response = await this.axiosInstance.delete(endpoint, payload);
    return response?.data;
  };

  /**
   * @async
   * @name get
   * @description
   * @param {string} endpoint
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} The response from the backend
   */
  get = async (endpoint, headers, params) => {
    let response;
    if (headers || params) {
      const config = Object.assign({}, headers && { headers }, params && { params });
      response = await this.axiosInstance.get(endpoint, config);
    } else {
      response = await this.axiosInstance.get(endpoint);
    }
    return response?.data;
  };

  /**
   * @async
   * @name post
   * @description
   * @param {string} endpoint
   * @param {object} payload
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} The response from the backend
   */
  post = async (endpoint, payload, headers, params) => {
    let response;
    if (headers || params) {
      const config = Object.assign({}, headers && { headers }, params && { params });
      response = await this.axiosInstance.post(endpoint, payload, config);
    } else response = await this.axiosInstance.post(endpoint, payload);
    return response?.data;
  };

  /**
   * @async
   * @name patch
   * @description
   * @param {string} endpoint
   * @param {object} payload
   * @param {object} headers
   * @param {object} params
   * @returns {Promise<object>} The response from the backend
   */
  patch = async (endpoint, payload, headers, params) => {
    let response;
    if (headers || params) {
      const config = Object.assign({}, headers && { headers }, params && { params });
      response = await this.axiosInstance.patch(endpoint, payload, config);
    } else response = await this.axiosInstance.patch(endpoint, payload);
    return response?.data;
  };
}

export const baseApiAxios = new BaseApiAxios(apiAxios);
