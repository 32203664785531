import { addSpinner, addTableSpinner, addButtonSpinner, removeSpinners } from './sidebar';
import {
  addCopyButton,
  addLoadAccountDetailsButton,
  checkValueAgainstList,
  configureTableColumns,
  displaySuccessPopup,
  createJsonCodeBlock,
  InfoTable,
  removeDisabledOptions,
  rowDetailsClickHandler,
} from './main';
import { formatAccountRow } from './accounts_table';
import { addDataAndFormatFooter } from './databases';
import { baseApiAxios, showError } from './api';
import { initDataTable } from './datatable';
import { CONF } from './environment';

const orgAccountsTableColumns = [
  { id: 'select' },
  { id: 'account_id_col', name: 'Account Id', attribute_name: 'account_id' },
  { id: 'email_col', name: 'Root Account', attribute_name: 'account_root_email' },
  { id: 'name_col', name: 'Account Name', attribute_name: 'org_friendly_name' },
  { id: 'status_col', name: 'Status', attribute_name: 'org_status' },
  { id: 'joined_method_col', name: 'JoinedMethod', attribute_name: 'org_joined_method' },
  { id: 'joined_time_col', name: 'JoinedTimestamp', attribute_name: 'org_joined_timestamp' },
];

export function initOrganizationsAccountsTable(tableId) {
  configureTableColumns(tableId, orgAccountsTableColumns);

  initDataTable(
    tableId,
    'lCfrtpBi',
    [
      {
        extend: 'excelHtml5',
        text: 'Export Excel',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as Excel file',
      },
      {
        extend: 'csvHtml5',
        text: 'Export CSV',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as CSV file',
      },
      {
        extend: 'copyHtml5',
        text: 'Copy',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Copy the visible columns into your clipboard',
      },
      {
        extend: 'resetTable',
        ajaxReload: false,
        titleAttr: 'Reset all filters in the table footer',
      },
      {
        extend: 'reloadTable',
        text: 'Reload Accounts',
        ajaxReload: false,
        methodReload: loadOrgAccountData,
        titleAttr: 'Reload AWS accounts (no-cache)',
      },
    ],
    [
      {
        // Column 0
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: null,
        name: 'select',
        class: 'details-control',
        width: '20px',
      },
      {
        visible: true,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'account_id',
        name: 'account_id_col',
        title: 'Account Id',
        createdCell: function (td) {
          addCopyButton(td);
          addLoadAccountDetailsButton(td);
        },
      },
      {
        visible: true,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'account_root_email',
        name: 'email_col',
        title: 'Root Email',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'org_friendly_name',
        name: 'name_col',
        title: 'Name',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'org_status',
        name: 'status_col',
        title: 'Status',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'org_joined_method',
        name: 'joined_method_col',
        title: 'JoinedMethod',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'org_joined_timestamp',
        name: 'joined_time_col',
        title: 'JoinedTimestamp',
        createdCell: addCopyButton,
      },
    ],
    null,
    {
      order: [[1, 'asc']],
    },
  );

  addTableButtons();
  rowDetailsClickHandler({ tableId: tableId, rowDetailCallback: formatAccountRow });
}

function addTableButtons() {
  const permissions = localStorage.permissions ? localStorage.permissions : false;
  const dt = $('#table-organizations-accounts').DataTable({ retrieve: true });
  let buttons = 0; // Add buttons before the already existing buttons

  if (checkValueAgainstList('manage_accounts', permissions)) {
    dt.button().add(buttons, {
      text: 'Trigger Account Updates',
      action: triggerAllAccountsAccDetailsUpdate,
      titleAttr: 'Collect account information like the alternate contacts or the support level.',
    });
    buttons++;
  }
}

function triggerAllAccountsAccDetailsUpdate() {
  addSpinner();
  baseApiAxios.post('/accounts/trigger-update').then(triggerAccountSuccess).catch(showError).finally(removeSpinners);
}

function triggerAccountSuccess(response) {
  displaySuccessPopup(response.message, 'Your request was successful.');
}

export function loadOrgAccountData(tableId, _headers) {
  const accountId = $('#aws-account-id')[0].value;
  const ouId = $('#ou-ids')[0].value;

  if (accountId && ouId) {
    addSpinner();
    addTableSpinner();
    addButtonSpinner();
    baseApiAxios
      .get(`/organizations/${accountId}/units/${ouId}/accounts`)
      .then(response => {
        addOrgAccountData(response, tableId);
      })
      .catch(showError)
      .finally(removeSpinners);
  }
}

function addOrgAccountData(response, tableId) {
  const dropdownColumns = ['org_status', 'org_joined_method'];
  const searchColumns = ['account_id_col', 'email_col', 'name_col', 'joined_time_col'];

  addDataAndFormatFooter({
    dataArray: response.accounts,
    tableId: tableId,
    tableColumns: orgAccountsTableColumns,
    dropdownColumns: dropdownColumns,
    searchColumns: searchColumns,
  });
}

/**
 * Update the AWS Master Account ID dropdown, then trigger the update of the Organizational Unit dropdown
 *
 * @param {*} settings the `organizations_details` portal config
 */
export function addOrgDetails(settings) {
  removeSpinners();

  // Reset the AWS Master Account Id dropdown
  const sel = document.getElementById('aws-account-id');
  removeDisabledOptions(sel);

  // Update the AWS Master Account Id dropdown with existing values from the organization_details settings
  for (const accountId in settings.organizations_details) {
    const opt = document.createElement('option');
    opt.value = accountId;
    opt.innerText = accountId;
    opt.dataset.content =
      `<span class="bs-dropdown-item-text">${accountId}</span>` +
      `<span class="bs-dropdown-badge">${CONF.awsMasterAccountIds[accountId] ?? ''}</span>`;
    sel.appendChild(opt);
  }
  $('.selectpicker').selectpicker('refresh');

  // Trigger the update of the Organization Unit dropdown
  ouUpdateWithSettings(settings);
}

/**
 * Retrieve the `organizations_details` config via REST call and trigger the update of the Organizational Unit dropdown
 */
export function ouUpdate() {
  baseApiAxios
    .getSettingsByKey(['organizations_details'])
    .then(settings => {
      ouUpdateWithSettings(settings);
    })
    .catch(showError)
    .finally(removeSpinners);
}

/**
 * Update the Organizational Unit dropdown with the OU ids for the selected AWS master account id
 *
 * @param {*} settings the `organizations_details` portal config
 */
function ouUpdateWithSettings(settings) {
  // Remove all options from the selectpicker
  const sel = document.getElementById('ou-ids');
  removeDisabledOptions(sel);

  // Get the target AWS master account id and get the org details settings for this account
  const accountId = $('#aws-account-id')[0].value;
  const orgDetails = settings.organizations_details[accountId];

  if (orgDetails) {
    const orgUnits = {};

    const rootOuId = orgDetails['root'];
    orgUnits[rootOuId] = {
      name: 'root',
      deprecated: false,
    };

    // Find new halt OUs
    for (const ou in orgDetails.halt_ous) {
      orgUnits[orgDetails.halt_ous[ou]] = {
        name: `halt / ${ou}`,
        halt: true,
      };
    }

    // Find the target account OUs for each account_type, account_area and account_stage
    for (const accountType in orgDetails.target_account_ous) {
      for (const accountArea in orgDetails.target_account_ous[accountType]) {
        for (const accountStage in orgDetails.target_account_ous[accountType][accountArea]) {
          orgUnits[orgDetails.target_account_ous[accountType][accountArea][accountStage]] = {
            name: `${accountType} / ${accountArea} / ${accountStage}`,
          };
        }
      }
    }

    // TODO: [DEPRECATED] This list of items for the dropdown is deprecated
    const haltOuId = orgDetails['halt'];
    orgUnits[haltOuId] = {
      name: 'halt',
      deprecated: true,
    };

    // TODO: [DEPRECATED] This list of items for the dropdown is deprecated
    for (let area in orgDetails['account_areas']) {
      for (let type in orgDetails['account_areas'][area]['account_types']) {
        const account_type = orgDetails['account_areas'][area]['account_types'][type];
        orgUnits[account_type['id']] = orgUnits[account_type['id']] || {
          name: account_type['name'],
          deprecated: true,
        };
      }
    }

    for (const ou in orgUnits) {
      const opt = document.createElement('option');
      opt.value = ou;
      opt.innerText = orgUnits[ou].name;

      const deprecatedBadge = orgUnits[ou].deprecated ? `<span class="bs-dropdown-badge failed">DEPRECATED</span>` : '';

      opt.dataset.content =
        `<span class="bs-dropdown-item-text">${orgUnits[ou].name}</span>` +
        `<span class="bs-dropdown-badge">${ou}</span>` +
        deprecatedBadge;
      opt.selected = false;
      opt.disabled = false;
      sel.appendChild(opt);
    }
  }

  $('.selectpicker').selectpicker('refresh');
}

/**
 * Render the SCP details in an accordion view with a single accordion-item per OU and account.
 *
 * @param {object} attachedScps contains an array with all directly attached and an object with OUs and attached SCPs
 * @param {string} accountId the target AWS account id
 * @param {object} sortedOrgUnits a sorted object with all OUs from root down to the last OU child of the account
 */
export function renderScpDetails(attachedScps, accountId, sortedOrgUnits) {
  /**
   * Get a single SCP incl. metadata and the actual policy and render a single row per SCP with an info table on the
   * left and the actual SCP on the right.
   *
   * @param {object} scp the SCP object with the SCP metadata and the actual SCP document
   * @returns
   */
  function renderScp(scp) {
    // Parse the json policy and some color-coding for `Allow` and `Deny` statements
    const json = JSON.parse(scp['Content']);

    // Calculate the current size in bytes of the SCP. This might not be 100% accurate but it's better than nothing.
    // Once calculated we add it to the SCP PolicySummary to show it below in the SCP info table.
    const maxSizeInBytes = 5120;
    const policySizeInBytes = new TextEncoder().encode(JSON.stringify(json)).length;
    const policyUsage = (policySizeInBytes / maxSizeInBytes) * 100;
    scp['PolicySummary']['Size'] = `~${policySizeInBytes} bytes of max. ${maxSizeInBytes} bytes (${policyUsage.toFixed(
      2,
    )}%)`;

    const jsonDump = createJsonCodeBlock(json);

    // Render and return the row item (=a single SCP as mentioned above)
    return (
      <div class="row">
        <div class="detailsContent">
          <h5>
            {scp['PolicySummary']['Name']} <small>{scp['PolicySummary']['Description']}</small>
          </h5>

          <div class="row">
            {/* Create the info table on the left side of the panel which contains some metadata about the SCP */}
            <div class="col-lg-12 col-xl-6">
              <InfoTable
                data={scp['PolicySummary']}
                rowInfos={[
                  { Name: 'Policy Name', Value: 'Name' },
                  { Name: 'Policy Id', Value: 'Id' },
                  { Name: 'Policy Arn', Value: 'Arn' },
                  { Name: 'Policy Description', Value: 'Description' },
                  { Name: 'AWS Managed Policy', Value: 'AwsManaged' },
                  { Name: 'Policy Size (bytes)', Value: 'Size' },
                ]}
              />
            </div>

            {/* Create the code snippet on the right side of the panel which contains the rendered SCP itself */}
            <div class="col-lg-12 col-xl-6">{jsonDump}</div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Render a single accordion-item incl. accordion-header and accordion-body. A single item will represent an
   * Organizational Unit or an AWS account incl. all SCPs which are assigned to the current resource.
   *
   * @param {string} id the OU or account id to create unique ids
   * @param {string} headerText the text value for the accordion-header button
   * @param {Array} renderedScps the actual content of the accordion-body (table with SCP info and the SCP itself)
   * @returns
   */
  function renderAccordionItem(id, headerText, renderedScps) {
    // Show a green badge if less than 3 or less SCPs are assigned, yellow if 4 SCPs are assigned and red if 5 SCPs are
    // assigned as there's a hard limit of 5 SCPs per OU and AWS account.
    let badgeColor = 'badge-success';
    if (renderedScps.length == 4) {
      badgeColor = 'badge-warning text-dark';
    } else if (renderedScps.length > 4) {
      badgeColor = 'badge-danger text-dark';
    }

    // Render and return the actual accordion-item. The accordion will be open/visible (="collapse show") if there are
    // more than 1 SCPs associated as AWS is attaching the FullAWSAccess policy by default to every OU and account.
    return (
      <div class="accordion-item">
        <h4 class="accordion-header" id={'accordionHeader-' + id}>
          <button
            class={'accordion-button' + (renderedScps.length > 1 ? '' : ' collapsed')}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={'#accordionBody-' + id}
            aria-expanded={renderedScps.length > 1 ? 'true' : 'false'}
            aria-controls={'accordionBody-' + id}>
            {headerText}
            <span class={'m-2 badge ' + badgeColor}>{renderedScps.length} from max. 5 SCPs attached</span>
          </button>
        </h4>
        <div
          class={'accordion-collapse' + (renderedScps.length > 1 ? ' collapse show' : ' collapse')}
          id={'accordionBody-' + id}
          aria-labelledby={'accordionHeader-' + id}>
          <div class="accordion-body">
            <div>{renderedScps}</div>
          </div>
        </div>
      </div>
    );
  }

  // Generate an array with accordion-items per Organizational Unit
  const mappedOrgUnits = [];
  const scpsOrgUnits = [];
  sortedOrgUnits.forEach(orgUnit => {
    const ouId = orgUnit.org_ou_id;
    const headerText = `Organizational Unit - ${orgUnit.org_ou_name} (${ouId})`;
    const scps = attachedScps?.ou_attached_scps[ouId]?.scps ?? [];
    const renderedScps = scps.map(scp => {
      return renderScp(scp);
    });
    scpsOrgUnits.push(renderAccordionItem(ouId, headerText, renderedScps));
    mappedOrgUnits.push(ouId);
  });

  // Update an array with accordion-items per Organizational Unit that are not listed in the `sortedOrgUnits`
  const unassignedOus = Object.keys(attachedScps?.ou_attached_scps).filter(x => !mappedOrgUnits.includes(x));
  unassignedOus.forEach(ouId => {
    const headerText = `Organizational Unit - ${ouId}`;
    const scps = attachedScps?.ou_attached_scps[ouId]?.scps ?? [];
    const renderedScps = scps.map(scp => {
      return renderScp(scp);
    });
    scpsOrgUnits.push(renderAccordionItem(ouId, headerText, renderedScps));
  });

  // Generate the last accordion-item for the AWS account itself
  const scps = attachedScps?.directly_attached_scps ?? [];
  const renderedScps = scps.map(scp => {
    return renderScp(scp);
  });
  const scpsAccount = renderAccordionItem(accountId, `AWS Account - ${accountId}`, renderedScps);

  // Create a new container with an accordion and all OU and account accordion-items
  const accordion = (
    <div class="detailsContent mb-0">
      <div class="accordion" id={'scps-by-ou-' + accountId}>
        {scpsOrgUnits}
        {scpsAccount}
      </div>
    </div>
  );

  // Delete the current children of the wrapper and append the new accordion to avoid that the content is stacking up
  $('#account_details_scp_' + accountId)
    .empty()
    .append(accordion);
}
