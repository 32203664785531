/**
 * Functions and variables for the user confirmation page (List and Confirm Users)
 */
import swal from 'sweetalert';
import { addButtonSpinner, addSpinner, addTableSpinner, removeSpinners } from './sidebar';
import {
  addContentButtonWithTooltip,
  addContentDiv,
  addContentHeader,
  addCopyButton,
  addLoadAccountDetailsButton,
  addRowLoadingAnimation,
  addToast,
  checkValueAgainstList,
  configureTableColumns,
  createAndAddInfoTableWide,
  createPortalOrder,
  displayError,
  displayErrorPopup,
  displaySuccess,
  formatTableChildRow,
  hasAccess,
  showErrorFromApiOperation,
} from './main';
import { apiAxios, baseApiAxios } from './api';
import { setSearchParamsUrl } from './search_params';
import { COGNITO } from './auth';
import { checkResponsibility } from './dashboard';
import { accountIdPattern } from './accounts';
import { getData } from './databases';
import { initDataTable } from './datatable';

export const principalsTableColumns = [
  { id: 'select_col' },
  { id: 'user_name_original_col', name: 'Principal Name' },
  { id: 'principal_arn_col', name: 'Principal ARN' },
  { id: 'principal_account_id_col', name: 'Principal/Source Account Id' },
  { id: 'principal_type_col', name: 'Principal Type' },
  { id: 'roles_col', name: 'Roles' },
  { id: 'status_update_col', name: 'Status Update (UTC)' },
  { id: 'actions_col', name: 'Actions' },
];

export const defaultIamRoles = ['UserRO', 'UserFull', 'BMWBillingRO', 'BMWSecurityRO'];

const principalsDropdownColumns = ['principal_type'];

const principalsSearchColumns = [
  'principal_account_id',
  'principal_arn',
  'user_name_original',
  'roles_col',
  'status_update',
  'account_id',
];

const principalDetailNavigation = [
  {
    Value: 'roles',
    Name: 'Roles',
    AttributeName: 'role-details-tab',
    UidDataAttribute: 'principal_arn',
    Default: true,
    Callback: formatPrincipalsTableRolesTab,
  },
];

const accountIdCellCallback = (td, cellData) => {
  addCopyButton(td);
  addLoadAccountDetailsButton(td);

  if (!accountIdPattern.test(cellData)) {
    $(td).addClass('portal-danger');
  }
};

/**
 * Initialize the datatable to list all principals for the selected account.
 */
export function initPrincipalsTable(tableId) {
  let permissions = localStorage.permissions ? localStorage.permissions : false;
  configureTableColumns(tableId, principalsTableColumns);

  const dt = initDataTable(
    tableId,
    'lCfrtpBi',
    [
      {
        name: 'principal-excel-export-button',
        extend: 'excelHtml5',
        text: 'Export Excel',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as Excel file',
      },
      {
        name: 'principal-csv-export-button',
        extend: 'csvHtml5',
        text: 'Export CSV',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as CSV file',
      },
      {
        name: 'principal-copy-button',
        extend: 'copyHtml5',
        text: 'Copy',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Copy the visible columns into your clipboard',
      },
      {
        name: 'principal-update-account-button',
        text: 'Update Account Principals',
        action: function () {
          if (checkValueAgainstList('manage_users', permissions)) {
            triggerUpdatePrincipalsAccount();
          } else {
            displayErrorPopup(null, "You don't have permissions to trigger update principals for all AWS accounts");
          }
        },
        titleAttr: 'Trigger the update to re-collect all principal in the selected AWS account',
        enabled: false,
      },
      {
        name: 'principal-reset-table-button',
        extend: 'resetTable',
        ajaxReload: false,
        titleAttr: 'Reset all filters in the table footer',
      },
      {
        name: 'principal-reload-table-button',
        extend: 'reloadTable',
        text: 'Reload Principals',
        ajaxReload: false,
        methodReload: loadPrincipalsTableData,
        titleAttr: 'Reload principals (no-cache)',
        enabled: false,
      },
    ],
    [
      {
        // Column 0
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: null,
        name: 'select_col',
        class: 'details-control',
        width: '20px',
      },
      {
        // Column 1
        visible: true,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'user_name_original',
        name: 'user_name_original_col',
        title: 'Principal Name',
        createdCell: addCopyButton,
      },
      {
        // Column 2
        visible: false,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'principal_arn',
        name: 'principal_arn_col',
        title: 'Principal ARN',
        createdCell: addCopyButton,
      },
      {
        // Column 3
        visible: true,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'principal_account_id',
        name: 'principal_account_id_col',
        title: 'Principal/Source Account Id',
        createdCell: accountIdCellCallback,
      },
      {
        // Column 4
        visible: true,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'principal_type',
        name: 'principal_type_col',
        title: 'Principal Type',
        createdCell: addCopyButton,
      },
      {
        // Column 5
        visible: true,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'roles',
        name: 'roles_col',
        title: '# Roles',
        createdCell: addCopyButton,
        render: {
          _: '.length',
          filter: '[, ].role_name',
          display: '.length',
        },
      },
      {
        // Column 6
        visible: false,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'status_update',
        name: 'status_update_col',
        title: 'Status Update (UTC)',
        createdCell: addCopyButton,
      },
      {
        // Column 7
        visible: true,
        defaultContent: '',
        orderable: false,
        data: null,
        name: 'actions_col',
        title: 'Actions',
        createdCell: addPrincipalsTableActionButtons,
        class: 'details-edit',
        width: '50px',
      },
    ],
    function (row, data) {
      if (data.roles && data.roles.every(role => role['marked_for_deletion'])) {
        $(row).addClass('row-deleted');
      }
    },
    null,
  );

  $('#' + tableId + ' tbody').on('click', 'tr td.details-control', function () {
    const tr = $(this).closest('tr');
    const row = dt.row(tr);
    if (row.child.isShown()) {
      tr.removeClass('details');
      row.child.hide();
    } else {
      tr.addClass('details');
      addRowLoadingAnimation(row);
      formatTableChildRow(row, row.data(), principalDetailNavigation);
    }
  });
}

/**
 * API request to trigger the update of all principals within the selected AWS accounts. This update is executed
 * asynchronous in the backend. Users need to reload the data manually with the reload button of the table (or visit
 * the page to a later point in time).
 */
function triggerUpdatePrincipalsAccount() {
  addSpinner();
  const account_id = $('#aws-account-id')[0].value.split(';')[0];

  apiAxios
    .post('/accounts/' + account_id + '/trigger-update-principals')
    .then(response => {
      console.debug('POST /accounts/{id}/trigger-update-principals', response);
      addToast('Account Principals Update', response.data.message, 6000);
    })
    .finally(() => {
      removeSpinners();
    });
}

/**
 * Create all buttons which are visible in the actions column of the table (main rows, not detail rows).
 *
 * @param {*} td
 * @param {*} cellData
 * @param {*} rowData
 * @param {*} row
 * @param {*} col
 */
function addPrincipalsTableActionButtons(td, _cellData, rowData, _row, _col) {
  const account_id = $('#aws-account-id')[0].value.split(';')[0];
  if (rowData.principal_type === 'federated_user' && rowData.roles && rowData.roles.length > 0) {
    const btnGroup = document.createElement('div');
    btnGroup.setAttribute('class', 'table-action-button-group');

    // Don't show a delete button if all the principal's roles are already marked for deletion
    if (rowData.roles.some(role => !role['marked_for_deletion'])) {
      let payload = {
        action: 'remove-ad-user',
        account_id: rowData.account_id,
        description: 'Remove Federated User "' + rowData.user_name + '" from Role Trust Relationship',
        email: rowData.user_name,
        roles: rowData.roles.map(role => role.role_name),
      };
      addContentButtonWithTooltip(
        btnGroup,
        'Delete Role Assignment',
        'fas fa-trash-alt',
        deleteRoleAssignments,
        payload,
        !hasAccess(account_id, ['manage_users']),
      );
    }
    td.appendChild(btnGroup);
  }
}

/**
 * Load the principals for the selected AWS account via API request. Principals can be federated_users,
 * account_responsibles, account_area_admins, iam_users, iam_roles or a complete AWS account root.
 *
 * NOTE: For the moment we only load federated_users by default and there's no switch available so far to select other
 * principals_types.
 *
 * @param {object?} headers
 * @param {boolean?} forceReload
 */
export function loadPrincipalsTableData(tableId, headers, forceReload) {
  const params = { principal_types: 'federated_user' };
  const account_id = $('#aws-account-id')[0].value.split(';')[0];
  setSearchParamsUrl({ account_id: account_id });

  $(() => addSpinner());
  $(() => addTableSpinner());

  if (account_id) {
    getData({
      apiPath: '/accounts/' + account_id + '/users',
      dataAttribute: 'account_users',
      tableId: tableId,
      tableColumns: principalsTableColumns,
      dropdownColumns: principalsDropdownColumns,
      searchColumns: principalsSearchColumns,
      headers: headers,
      forceReload: forceReload,
      params: params,
    }).then(response => {
      showExpirationDate(response);
      const dt = $('#table-principals').DataTable();
      if (dt.buttons('principal-update-account-button:name') && hasAccess(account_id, ['manage_users'])) {
        dt.buttons('principal-update-account-button:name').enable();
      }
      if (dt.buttons('principal-reload-table-button:name')) {
        dt.buttons('principal-reload-table-button:name').enable();
      }
      const details_button = document.getElementById('show-account-details');
      if (details_button) details_button.removeAttribute('disabled');
    });
  }
}

/**
 * Format the roles tab of each principal details row.
 *
 * @param {*} data
 * @param {*} container
 */
function formatPrincipalsTableRolesTab(data, container) {
  // DETAIL ROW - LEFT
  const detailContainer = document.createElement('div');
  detailContainer.setAttribute('class', 'col-lg-12 col-md-12');
  detailContainer.setAttribute('name', 'role-details');
  container.appendChild(detailContainer);
  formatPrincipalsTableRolesTabDetails(data, detailContainer);
}

/**
 * Add the role details to the roles tab (formatPrincipalsTableRolesTab).
 *
 * @param {*} data
 * @param {*} container
 */
function formatPrincipalsTableRolesTabDetails(data, container) {
  const detailContainer = addContentDiv(container, 'detailsContent');
  addContentHeader(detailContainer, 'IAM Roles');
  const details = document.createElement('div');
  detailContainer.appendChild(details);

  const roleInformation = (data.roles || []).map(role => {
    const btnGroup = document.createElement('div');
    btnGroup.setAttribute('class', 'table-action-button-group');

    const payload = {
      action: 'remove-ad-user',
      account_id: data.account_id,
      description: 'Remove Federated User "' + data.user_name + '" from Role Trust Relationship',
      email: data.user_name,
      roles: [role.role_name],
    };

    if (!role['marked_for_deletion']) {
      addContentButtonWithTooltip(
        btnGroup,
        'Delete Role Assignment',
        'fas fa-trash-alt',
        deleteRoleAssignments,
        payload,
        !hasAccess(data.account_id, ['manage_users']),
      );
    }

    let statusText = '';
    if (role['marked_for_deletion']) {
      statusText = 'Marked for deletion';
      const orderIds = Object.keys(role.orders || {});
      if (orderIds.length) {
        statusText += ' (order ID ' + orderIds[orderIds.length - 1] + ')';
      }
    } else if (role.approval_status === 'APPROVED') {
      statusText = 'Approved at ' + role.approval_reviewed_at + ' by ' + role.approval_reviewed_by;
    }

    return {
      'Role Name': role.role_name,
      'Role ARN': role.role_arn,
      Status: statusText,
      Actions: btnGroup,
    };
  });

  const roleFields = ['Role Name', 'Role ARN', 'Status', 'Actions'];
  createAndAddInfoTableWide(roleInformation, roleFields, details, true);
}

/**
 * Updates the user confirmation expiration information for the select account. Shows warning messages if the
 * principals of the account never were confirmed so far or if the confirmation is due in less than 50 days (warning)
 * or less than 28 days (danger).
 *
 * It shows the date, how many days are left until the expiration and who did the last confirmation (incl. date).
 *
 * @param {*} data
 */
function showExpirationDate(data) {
  const { data: { account_details: accountDetails = {} } = {} } = data;

  const responsible = checkResponsibility(accountDetails, COGNITO.user.email);

  const {
    approval_expires: expirationDateStr,
    approval_reviewed_by: lastApprover,
    approval_reviewed_at: lastApprovalDateStr,
  } = accountDetails;

  const classic_login_opt_out_status = data.data.account_details?.classic_login_opt_out;

  let alertClass = 'alert-primary';
  let infoIconClass = 'fa-info-circle';

  if (classic_login_opt_out_status) {
    $('#approval-required')
      .empty()
      .append(
        'This account was opted out from the classic login. Reconfirmation of users in this account is done in RightNow.',
      )
      .removeClass('d-none');

    $('#approval-info').addClass('d-none');
  } else {
    if (expirationDateStr) {
      const expirationDate = new Date(expirationDateStr);
      const daysDifference = Math.ceil((expirationDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));

      if (daysDifference <= 28) {
        alertClass = 'alert-danger';
        infoIconClass = 'fa-exclamation-triangle';
      } else if (daysDifference <= 50) {
        alertClass = 'alert-warning';
        infoIconClass = 'fa-exclamation-triangle';
      }

      $('#approval-required')
        .empty()
        .append(
          'Reconfirmation of users in this account is due in ',
          $('<strong>').text(daysDifference + ' days'),
          ' (' + expirationDate.toDateString() + ')',
        )
        .removeClass('d-none');
    } else {
      // No expiration date has been set for the account.
      // This will only happen on the same day that an account has been created. After the check job runs for
      // the first time, an expiration date will be assigned.
      $('#approval-required').empty().addClass('d-none');
    }

    let lastApprovalText = 'this';
    if (lastApprovalDateStr) {
      const lastApprovalDate = new Date(lastApprovalDateStr);
      lastApprovalText = 'This account was last approved on ' + lastApprovalDate.toDateString() + ' by ' + lastApprover;
    } else {
      lastApprovalText =
        'The users of this account were never confirmed so far. ' +
        'Please review the list of users below and remove or confirm them.';
      alertClass = 'alert-danger';
      infoIconClass = 'fa-exclamation-triangle';
    }
    $('#approval-info').text(lastApprovalText);

    $('#approval-info').removeClass('d-none');
  }

  $('#approval-icon').removeClass('fa-info-circle fa-exclamation-triangle').addClass(infoIconClass);
  $('#confirmation-info').removeClass('alert-primary alert-warning alert-danger').addClass(alertClass);
  $('#confirmation-footer').removeClass('d-none');

  if (responsible && !classic_login_opt_out_status) {
    $('#confirm-divider').removeClass('d-none');
    $('#confirm-user-button').removeClass('d-none');
  } else {
    $('#confirm-divider').addClass('d-none');
    $('#confirm-user-button').addClass('d-none');
  }
}

/**
 * Confirm all federated users in the selected AWS account via API call.
 */
export function principalsConfirm(tableId) {
  const account_id = $('#aws-account-id')[0].value.split(';')[0];
  swal({
    title: 'Confirm Users',
    text:
      'Do you confirm that all federated users listed below will keep their access to AWS account ' +
      account_id +
      ' for the next 180 days?',
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      okay: {
        text: 'Confirm',
        value: true,
        visible: true,
      },
    },
  }).then(function (responseSwal) {
    if (responseSwal) {
      baseApiAxios
        .createOrder({
          action: 'confirm-account-users',
          description: 'Confirm users for AWS account ' + account_id,
          account_id: account_id,
        })
        .then(response => {
          let header = {
            'Cache-Control': 'max-age=0, must-revalidate',
          };
          addToast('Account User Confirmation', response.message, 6000);
          loadPrincipalsTableData(tableId, header);
        })
        .catch(showErrorFromApiOperation('Error while creating order to confirm users for AWS account ' + account_id))
        .finally(() => {
          removeSpinners();
        });
    }
  });
}

/**
 * Functions and variables for the user permissions page (List User Permissions)
 */

export const adminUserPermissionsColumns = [
  { id: 'select' },
  { id: 'account_id_col', name: 'Account Id' },
  { id: 'sum_roles_col', name: '# Roles' },
  { id: 'actions_col', name: 'Actions' },
];

const adminUserPermissionsNavigation = [
  {
    Value: 'roles',
    Name: 'Roles',
    AttributeName: 'role-details-tab',
    UidDataAttribute: 'account_id',
    Default: true,
    Callback: formatUserPermissionsTableRolesTab,
  },
];

/**
 * Initialize the datatable to list all permissions of the selected user.
 */
export function initUserPermissionsTable(tableId) {
  configureTableColumns(tableId, adminUserPermissionsColumns);

  const dt = initDataTable(
    tableId,
    'lCfrtpBi',
    [
      {
        extend: 'excelHtml5',
        text: 'Export Excel',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as Excel file',
      },
      {
        extend: 'csvHtml5',
        text: 'Export CSV',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as CSV file',
      },
      {
        extend: 'copyHtml5',
        text: 'Copy',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Copy the visible columns into your clipboard',
      },
      {
        extend: 'resetTable',
        ajaxReload: false,
        titleAttr: 'Reset all filters in the table footer',
      },
      {
        extend: 'reloadTable',
        text: 'Reload User Permissions',
        ajaxReload: false,
        methodReload: loadUserPermissionsTableData,
        titleAttr: 'Reload user permissions (no-cache)',
      },
    ],
    [
      {
        // Column 0
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: null,
        name: 'select',
        class: 'details-control',
        width: '20px',
      },
      {
        // Column 1
        visible: true,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'account_id',
        name: 'account_id_col',
        title: 'Account Id',
        createdCell: accountIdCellCallback,
      },
      {
        // Column 2
        visible: true,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'roles',
        name: 'sum_roles_col',
        title: '# Roles',
        createdCell: addCopyButton,
        render: {
          _: '.length',
          filter: '[, ].role_name',
          display: '.length',
        },
      },
      {
        // Column 3
        visible: true,
        defaultContent: '',
        orderable: false,
        data: null,
        name: 'actions_col',
        title: 'Actions',
        createdCell: addUserPermissionsTableActionButtons,
        class: 'details-edit',
        width: '50px',
      },
    ],
    null,
    {
      order: [[1, 'asc']],
    },
  );

  $('#' + tableId + ' tbody').on('click', 'tr td.details-control', function () {
    const tr = $(this).closest('tr');
    const row = dt.row(tr);
    if (row.child.isShown()) {
      tr.removeClass('details');
      row.child.hide();
    } else {
      tr.addClass('details');
      addRowLoadingAnimation(row);
      formatTableChildRow(row, row.data(), adminUserPermissionsNavigation);
    }
  });
}

/**
 *
 * @param {*} td
 * @param {*} cellData
 * @param {*} rowData
 * @param {*} row
 * @param {*} col
 */
function addUserPermissionsTableActionButtons(td, _cellData, rowData, _row, _col) {
  const user_name = $('#user-email')[0].value.split(';')[0];
  const btnGroup = document.createElement('div');
  btnGroup.setAttribute('class', 'table-action-button-group');
  td.appendChild(btnGroup);

  if (rowData.roles.length > 0) {
    let roleNames = [];
    rowData.roles.forEach(role => {
      roleNames.push(role.role_name);
    });

    let payload = {
      action: 'remove-ad-user',
      account_id: rowData.account_id,
      description: 'Remove Federated User "' + user_name + '" from Role Trust Relationship',
      email: user_name,
      roles: roleNames,
    };
    addContentButtonWithTooltip(
      btnGroup,
      'Delete Role Assignments',
      'fas fa-trash-alt',
      deleteRoleAssignments,
      payload,
      !hasAccess(rowData.account_id, ['manage_users']),
    );
  }
}

/**
 * Load the principals for the selected user via API request.
 *
 * @param {object?} headers
 * @param {boolean?} forceReload
 */
export function loadUserPermissionsTableData(tableId, headers, forceReload) {
  const user_name = $('#user-email')[0].value.split(';')[0];
  if (user_name) {
    addSpinner();
    addTableSpinner();
    getData({
      apiPath: 'users/' + user_name,
      dataAttribute: 'roles',
      tableId: tableId,
      headers: headers,
      forceReload: forceReload,
    });
  }
}

/**
 * Format the roles tab of each AWS account details row.
 *
 * @param {*} data
 * @param {*} content
 */
function formatUserPermissionsTableRolesTab(data, content) {
  // DETAIL ROW - LEFT
  let detailContainer = addContentDiv(content);
  formatUserPermissionsTableRolesTabDetails(data, detailContainer, true);
}

/**
 * Add the role details to the roles tab (formatUserPermissionsTableRolesTab).
 *
 * @param {*} data
 * @param {*} content
 * @param {*} enabled
 */
function formatUserPermissionsTableRolesTabDetails(data, content, _enabled) {
  const roleInformation = [];
  const user_name = $('#user-email')[0].value.split(';')[0];
  const detailContainer = addContentDiv(content, 'detailsContent');
  addContentHeader(detailContainer, 'IAM Roles');
  const details = document.createElement('div');
  detailContainer.appendChild(details);

  if (data.roles) {
    data.roles.forEach(role => {
      const btnGroup = document.createElement('div');
      btnGroup.setAttribute('class', 'table-action-button-group');

      let payload = {
        action: 'remove-ad-user',
        account_id: data.account_id,
        description: 'Remove Federated User "' + user_name + '" from Role Trust Relationship',
        email: user_name,
        roles: [role.role_name],
      };

      addContentButtonWithTooltip(
        btnGroup,
        'Delete Role Assignment',
        'fas fa-trash-alt',
        deleteRoleAssignments,
        payload,
        !hasAccess(data.account_id, ['manage_users']),
      );

      const roleItem = {
        role_name: role.role_name,
        role_arn: role.role_arn,
        approval_status: role.approval_status,
        approval_reviewed_at: role.approval_reviewed_at,
        approval_reviewed_by: role.approval_reviewed_by,
        actions: btnGroup,
      };
      roleInformation.push(roleItem);
    });
  }

  const roleFields = [
    'role_name',
    'role_arn',
    'approval_status',
    'approval_reviewed_at',
    'approval_reviewed_by',
    'actions',
  ];
  createAndAddInfoTableWide(roleInformation, roleFields, details, true);
}

/**
 * Functions and variables for the invalid principals page (List Invalid Principals)
 */

export const adminInvalidPrincipalsColumns = [
  { id: 'account_id_col', name: 'Account Id' },
  { id: 'principal_name_col', name: 'Principal Name' },
  { id: 'principal_type_col', name: 'Principal Type' },
  { id: 'actions_col', name: 'Actions' },
];

/**
 * Initialize the datatable to list all principals that couldn't be validated against the Active Directory.
 */
export function initInvalidPrincipalsTable(tableId) {
  configureTableColumns(tableId, adminInvalidPrincipalsColumns);
  let permissions = localStorage.permissions ? localStorage.permissions : false;

  initDataTable(
    tableId,
    'lCfrtpBi',
    [
      {
        extend: 'excelHtml5',
        text: 'Export Excel',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as Excel file',
      },
      {
        extend: 'csvHtml5',
        text: 'Export CSV',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as CSV file',
      },
      {
        extend: 'copyHtml5',
        text: 'Copy',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Copy the visible columns into your clipboard',
      },
      {
        text: 'Update Account Principals',
        action: function () {
          if (checkValueAgainstList('manage_users', permissions)) {
            triggerUpdatePrincipals();
          } else {
            displayErrorPopup(null, "You don't have permissions to trigger update principals for all AWS accounts");
          }
        },
        titleAttr: 'Trigger the update to re-collect all principals in all AWS accounts',
      },
      {
        extend: 'resetTable',
        ajaxReload: false,
        titleAttr: 'Reset all filters in the table footer',
      },
      {
        extend: 'reloadTable',
        text: 'Reload Principals',
        ajaxReload: false,
        methodReload: loadInvalidPrincipalsTableData,
        titleAttr: 'Reload all invalid principals (no-cache)',
      },
    ],
    [
      {
        // Column 0
        visible: true,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'account_id',
        name: 'account_id_col',
        title: 'Account Id',
        createdCell: accountIdCellCallback,
      },
      {
        // Column 1
        visible: true,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'user_name_original',
        name: 'principal_name_col',
        title: 'Principal Name',
        createdCell: addCopyButton,
      },
      {
        // Column 2
        visible: true,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'principal_type',
        name: 'principal_type_col',
        title: 'Principal Type',
        createdCell: addCopyButton,
      },
      {
        // Column 3
        visible: true,
        defaultContent: '',
        orderable: false,
        data: null,
        name: 'actions_col',
        title: 'Actions',
        createdCell: addInvalidPrincipalsTableActionButtons,
        class: 'details-edit',
        width: '50px',
      },
    ],
    null,
    {
      order: [[1, 'asc']],
    },
  );

  loadInvalidPrincipalsTableData(tableId);
}

/**
 * API request to trigger the update of all principals in all available AWS accounts. This update is executed
 * asynchronous in the backend. Users need to reload the data manually with the reload button of the table (or visit
 * the page to a later point in time).)
 *
 * @param accountId {string?} the account ID, or undefined to trigger update for all accounts
 */
function triggerUpdatePrincipals(accountId) {
  const path = '/accounts' + (accountId ? '/' + accountId : '') + '/trigger-update-principals';

  addSpinner();
  apiAxios
    .post(path)
    .then(response => {
      console.debug('POST ' + path, response);
      addToast('Account Principals Update', response.data.message, 6000);
    })
    .finally(() => {
      removeSpinners();
    });
}

/**
 * Create all buttons which are visible in the actions column of the table (main rows, not detail rows).
 *
 * @param {*} td
 * @param {*} cellData
 * @param {*} rowData
 * @param {*} row
 * @param {*} col
 */
function addInvalidPrincipalsTableActionButtons(td, _cellData, rowData, _row, _col) {
  const accountId = rowData.account_id;

  $(td)
    .empty()
    .append(
      $('<div class="table-action-button-group">').append(
        // Edit Button
        $('<button class="btn btn-custom btn-xs" data-bs-toggle="tooltip" title="Edit AWS Accounts">')
          .on('click', () => {
            window.open(window.location.origin + '/?account_id=' + accountId + '#dashboard', '_blank');
          })
          .append('<span class="fas fa-pencil-alt">'),

        // Refresh Button
        $('<button class="btn btn-custom btn-xs" data-bs-toggle="tooltip" title="Refresh principals for this account">')
          .on('click', () => triggerUpdatePrincipals(accountId))
          .append('<span class="fas fa-sync-alt">'),
      ),
    );
}

/**
 * Loads all invalid principals for all AWS accounts via API request.
 *
 * @param {object} headers
 * @param {boolean} forceReload
 */
function loadInvalidPrincipalsTableData(tableId, headers, forceReload) {
  $(() => addSpinner());
  $(() => addTableSpinner());

  getData({
    apiPath: '/users/invalid',
    dataAttribute: 'user_data',
    tableId: tableId,
    tableColumns: principalsTableColumns,
    dropdownColumns: principalsDropdownColumns,
    searchColumns: principalsSearchColumns,
    headers: headers,
    forceReload: forceReload,
  });
}

/**
 * Shared functions and variables across user management pages
 */

/**
 * Creates a new portal order with the given order payload. This payload must contain already all the information to
 * delete a user from an IAM role trust relationship policy. This order can remove a single user from a single AWS
 * account but from a list of IAM roles.
 *
 * @param {object} payload
 */
function deleteRoleAssignments(payload) {
  swal({
    title: 'Are you sure?',
    text: 'Confirm to remove ' + payload.email + ' from the following IAM role(s): ' + payload.roles.join(', '),
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      confirm: {
        text: 'Confirm',
        value: true,
        visible: true,
        className: 'swal-button swal-button--confirm swal-button--danger',
      },
    },
  }).then(response => {
    if (response) {
      createPortalOrder(payload);
    }
  });
}

/**
 * Updates the role dropdown field default values based on the user permissions.
 */
export function updateListRolesButtonWithoutDefaults() {
  let permissions = localStorage.permissions ? localStorage.permissions : false;
  updateListRolesButton(defaultIamRoles);
}

/**
 * Updates the "List Roles from Account(s)"-Button.
 *
 * Button is activated when at least one account is selected. Button is
 * deactivated when no account is selected. Dropdown list "Target Roles" will
 * get an reset to the list of default_roles.
 *
 * @param {type[]} default_roles - list of strings with role names.
 */
export function updateListRolesButton(default_roles) {
  if (default_roles === undefined) {
    default_roles = [];
  }
  const awsAccountIdPicker = $('#aws-account-id');
  const userEmailPicker = $('#user-email');
  const readRolesButton = $('#readRolesButton');
  const allAccountsCheckBox = $('#allAccounts');
  if (
    (awsAccountIdPicker.val() && awsAccountIdPicker.val().length > 0) ||
    (allAccountsCheckBox.is(':checked') && userEmailPicker.val().length > 0)
  )
    readRolesButton.removeClass('disabled');
  else readRolesButton.addClass('disabled');

  updateRolesSelectPickers(default_roles);
}

/**
 * Update the "Target Roles" dropdown list with a list of IAM roles.
 *
 * @param {object} result
 */
function updateRolesDropdown(result) {
  removeSpinners();
  let roles = [];
  if (result.account_roles?.length) {
    roles = result.account_roles[0].roles.map(role => role.role_name);
    roles.sort();
  }
  updateRolesSelectPickers(roles);
}

/**
 *
 * @param {*} roles
 */
function updateRolesSelectPickers(roles) {
  const selectPickerIds = ['target-roles'];
  if (window.location.hash === '#removeaduser' && $('#allAccounts').is(':checked'))
    selectPickerIds.push('except-roles');

  selectPickerIds.forEach(id => {
    $('#' + id).empty();

    let selectPicker = document.getElementById(id);
    let disabledOption = document.createElement('option');
    disabledOption.value = '';
    disabledOption.innerText =
      id === 'except-roles' ? '- Select IAM Roles to exclude -' : '- Select target IAM Roles -';
    disabledOption.disabled = true;
    selectPicker.appendChild(disabledOption);

    let selectableRoles = id === 'except-roles' ? roles.filter(role => role !== 'all') : roles;
    if (id === 'target-roles' && !selectableRoles.includes('all') && $('#allAccounts').is(':checked'))
      selectableRoles.push('all');

    selectableRoles.forEach(role => {
      let validOption = document.createElement('option');
      validOption.value = role;
      validOption.innerText = role;
      selectPicker.appendChild(validOption);
    });
  });

  $('.selectpicker').selectpicker('refresh');
}

/**
 * Loads a list of IAM roles from selected AWS accounts.
 */
export function readRolesFromAccounts() {
  const selectedAccounts =
    typeof $('#aws-account-id').val() === 'string' ? [$('#aws-account-id').val()] : $('#aws-account-id').val();

  const account_ids = [];
  for (const account of selectedAccounts) {
    account_ids.push(account.split(';')[0]);
  }

  addSpinner();
  addButtonSpinner();

  const params = {
    account_ids: account_ids.join(','),
    list_service_roles: false,
  };

  baseApiAxios.get('/iam/iam-roles', {}, params).then(response => {
    updateRolesDropdown(response);
  });
}

/**
 * Update the "User Names" dropdown list with a list of IAM users.
 *
 * @param {object} result
 */
function updateIamUsersDropdown(users) {
  removeSpinners();
  const sel = document.getElementById('target-user');

  users.sort();
  users.forEach(user => {
    console.debug(user);
    const opt = document.createElement('option');
    opt.value = user.UserName;
    opt.innerText = '' + user.UserName;
    const subText = 'created ' + user.CreateDate;
    opt.setAttribute('Arn', user.Arn);
    opt.setAttribute('Path', user.Path);
    opt.setAttribute('CreateDate', user.CreateDate);
    opt.setAttribute('data-subtext', subText);
    sel.appendChild(opt);
  });
  $('.selectpicker').selectpicker('refresh');
}

/**
 * Loads a list of IAM users from the selected AWS account.
 */
export function readIamUsersFromAccount(accountId) {
  // Clear the IAM user dropdown
  const sel = document.getElementById('target-user');
  for (let i = sel.options.length - 1; i >= 0; i--) {
    sel.remove(i);
  }
  $('.selectpicker').selectpicker('refresh');

  // Add fancy spinners to show the user how hard we're working
  addSpinner();
  addButtonSpinner();
  const loading_target_iam_user = document.querySelector('.loading-target-iam-user');
  if (loading_target_iam_user) loading_target_iam_user.classList.add('loading-animation');

  // Collect all IAM users from the target AWS account
  baseApiAxios.get('/accounts/' + accountId + '/iam-users').then(response => {
    updateIamUsersDropdown(response.users);
  });
}

/**
 *
 */
export function showTemporaryAccessConfiguration() {
  let checkbox = document.getElementById('temporary-access');

  if (checkbox.checked) {
    $('#temporary-access-div').show();
  } else {
    $('#temporary-access-div').hide();
  }
}

/**
 *
 */
function readRolesForUsers() {
  const selectedUsers = $('#user-email').val().join(',');
  baseApiAxios
    .get('/ad-users/roles', {}, { user_names: selectedUsers })
    .then(response => {
      updateRolesDropdown(response);
    })
    .catch(showErrorFromApiOperation('Error retrieving user roles'));
}

/**
 *
 */
export function enableRemoveFromAllAccounts() {
  const allAccountsCheckBox = $('#allAccounts');
  const awsAccountIdPicker = $('#aws-account-id');
  const awsAccountIdRefresh = $('#refresh-accounts');
  const readRolesButton = $('#readRolesButton');
  const exceptRolesRow = $('#exceptRolesRow');
  if (allAccountsCheckBox.is(':checked')) {
    awsAccountIdPicker.attr('disabled', true);
    awsAccountIdRefresh.attr('disabled', true);
    awsAccountIdPicker.attr('required', false);
    readRolesButton.text('List Roles for User(s)');
    readRolesButton.off('click').on('click', function () {
      readRolesForUsers();
    });
    $('#infobox-target-roles > .card-body').html(
      '<code>All:</code> This option will remove the given user from all available IAM Roles in an AWS Account,' +
        ' beside of administrative IAM Roles for the BMW Framework for Public Cloud and of the IAM Roles ' +
        'reported in the exclude field.<br />',
    );
    awsAccountIdPicker.selectpicker('val', '');
    exceptRolesRow.show();
  } else {
    awsAccountIdPicker.attr('disabled', false);
    awsAccountIdRefresh.attr('disabled', false);
    awsAccountIdPicker.attr('required', true);
    readRolesButton.text('List Roles from Account(s)');
    readRolesButton.off('click').on('click', function () {
      readRolesFromAccounts();
    });
    $('#infobox-target-roles > .card-body').html(
      '<code>All:</code> This option will remove the given user from all available IAM Roles in an AWS Account,' +
        ' beside of administrative IAM Roles for the BMW Framework for Public Cloud.<br />',
    );
    exceptRolesRow.hide();
  }
  updateListRolesButton(defaultIamRoles.concat(['all']));
}

/**
 *
 */
export function renderRemoveADUserForm() {
  let permissions = localStorage.permissions ? localStorage.permissions : false;

  $('#readRolesButton')
    .off('click')
    .on('click', function () {
      readRolesFromAccounts();
    });

  if (!checkValueAgainstList('manage_users', permissions)) {
    $('#allAccountsRow').empty();
    $('#exceptRolesRow').empty();
  }
}

/**
 *
 * @param {object} orderBody
 * @returns
 */
const promisifyCreateOrder = orderBody => {
  return new Promise(resolve => {
    baseApiAxios
      .createOrder(orderBody)
      .then(() => resolve(false))
      .catch(err => resolve(err.message || 'Error creating the order'));
  });
};

/**
 *
 * @param {*} orderBodies
 */
const renderResultOrders = orderBodies => {
  Promise.all(orderBodies.map(body => promisifyCreateOrder(body)))
    .then(result => {
      const submitted = result.filter(value => !value);
      const failed = result.filter(value => !!value);
      if (submitted.length)
        displaySuccess(
          `You've successfully created <strong>${submitted.length}</strong> new order(s). Please check your orders for the current approval/execution status.`,
        );
      failed.forEach(errorMessage => displayError(`<strong>Error</strong>: ${errorMessage}`));
    })
    .catch(displayErrorPopup)
    .finally(() => {
      removeSpinners();
      document.getElementById('user-remove-form').reset();
      $('.selectpicker').not('no-deselect').selectpicker('deselectAll');
      $('.selectpicker').selectpicker('refresh');
    });
};

/**
 *
 */
export function submitRemoveADOrder() {
  addSpinner();
  addButtonSpinner();
  const selectedUsers = $('#user-email').val();
  const selectedRoles = $('#target-roles').val();
  const isRemoveADUserRolesOrder = $('#allAccounts').is(':checked');
  if (isRemoveADUserRolesOrder) {
    swal({
      title: 'Are you sure?',
      text: 'This option will remove the selected users from all the accounts where they have roles',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: false,
          visible: true,
        },
        reset: {
          text: 'Continue',
          value: true,
          className: 'swal-button swal-button--confirm swal-button--danger',
        },
      },
      dangerMode: true,
    }).then(response => {
      if (response)
        renderResultOrders(createRemoveADRolesUserOrders(selectedUsers, selectedRoles, $('#except-roles').val()));
    });
  } else renderResultOrders(createRemoveADUserOrders(selectedUsers, $('#aws-account-id').val(), selectedRoles));
}

/**
 *
 * @param {*} selectedUsers
 * @param {*} selectedAccounts
 * @param {*} selectedRoles
 * @returns
 */
const createRemoveADUserOrders = (selectedUsers, selectedAccounts, selectedRoles) => {
  const orderBodies = [];
  selectedUsers.forEach(user => {
    selectedAccounts.forEach(account => {
      orderBodies.push({
        action: 'remove-ad-user',
        description: 'Remove Federated User "' + user.toLowerCase() + '" from Role Trust Relationship',
        email: user,
        roles: selectedRoles,
        account_id: account.split(';')[0],
      });
    });
  });
  return orderBodies;
};

/**
 *
 * @param {*} selectedUsers
 * @param {*} selectedRoles
 * @param {*} exceptedRoles
 * @returns
 */
const createRemoveADRolesUserOrders = (selectedUsers, selectedRoles, exceptedRoles) => {
  const orderBodies = [];
  selectedUsers.forEach(user => {
    orderBodies.push({
      action: 'remove-ad-user-roles',
      description: 'Remove Federated User "' + user.toLowerCase() + '" from all Role Trust Relationship',
      email: user,
      roles: selectedRoles,
      except: exceptedRoles,
    });
  });
  return orderBodies;
};

export function assignADUserOrdersPolicyDistinction(response) {
  const selectedUsers = $('#user-email').val();
  const selectedRoles = $('#target-roles').val();
  let checkbox = document.getElementById('temporary-access');
  const policyLengthLimit = 4096;
  const policyExtraText = 46;
  var ordersToBe = [];
  var ordersSummary = [];
  response.account_roles.forEach(function (account) {
    selectedUsers.forEach(function (user) {
      let account_payload = {
        account_id: account.account_id,
        email: user,
        roles: [],
      };
      account.roles.forEach(function (role) {
        if (selectedRoles.includes(role.role_name)) {
          let updatedPolicyLength = role.policy_length + policyExtraText + user.length;
          if (policyLengthLimit > updatedPolicyLength) {
            role.policy_length = updatedPolicyLength;
            account_payload.roles.push(role.role_name);
            ordersSummary.push({
              AccountID: account.account_id,
              User: user,
              Role: role.role_name,
              Status: 'Ordered',
            });
          } else {
            ordersSummary.push({
              AccountID: account.account_id,
              User: user,
              Role: role.role_name,
              Status: 'Cancelled',
            });
          }
        }
      });
      if (account_payload.roles.length > 0) {
        if (checkbox.checked) {
          account_payload['action'] = 'grant-temporary-access';
          account_payload['comment'] = $('#temp-access-comment').val();
          account_payload['description'] = 'Grant Temporary Access for Federated User ' + user.toLowerCase();
          account_payload['hours'] = $('#temporary-hours').val();
        } else {
          account_payload['action'] = 'assign-ad-user';
          account_payload['description'] = 'Add Federated User "' + user.toLowerCase() + '" to Role Trust Relationship';
        }
        ordersToBe.push(account_payload);
      }
    });
  });
  return { ordersToBe: ordersToBe, ordersSummary: ordersSummary };
}
