import { OverviewSwitch, toggleOverview } from './js/portal/main';
import { initFourWheelsTable } from './js/portal/fourwheels';
import kubernetesBrandImageSrc from './images/kubernetes-brands.svg';
import { Datatable } from './Datatable';

const tableId = 'table-fourwheels';

export default function FourWheelsListPage() {
  const page = (
    <>
      {/* WELL AND AWS ACCOUNT COUNTER */}
      <div id="fourwheels_overview" class="row row-cols-1 row-cols-lg-3 collapse show">
        <div class="col overview" id="fourwheelsDocumentation">
          <h3 class="overview">Documentation</h3>

          <ul class="overview">
            <li>
              <a
                href="https://4wheels-docs.bmwgroup.net/aws/"
                title="4wheels Documentation"
                target="_blank"
                class="overview btn"
                rel="noopener noreferrer">
                <i class="fas fa-book" /> 4wheels Product Documentation
              </a>
            </li>
            <li>
              <a
                href="https://atc.bmwgroup.net/confluence/display/DEVOPSPF/questions/all"
                title="DevOps Platform Questions"
                target="_blank"
                class="overview btn"
                rel="noopener noreferrer">
                <i class="fas fa-book" /> DevOps Platform Questions
              </a>
            </li>
          </ul>
        </div>

        <div class="col overview" id="fourwheelsSupport">
          <h3 class="overview">Support</h3>

          <ul class="overview">
            <li>
              <a
                href="https://developer.bmwgroup.net/docs/4wheels-guided/support/support/#support-options"
                title="4wheels support page"
                target="_blank"
                class="overview btn"
                rel="noopener noreferrer">
                <i class="fas fa-envelope" /> Contact the 4wheels Team
              </a>
            </li>
            <li>
              <a
                href="https://atc.bmwgroup.net/confluence/display/ITLAB/Skype+Walk-In+Session%3A+Cloud#SkypeWalkInSession:Cloud-SkypeWalk-InSession:PublicCloud-4wheelsKubernetestopics(AzureorAWS)%E2%86%92Joinmeeting"
                title="4wheels Drop-In Sessions"
                target="_blank"
                class="overview btn"
                rel="noopener noreferrer">
                <i class="fas fa-calendar-alt" /> Organize a Drop-In Session
              </a>
            </li>
          </ul>
        </div>

        <div class="col overview" id="4wheelsProjects">
          <h3 class="overview">4wheels Projects</h3>
          <div class="fourwheelscount">
            <img class="kubernetes" src={kubernetesBrandImageSrc} alt="Kubernetes Logo" />
            <div id="fourwheelsProjects">0</div>
          </div>
        </div>
      </div>

      <hr id="fourwheels_overview_hr" class="cloudcount collapse show" />

      <div class="row row-cols-auto justify-content-end gy-3" style="margin-bottom: 20px">
        <div class="form-check form-switch">
          <input class="form-check-input danger" type="checkbox" id="show-all-four-wheels-switch" />
          <label class="form-check-label" for="show-all-four-wheels-switch">
            Show all clusters
          </label>
        </div>

        <OverviewSwitch
          targets="#fourwheels_overview,#fourwheels_overview_hr"
          localStorageItem="hidden_fourwheels_overview"
        />
      </div>

      <Datatable tableId={tableId} />
    </>
  );

  function onPageReady() {
    toggleOverview('hidden_fourwheels_overview');
    initFourWheelsTable(tableId);
  }

  return [page, onPageReady];
}
