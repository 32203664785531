import { baseApiAxios } from './js/portal/api';
import { showErrorFromApiOperation } from './js/portal/main';
import { addSpinner, removeSpinners } from './js/portal/sidebar';
import VPCCompare from './jsx/components/forms/VPCCompare';
import VPCDropdown from './jsx/components/forms/VPCDropdown';

import equalsImageSrc from '../images/equals.svg';
import { getSearchParamsUrl, setSearchParamsUrl } from './js/portal/search_params';

const SECTION_IDS = ['vpc', 'subnets', 'peerings', 'dx', 'r53', 'logging'];
const SECTION_LABELS = {
  vpc: 'VPC',
  subnets: 'Subnets & Routing',
  peerings: 'VPC Peerings',
  dx: 'Direct Connect',
  r53: 'Route R53 Resolver Rules',
  logging: 'Logging',
};
let data_cache = {};
let selected_left = { id: '', uuid: '', resource_id: [], sections: [] };
let selected_right = { id: '', uuid: '', resource_id: [], sections: [] };

function renderContent(data, sections, column) {
  // remove placeholder and old content blocks
  $('#no-vpc-selected').remove();
  $(`.content-block-${column}`).remove();
  $(`.compare-content`).append(<VPCCompare data={data} sections={sections} column={column} />);

  // display equals image if the same VPCs are selected
  if (selected_left.uuid && selected_left.uuid === selected_right.uuid) {
    $('.compare-resources-equal').addClass('is-equal');
  } else {
    $('.compare-resources-equal').removeClass('is-equal');
  }

  // wrap content blocks in rows and hide not selected sub-sections
  const visible_sections = [...new Set(selected_left.sections.concat(selected_right.sections))];

  for (const section of SECTION_IDS) {
    let container = $(`.section-${section}-container`);
    if (!container.length) {
      container = $(
        `<div class="row section-container section-${section}-container" data-section="${SECTION_LABELS[section]}" />`
      );
      $(`.section-${section}`).wrapAll(container);
    } else {
      if (column === 'left') {
        $(`.section-${section}.content-block-left`).prependTo(container);
      } else if (column === 'right') {
        $(`.section-${section}.content-block-right`).appendTo(container);
      }
    }
    if (visible_sections.indexOf(section) === -1) {
      container.addClass('d-none');
    } else {
      container.removeClass('d-none');
    }
  }
}

function flattenObject(obj) {
  const object = Object.create(null);
  const path = [];
  const isObject = value => Object(value) === value;

  function dig(objDig) {
    for (let [key, value] of Object.entries(objDig)) {
      path.push(key);
      if (isObject(value)) dig(value);
      else object[path.join('-')] = value;
      path.pop();
    }
  }

  dig(obj);
  return object;
}

function diffFlatten(oldFlat, newFlat) {
  const updated = Object.assign({}, oldFlat);
  const removed = Object.assign({}, newFlat);
  const same = {};
  for (let key in newFlat) {
    if (newFlat[key] === oldFlat[key]) {
      delete updated[key];
      delete removed[key];
      same[key] = true;
    }
  }
  return [updated, removed, same];
}

// reload dropdown options + delete cached data for and reload the currently selected VPC
function deleteCachedData(_event, select_element) {
  const selected_option = $(select_element).find(':selected');
  const id = selected_option.data('id');
  const uuid = selected_option.data('uuid');
  if (data_cache[id + '-' + uuid]) delete data_cache[id + '-' + uuid];
  $(select_element).trigger('reload-dropdown-options');
  $(select_element).selectpicker('val', selected_option.val());
}

// transform route data before comparison (-> updated key names in diff object)
function transformRouteData(subnet) {
  if (subnet.routing_table?.Routes?.length) {
    let new_routes = [];
    for (const route of subnet.routing_table.Routes) {
      let minimizedRoute = {};
      for (const key in route) {
        if (['DestinationCidrBlock', 'DestinationPrefixListId'].indexOf(key) > -1) {
          minimizedRoute['Destination'] = route[key];
        } else if (['GatewayId', 'NatGatewayId', 'TransitGatewayId', 'VpcPeeringConnectionId'].indexOf(key) > -1) {
          minimizedRoute['Target'] = route[key];
        } else if (key === 'State') {
          minimizedRoute['State'] = route[key];
        }
      }
      new_routes.push(minimizedRoute);
    }
    let new_subnet = subnet;
    new_subnet.routing_table.Routes = new_routes;
    return new_subnet;
  }
  return subnet;
}

function routesHelper(api_result) {
  let new_data = api_result;
  if (new_data.network_item.subnets?.public?.length) {
    const public_subnets = new_data.network_item.subnets.public.map(transformRouteData);
    new_data.network_item.subnets.public = public_subnets;
  }
  if (new_data.network_item.subnets?.private?.length) {
    const private_subnets = new_data.network_item.subnets.private.map(transformRouteData);
    new_data.network_item.subnets.private = private_subnets;
  }
  if (new_data.network_item.subnets?.intranet?.length) {
    const intranet_subnets = new_data.network_item.subnets.intranet.map(transformRouteData);
    new_data.network_item.subnets.intranet = intranet_subnets;
  }
  return new_data;
}

function sectionsDropdownHelper(id) {
  return (
    <select
      required
      class="form-control form-select selectpicker sections-dropdown"
      data-live-search="true"
      data-show-subtext="true"
      id={id}
      name={id}
      data-size="10"
      data-none-selected-text="- Select one or more Sub-Sections -"
      data-selected-text-format="count > 1"
      data-count-selected-text="{0} Sections selected"
      data-dropdown-align-right="auto"
      data-actions-box="true"
      multiple={true}>
      <option class="defaultDisabled" value="" disabled>
        Sub-Sections
      </option>
      <option value="vpc">VPC Details</option>
      <option value="subnets">Subnets &amp; Routing</option>
      <option value="peerings">VPC Peerings</option>
      <option value="dx">Direct Connect</option>
      <option value="r53">Route 53 Resolver Rules</option>
      <option value="logging">Logging</option>
    </select>
  );
}

export default function NetworksComparePage() {
  let highlightEnabled = sessionStorage.getItem('compareHighlightDisabled') !== 'true';

  // get vpc_id(s) from URL
  const vpc_id_param = getSearchParamsUrl('vpc_ids');
  if (vpc_id_param) {
    const vpc_ids = vpc_id_param.split(',');
    if (vpc_ids.length >= 2) {
      selected_left.resource_id = vpc_ids[0];
      selected_right.resource_id = vpc_ids[1];
    } else {
      selected_left.resource_id = vpc_id_param;
    }
  }

  const loadComparisonData = async (id, uuid) => {
    if (!id || !uuid) return;
    if (Object.keys(data_cache).indexOf(id + '-' + uuid) === -1) {
      try {
        addSpinner();
        const resource_item_result = await baseApiAxios.getNetworkItem(id, uuid, 'VPC');
        if (resource_item_result) {
          const result = routesHelper(resource_item_result);
          data_cache[id + '-' + uuid] = result;
        }
      } catch (err) {
        showErrorFromApiOperation('Fetching VPC information failed.')(err);
      }
      removeSpinners();
    }
  };

  const page = (
    <>
      <div class="my-3">
        <span class="d-flex">
          <div class="flex-grow-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#listvpc">List VPCs</a>
                </li>
                <li class="breadcrumb-item active edit-account-show" style="" aria-current="page">
                  Compare VPCs
                </li>
              </ol>
            </nav>
          </div>
          <div id="highlight-diff-div" class="col col-auto align-self-center mb-3">
            <div class="form-check form-switch">
              <input class="form-check-input success" type="checkbox" id="highlight-diff-switch" />
              <label class="form-check-label" for="highlight-diff-switch">
                Highlight
              </label>
            </div>
          </div>
        </span>
      </div>
      <div style="position:relative;">
        <img src={equalsImageSrc} height="15" class="compare-resources-equal" />
      </div>
      <div class="row g-3" style="margin-bottom: 20px">
        <div class="col-sm-12 col-md-6">
          <div class="compare-dropdown-container">
            <div class="form-group row">
              <div class="col-12">
                <VPCDropdown
                  id="left-compare-vpc"
                  label="none"
                  onrefresh={deleteCachedData}
                  disableUrlParams
                  selectedValues={selected_left.resource_id}
                  listAll
                  inline
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">{sectionsDropdownHelper('left-sections-dropdown')}</div>
            </div>
          </div>
          {/* <div class="container-fluid mt-3 left-compare-content"></div> */}
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="compare-dropdown-container">
            <div class="form-group row">
              <div class="col-12">
                <VPCDropdown
                  id="right-compare-vpc"
                  label="none"
                  onrefresh={deleteCachedData}
                  disableUrlParams
                  selectedValues={selected_right.resource_id}
                  listAll
                  inline
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">{sectionsDropdownHelper('right-sections-dropdown')}</div>
            </div>
          </div>
          {/* <div class="container-fluid mt-3 right-compare-content"></div> */}
        </div>
      </div>
      <div class={'mt-1 compare-content' + (highlightEnabled ? '' : ' disable-highlighting')}>
        <div class="col-12 compare-section-container" id="no-vpc-selected">
          <div class="row">
            <div class="col-12">
              <div class="detailsContent text-center">
                <h4 class="mb-0">No VPCs selected</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  function onPageReady() {
    const sections_dropdowns = $('.selectpicker.sections-dropdown');

    // if section dropdown values change, render content and compare data objects
    sections_dropdowns.on('change', evt => {
      const selected_sections = $(evt.target).val();
      if (evt.target.id === 'left-sections-dropdown') {
        selected_left.sections = selected_sections;
        if (!data_cache[selected_left.id + '-' + selected_left.uuid]) return;
        renderContent(data_cache[selected_left.id + '-' + selected_left.uuid], selected_sections, 'left');
      } else if (evt.target.id === 'right-sections-dropdown') {
        selected_right.sections = selected_sections;
        if (!data_cache[selected_right.id + '-' + selected_right.uuid]) return;
        renderContent(data_cache[selected_right.id + '-' + selected_right.uuid], selected_sections, 'right');
      }
      if (
        data_cache[selected_left.id + '-' + selected_left.uuid] &&
        data_cache[selected_right.id + '-' + selected_right.uuid]
      ) {
        const flattened_left = flattenObject(data_cache[selected_left.id + '-' + selected_left.uuid]);
        const flattened_right = flattenObject(data_cache[selected_right.id + '-' + selected_right.uuid]);
        const flatDiff = diffFlatten(flattened_left, flattened_right);
        const [_updated, _removed, same] = flatDiff;
        $('.compare-highlight').removeClass('compare-highlight');
        for (const field_class of Object.keys(same)) {
          $(`.${field_class}`).addClass('compare-highlight');
        }
      }
    });

    const left_vpc_dropdown = $('#left-compare-vpc');
    const right_vpc_dropdown = $('#right-compare-vpc');

    left_vpc_dropdown.on('changed.bs.select', evt => {
      const selected_option = $(evt.target).find(':selected');
      selected_left.id = selected_option.data('id');
      selected_left.uuid = selected_option.data('uuid');
      loadComparisonData(selected_left.id, selected_left.uuid).then(() => {
        const left_sections_dropdown = $('#left-sections-dropdown');
        left_sections_dropdown.selectpicker('selectAll');
        left_sections_dropdown.trigger('change');
      });
      const vpc_id_right = $(right_vpc_dropdown).find(':selected').val();
      if (selected_option.val() && vpc_id_right) {
        setSearchParamsUrl({ vpc_ids: `${selected_option.val()},${vpc_id_right}` });
      }
    });

    right_vpc_dropdown.on('changed.bs.select', evt => {
      const selected_option = $(evt.target).find(':selected');
      selected_right.id = selected_option.data('id');
      selected_right.uuid = selected_option.data('uuid');
      loadComparisonData(selected_right.id, selected_right.uuid).then(() => {
        const right_sections_dropdown = $('#right-sections-dropdown');
        right_sections_dropdown.selectpicker('selectAll');
        right_sections_dropdown.trigger('change');
      });
      const vpc_id_left = $(left_vpc_dropdown).find(':selected').val();
      if (selected_option.val() && vpc_id_left) {
        setSearchParamsUrl({ vpc_ids: `${vpc_id_left},${selected_option.val()}` });
      }
    });

    $('#highlight-diff-switch')
      .attr('checked', highlightEnabled)
      .on('change', function () {
        if (highlightEnabled !== this.checked) {
          highlightEnabled = this.checked;
          sessionStorage.setItem('compareHighlightDisabled', !this.checked);
          const container = $('.compare-content');
          if (this.checked) {
            container.removeClass('disable-highlighting');
          } else {
            container.addClass('disable-highlighting');
          }
        }
      });
  }

  return [page, onPageReady];
}
