import swal from 'sweetalert';
import { baseApiAxios } from './api';
import {
  addCopyButton,
  addLoadAccountDetailsButton,
  apiKeyAdminInfoFields,
  apiKeyInfoFields,
  apiKeyUsagePlanInfoFields,
  renderInfoTable,
  configureTableColumns,
  addRowLoadingAnimation,
  displaySuccess,
  displayError,
} from './main';
import { addSpinner, removeSpinners } from './sidebar';
import ApiKeyEditForm from '../../ApiKeyEditForm';
import ApiKeyPermissionsEditForm from '../../ApiKeyPermissionsEditForm';
import { accountIdPattern } from './accounts';
import { initDataTable } from './datatable';

export const apiKeysTableColumns = [
  { id: 'select_col' },
  { id: 'api_key_id_col', name: 'Key Id' },
  { id: 'custom_name_col', name: 'Friendly Name' },
  { id: 'custom_description_col', name: 'Friendly Description' },
  { id: 'value_col', name: 'Key Value' },
  { id: 'created_date_col', name: 'Creation Date' },
  { id: 'enabled_col', name: 'Enabled' },
  { id: 'status_col', name: 'Status' },
  { id: 'quota_used_col', name: 'Today Quota Used' },
  { id: 'quota_left_col', name: 'Today Quota Left' },
  { id: 'usage_plan_id_col', name: 'Usage Plan Id' },
  { id: 'usage_plan_name_col', name: 'Usage Plan Name' },
  { id: 'last_updated_date_col', name: 'Last Update' },
  { id: 'extend_col', name: 'Extend Quota' },
  { id: 'delete_col', name: 'Delete Key' },
  { id: 'edit_col' },
];

export const apiAdminKeysTableColumns = [
  //Column 0
  { id: 'select_col' },
  //Column 1
  { id: 'api_key_id_col', name: 'Key Id' },
  //Column 2
  { id: 'custom_name_col', name: 'Friendly Name' },
  //Column 3
  { id: 'custom_description_col', name: 'Friendly Description' },
  //Column 4
  { id: 'name_col', name: 'Key Name' },
  //Column 5
  { id: 'description_col', name: 'Description' },
  //Column 6
  { id: 'created_date_col', name: 'Creation Date' },
  //Column 7
  { id: 'enabled_col', name: 'Enabled' },
  //Column 8
  { id: 'status_col', name: 'Status' },
  //Column 9
  { id: 'quota_used_col', name: 'Today Quota Used' },
  //Column 10
  { id: 'quota_left_col', name: 'Today Quota Left' },
  //Column 11
  { id: 'usage_plan_id_col', name: 'Usage Plan Id' },
  //Column 12
  { id: 'usage_plan_name_col', name: 'Usage Plan Name' },
  //Column 13
  { id: 'last_updated_date_col', name: 'Last Update' },
  //Column 14
  { id: 'extend_col', name: 'Extend Quota' },
];

const editApiKeyFields = [
  'api_key_id',
  'custom_name',
  'custom_description',
  'usage_plan_id',
  'target_api',
  'provider_id',
];

const apiKeyDetailsNavigation = [
  { Value: 'api_key', Name: 'API Key' },
  { Value: 'permissions', Name: 'Permissions' },
];

const editApiKeyPermissionsFields = ['api_key_id', 'usage_plan_id', 'target_api', 'provider_id'];

/*
function to store the possible resources and methods and avoid call everytime
*/
export function addApiKeyPermissionsMapping(result) {
  let ordered_permissions = {};
  Object.keys(result.items)
    .sort()
    .forEach(key => {
      ordered_permissions[key] = result.items[key];
    });
  localStorage.setItem('apiKeyPermissionsMapping', JSON.stringify(ordered_permissions));
}

function stateSaveParams(_settings, data) {
  for (let i = 0, ien = data.columns.length; i < ien; i++) {
    delete data.columns[i].search;
  }
}

// Array to track the ids of the details displayed rows
let detailRowsApiKeys = [];

export function initAdminApiKeysTable(tableId) {
  configureTableColumns(tableId, apiAdminKeysTableColumns);

  const dt = initDataTable(
    tableId,
    'lCfrtpBi',
    [
      {
        name: 'api-key-csv-export-button',
        extend: 'csvHtml5',
        text: 'Export CSV',
        exportOptions: {
          columns: ':visible',
        },
      },
      {
        name: 'api-key-copy-button',
        extend: 'copyHtml5',
        text: 'Copy',
        exportOptions: {
          columns: ':visible',
        },
      },
    ],
    [
      //Column 0
      {
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: null,
        name: 'select_col',
        class: 'details-control',
        width: '20px',
      },
      //Column 1
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'api_key_id',
        name: 'api_key_id_col',
        title: 'Key Id',
        createdCell: addCopyButton,
      },
      //Column 2
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'custom_name',
        name: 'custom_name_col',
        title: 'Friendly Name',
        createdCell: addCopyButton,
      },
      //Column 3
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'custom_description',
        name: 'custom_description_col',
        title: 'Friendly Description',
        createdCell: addCopyButton,
      },
      //Column 4
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'name',
        name: 'name_col',
        title: 'Key Name',
        createdCell: function (td) {
          addCopyButton(td);
          addLoadAccountDetailsButton(td);
        },
      },
      //Column 5
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'description',
        name: 'description_col',
        title: 'Description',
        createdCell: addCopyButton,
      },
      //Column 6
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'created_date',
        name: 'created_date_col',
        title: 'Creation Date',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'enabled',
        name: 'enabled_col',
        title: 'Enabled',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'status',
        name: 'status_col',
        title: 'Status',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'usage.today.used',
        name: 'quota_used_col',
        title: 'Today Quota Used',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'usage.today.left',
        name: 'quota_left_col',
        title: 'Today Quota Left',
        createdCell: createdCellQuotaLeft,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'usage_plan_id',
        name: 'usage_plan_id_col',
        title: 'Usage Plan Id',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'usage_plan_name',
        name: 'usage_plan_name_col',
        title: 'Usage Plan Name',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'last_updated_date',
        name: 'last_updated_date_col',
        title: 'Last Update',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: true,
        data: null,
        name: 'extend_col',
        title: 'Extend Quota',
        createdCell: createdCellExtendQuota,
        class: 'details-edit',
        width: '50px',
      },
    ],
    rowCallbackForDeleted,
    {
      stateSaveParams: stateSaveParams,
    },
  );

  $('#' + tableId + ' tbody').on('click', 'tr td.details-control', function () {
    const tr = $(this).closest('tr');
    const row = dt.row(tr);
    const idx = $.inArray(tr.attr('id'), detailRowsApiKeys);
    if (row.child.isShown()) {
      tr.removeClass('details');
      row.child.hide();
      // Remove from the 'open' array
      detailRowsApiKeys.splice(idx, 1);
    } else {
      tr.addClass('details');
      addRowLoadingAnimation(row);
      row.child(formatChildRowApiKey(row, true)).show();
      row.child()[0].setAttribute('class', 'rowDetails');
      // Add to the 'open' array
      if (idx === -1) {
        detailRowsApiKeys.push(tr.attr('id'));
      }
    }
  });
}

export function initApiKeysTable(tableId, key_usage) {
  configureTableColumns(tableId, apiKeysTableColumns);

  const dt = initDataTable(
    tableId,
    'lCfrtpBi',
    [
      {
        name: 'api-key-csv-export-button',
        extend: 'csvHtml5',
        text: 'Export CSV',
        exportOptions: {
          columns: ':visible',
        },
      },
      {
        name: 'api-key-copy-button',
        extend: 'copyHtml5',
        text: 'Copy',
        exportOptions: {
          columns: ':visible',
        },
      },
      {
        name: 'api-key-create-button',
        text: 'Create new API Key',
        action: function () {
          createApiKey(key_usage);
        },
      },
    ],
    [
      {
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: null,
        name: 'select_col',
        class: 'details-control',
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'api_key_id',
        name: 'api_key_id_col',
        title: 'Key Id',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'custom_name',
        name: 'custom_name_col',
        title: 'Friendly Name',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'custom_description',
        name: 'custom_description_col',
        title: 'Friendly Description',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: null,
        name: 'value_col',
        title: 'Key Value',
        createdCell: createdCellKeyValue,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'created_date',
        name: 'created_date_col',
        title: 'Creation Date',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'enabled',
        name: 'enabled_col',
        title: 'Enabled',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'status',
        name: 'status_col',
        title: 'Status',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'usage.today.used',
        name: 'quota_used_col',
        title: 'Today Quota Used',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'usage.today.left',
        name: 'quota_left_col',
        title: 'Today Quota Left',
        createdCell: createdCellQuotaLeft,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'usage_plan_id',
        name: 'usage_plan_id_col',
        title: 'Usage Plan Id',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'usage_plan_name',
        name: 'usage_plan_name_col',
        title: 'Usage Plan Name',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'last_updated_date',
        name: 'last_updated_date_col',
        title: 'Last Update',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: false,
        searchable: true,
        data: null,
        name: 'extend_col',
        title: 'Extend Quota',
        createdCell: createdCellExtendQuota,
        class: 'details-edit',
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: false,
        searchable: false,
        data: null,
        name: 'delete_col',
        title: 'Delete Key',
        createdCell: createdCellDeleteKey,
        class: 'details-edit',
      },
      {
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: null,
        name: 'edit_col',
        title: 'Edit',
        createdCell: createdCellEdit,
        class: 'details-edit',
      },
    ],
    rowCallbackForDeleted,
    {
      stateSaveParams: stateSaveParams,
    },
  );

  $('#' + tableId + ' tbody').on('click', 'tr td.details-control', function () {
    const tr = $(this).closest('tr');
    const row = dt.row(tr);
    const idx = $.inArray(tr.attr('id'), detailRowsApiKeys);
    if (row.child.isShown()) {
      tr.removeClass('details');
      row.child.hide();
      // Remove from the 'open' array
      detailRowsApiKeys.splice(idx, 1);
    } else {
      tr.addClass('details');
      addRowLoadingAnimation(row);
      row.child(formatChildRowApiKey(row, false)).show();
      row.child()[0].setAttribute('class', 'rowDetails');
      // Add to the 'open' array
      if (idx === -1) {
        detailRowsApiKeys.push(tr.attr('id'));
      }
    }
  });

  if (key_usage === 'portal') {
    loadKeysForUser();
  } else {
    if (dt.buttons('api-key-create-button:name')) {
      dt.buttons('api-key-create-button:name').disable();
    }
  }
}

/**
 * Created Cell functions
 */
function createdCellKeyValue(td, _cellData, rowData) {
  if (rowData['status'] !== 'deleted') {
    const { api_key_id: apiKeyId, target_api: targetApi, provider_id: providerId } = rowData;
    td.setAttribute('id', 'td_key_value_' + rowData['api_key_id']);
    $(td)
      .empty()
      .append(
        <>
          <span>******************************</span>
          <span
            style={{ cursor: 'pointer' }}
            title="Show key value"
            class="helperBtn fas fa-search"
            onclick={() => showKeyValue(apiKeyId, targetApi, providerId)}
          />
        </>,
      );
  }
}

function showKeyValue(api_key_id, target_api, provider_id) {
  let td_key_value = $('#td_key_value_' + api_key_id);
  td_key_value.html('<i class="fas fa-spinner fa-pulse fa-fw"></i>');
  const payload = {
    action: 'show-api-key-value',
    account_id: provider_id,
    api_key_id: api_key_id,
    target: target_api,
  };

  baseApiAxios
    .postApiKeyData(payload)
    .then(result => {
      td_key_value.html(result.value);
      addCopyButton(td_key_value[0]);
      $(window).trigger('resize');
    })
    .catch(err => {
      td_key_value.html('<strong>Error retrieving value</strong>');
      displayError(`<strong>Warning:</strong> ${err.message}`);
    })
    .finally(() => {
      removeSpinners();
    });
}

function createdCellExtendQuota(td, _cellData, rowData) {
  if (rowData['status'] !== 'deleted') {
    let today_undefined_condition = rowData['usage'] !== undefined && rowData['usage']['today'] !== undefined;
    let today_left = today_undefined_condition ? rowData['usage']['today']['left'] : undefined;
    let today_used = today_undefined_condition ? rowData['usage']['today']['used'] : undefined;
    if (today_left !== undefined && today_used !== undefined) {
      const wrapperDiv = $('<div>');
      const extendQuotaButton = $('<button type="button" class="btn btn-success table-cell-button">')
        .html('<span class="fas fa-plus"></span> Extend Quota')
        .appendTo(wrapperDiv);
      const perc_left = (100 / (today_left + today_used)) * today_left;
      if (perc_left > 25 || (rowData['provider_id'] && !accountIdPattern.test(rowData['provider_id']))) {
        extendQuotaButton.attr('disabled', true);
        wrapperDiv.css({ cursor: 'not-allowed' });
      } else {
        extendQuotaButton.on('click', () => extendKeyUsageQuota(rowData));
      }
      $(td).empty().append(wrapperDiv);
    }
  }
}

function createdCellDeleteKey(td, _cellData, rowData, row) {
  if (rowData['status'] !== 'deleted') {
    $(td)
      .empty()
      .append(
        $('<button type="button" class="btn btn-danger table-cell-button">')
          .on('click', () => deleteApiKey(row, rowData['target_api']))
          .html('<span class="fas fa-times"></span> Delete Key'),
      );
  }
}

function createdCellQuotaLeft(td, _cellData, rowData) {
  addCopyButton(td);
  let today_undefined_condition = rowData['usage'] !== undefined && rowData['usage']['today'] !== undefined;
  let today_left = today_undefined_condition ? rowData['usage']['today']['left'] : undefined;
  let today_used = today_undefined_condition ? rowData['usage']['today']['used'] : undefined;
  if (today_left !== undefined && today_used !== undefined) {
    const perc_left = (100 / (today_left + today_used)) * today_left;

    if (perc_left <= 10) {
      $(td).addClass('portal-danger');
    } else if (perc_left <= 25) {
      $(td).addClass('portal-warning');
    }
  }
}

function createdCellEdit(td, _cellData, rowData, row) {
  if (rowData['status'] !== 'deleted') {
    $(td).append(
      $('<div class="table-action-button-group">').append(
        $('<button type="button" class="btn btn-custom btn-xs">')
          .on('click', () => editApiKey(row))
          .append('<span class="fas fa-pencil-alt">'),
      ),
    );
  }
}

/**
 * rowCallback for Table with API Keys
 */
function rowCallbackForDeleted(row, data) {
  if (data['status'] === 'deleted') {
    $(row).addClass('row-deleted');
  }
}

/**
 * Reload the Table with API Keys
 */
function reloadApiKeyTable(result) {
  //reset the array of the displayed rows
  detailRowsApiKeys = [];

  const dt = $('#apiKeysTable').DataTable().clear().draw();
  const json = result.keys;
  dt.rows.add(json).draw();

  if (dt.buttons('api-key-create-button:name')) {
    dt.buttons('api-key-create-button:name').enable();
  }
}

// FORMAT CHILD ROWS OF DATATABLES
function formatChildRowApiKey(row, adminView) {
  const data = row.data();
  data.usage_plan_quota_limit = data['usage_plan_quota']['limit'];

  // DETAIL ROW

  const nav = $('<ul class="nav nav-tabs space">');
  const navContent = $('<div class="tab-content detailsTab">');

  const items = data.target_api === 'portal' ? apiKeyDetailsNavigation : [{ Name: 'API Key', Value: 'api_key' }];

  items.forEach(function (item) {
    const active = item['Value'] === 'api_key';
    nav.append(
      $('<li class="nav-item">')
        .toggleClass('active', active)
        .append(
          $('<a class="nav-link" role="tab" data-bs-toggle="tab">')
            .toggleClass('active', active)
            .attr({
              'data-bs-target': '#' + item['Value'] + data.api_key_id,
              'aria-selected': active,
              'aria-expanded': active,
            })
            .text(item['Name']),
        ),
    );

    const navItemBody = $('<div class="tab-pane fade" role="tabpanel">')
      .toggleClass('active show', active)
      .attr('id', item['Value'] + data.api_key_id)
      .appendTo(navContent);

    switch (item['Value']) {
      case 'api_key':
        // DETAIL ROW
        $('<div class="row">')
          .append(...renderApiKeyDetails(data, row, adminView))
          .appendTo(navItemBody);
        break;
      case 'permissions':
        // DETAIL ROW
        $('<div class="row">').append(renderApiKeyPermissionsDetails(data, row)).appendTo(navItemBody);
        break;
    }
  });

  return $('<div class="detailsContainer">').append(nav, navContent);
}

function renderApiKeyDetails(data, row, adminView) {
  return [
    // DETAIL ROW - LEFT
    $('<div class="col-lg-6 col-md-12 detailsContainerLeft">').append(
      // LEFT - API KEY DETAILS
      renderChildRowApiKeyDetails(data, adminView),

      // LEFT - BUTTONS
      !adminView && data.status !== 'deleted' && renderChildRowApiKeyButtons(data, row),
    ),

    // DETAIL ROW - RIGHT
    $('<div class="col-lg-6 col-md-12 detailsContainerRight">').append(
      // USAGE DETAILS
      Object.keys(data.usage).length > 0 && renderChildRowUsageDetails(data),
    ),
  ];
}

function renderChildRowApiKeyDetails(data, adminView) {
  const fields = [...(adminView ? apiKeyAdminInfoFields : apiKeyInfoFields), ...apiKeyUsagePlanInfoFields];
  return $('<div class="detailsContent">').append(
    '<h4>Api Key Details</h4>',
    $('<div>').append(renderInfoTable(data, fields)),
  );
}

function renderChildRowUsageDetails(data) {
  return $('<div class="detailsContent">').append(
    '<h4>Usage Details</h4>',
    $('<p>').html(
      'Daily usage for the last 7 days, the quota for used calls is reset at 00:00 UTC.' +
        '<br><small>Usage data may be delayed. If only <strong>today</strong> is present no usage in the last 7 days.</small>',
    ),
    createUsageTable(data.usage),
  );
}

function createUsageTable(usage) {
  const days = Object.keys(usage).sort().reverse();
  return $('<table class="table table-hover dataTable row-border nowrap compressed">').append(
    $('<thead>').append(
      $('<tr>').append(
        $('<th class="compressed">').text('Day'),
        $('<th class="compressed">').text('Used'),
        $('<th class="compressed">').text('Left'),
      ),
    ),
    $('<tbody>').append(
      days.map(day =>
        $('<tr role="row" class="compressed">').append(
          $('<td class="value">').text(day),
          $('<td class="value">').text(usage[day].used),
          $('<td class="value">').text(usage[day].left),
        ),
      ),
    ),
  );
}

function renderChildRowApiKeyButtons(_data, row) {
  return $('<div class="detailsContent">').append(
    $('<button type="button" class="btn btn-sm space">')
      .on('click', () => editApiKey(row[0][0]))
      .html('<span class="fas fa-pencil-alt"></span> Edit Api Key'),
  );
}

function renderApiKeyPermissionsDetails(data, row) {
  // DETAIL ROW - LEFT
  return $('<div class="col-sm-6 detailsContainerLeft">').append(...renderChildRowApiKeyPermissions(data, row));
}

function renderChildRowApiKeyPermissions(data, row) {
  return [
    // LEFT - API KEY DETAILS
    renderChildRowApiKeyPermissionsDetails(data),

    // LEFT - BUTTONS
    data.status !== 'deleted' &&
      $('<div class="detailsContent">').append(
        $('<button type="button" class="btn-sm space">')
          .on('click', () => editApiKeyPermissions(row[0][0]))
          .append('<span class="fas fa-pencil-alt"></span> Edit Permissions'),
      ),
  ];
}

function formatAndOrderPermissions(key_permissions) {
  let defaultPermissions = JSON.parse(localStorage.apiKeyPermissionsMapping);
  if (JSON.stringify(key_permissions) === JSON.stringify({ '*': { '*': true } })) {
    key_permissions = {};
    for (let [resource, methods] of Object.entries(defaultPermissions)) {
      key_permissions[resource] = {};
      Object.keys(methods).forEach(method => (key_permissions[resource][method] = true));
    }
  } else {
    for (let [resource, methods] of Object.entries(defaultPermissions)) {
      if (resource in key_permissions) {
        if (JSON.stringify(key_permissions[resource]) === JSON.stringify({ '*': false }))
          key_permissions[resource] = methods;
        else if (JSON.stringify(key_permissions[resource]) === JSON.stringify({ '*': true }))
          Object.keys(methods).forEach(method => (key_permissions[resource][method] = true));
        else key_permissions[resource] = $.extend({}, methods, key_permissions[resource]);
      } else {
        //in this way the newly added endpoint do not have permission over already edited API Keys
        key_permissions[resource] = methods;
      }
    }
  }
  let ordered_permissions = {};
  Object.keys(key_permissions)
    .sort()
    .forEach(resource => {
      ordered_permissions[resource] = key_permissions[resource];
    });

  return ordered_permissions;
}

function renderChildRowApiKeyPermissionsDetails(data) {
  data.permissions = formatAndOrderPermissions(data.permissions);

  return $('<div class="detailsContent">').append(
    '<h4>Permissions Details</h4>',
    $('<div>'),
    $('<table class="table table-hover dataTable row-border compressed">').append(
      $('<thead>').append(
        $('<tr>').append(
          '<th class="table table-hover dataTable row-border nowrap compressed">Resources</th>',
          '<th class="table table-hover dataTable row-border nowrap compressed">Methods</th>',
          '<th class="table table-hover dataTable row-border nowrap compressed">Allowed</th>',
        ),
      ),
      $('<tbody>').append(
        Object.keys(data.permissions).flatMap(resource => {
          const permissionsForResource = data.permissions[resource];
          return Object.entries(permissionsForResource).map(([method, _allowed], index) =>
            $('<tr role="row" class="compressed">').append(
              index === 0 &&
                $('<td class="name">').attr('rowspan', Object.keys(permissionsForResource).length).text(resource),
              $('<td class="value">').text(method),
              $('<td class="value">').append(
                $('<span class="fas" aria-hidden="true">').addClass(
                  data.permissions[resource][method] ? 'fa-check' : 'fa-times',
                ),
              ),
            ),
          );
        }),
      ),
    ),
  );
}

export function addApiKeySettings(result) {
  removeSpinners();

  const sel = document.getElementById('api-keys');
  for (let key in result.api_keys) {
    const opt = document.createElement('option');
    opt.value = JSON.stringify(result.api_keys[key]);
    opt.innerText = key;
    sel.appendChild(opt);
  }

  $('#api-keys').html(
    $('#api-keys option').sort(function (a, b) {
      return a.text < b.text ? -1 : 1;
    }),
  );

  $('.selectpicker').selectpicker('refresh');
}

export function updateByApiSelection() {
  const index = document.getElementById('api-keys').selectedIndex;
  const selectOption = document.getElementById('api-keys')[index].text;
  const selectValue = document.getElementById('api-keys').value;

  loadAllApiKeys(selectOption);
  updateApiKeyInformation(selectOption, selectValue);
  if ($('#edit-api-key-permissions-form').find('#edit-permissions-form').length !== 0) hideApiKeyPermissionsEditForm();
}

function updateApiKeyInformation(api, data) {
  const keyData = JSON.parse(data);
  $('#chosenApi')[0].innerText = api;
  $('#apiAccountId')[0].innerText = keyData['account_id'];
  $('#apiRegion')[0].innerText = keyData['region'];
  $('#apiUsagePlanId')[0].innerText = keyData['usage_plan_id'];
}

/**
 * Create a new API Key
 */
function createApiKey(key_usage) {
  if (key_usage === 'undefined' && document.getElementById('api-keys')) {
    const index = document.getElementById('api-keys').selectedIndex;
    key_usage = document.getElementById('api-keys')[index].text;
  }

  const payload = {
    action: 'create-api-key',
    description: 'Create new "' + key_usage + '" API key',
    target: key_usage,
  };

  if ($('#aws-account-id')[0]) {
    payload['account_id'] = $('#aws-account-id')[0].value.split(';')[0];
  }

  baseApiAxios
    .createOrder(payload)
    .then(result => {
      displaySuccess(`<strong>Success:</strong> ${result.message}`);
    })
    .catch(err => {
      displayError(`<strong>Warning:</strong> ${err.message}`);
    })
    .finally(() => {
      removeSpinners();
    });
}

/**
 * Extend key usage quota for given key
 */
function extendKeyUsageQuota(rowData) {
  const payload = {
    action: 'extend-api-key-usage',
    description: 'Extend key usage for "' + rowData.target_api + '" API key "' + rowData.api_key_id + '"',
    target: rowData.target_api,
    key_id: rowData.api_key_id,
  };

  if (rowData.target_api === 'itsm') payload['account_id'] = rowData.provider_id;

  baseApiAxios
    .createOrder(payload)
    .then(result => {
      displaySuccess(`<strong>Success:</strong> ${result.message}`);
    })
    .catch(err => {
      displayError(`<strong>Warning:</strong> ${err.message}`);
    })
    .finally(() => {
      removeSpinners();
    });
}

/**
 * Delete a given API Key
 */
function deleteApiKey(row, key_usage) {
  if (key_usage === 'undefined' && document.getElementById('api-keys')) {
    const index = document.getElementById('api-keys').selectedIndex;
    key_usage = document.getElementById('api-keys')[index].text;
  }

  const dt = $('#apiKeysTable').DataTable({ retrieve: true });
  const data = dt.row(row).data();

  const accountIdField = document.getElementById('aws-account-id');
  const payload = {
    action: 'delete-api-key',
    account_id: accountIdField?.value ? accountIdField.value.split(';')[0] : undefined,
    description: 'Delete "' + key_usage + '" API key "' + data.api_key_id + '"',
    target: key_usage,
    key_id: data.api_key_id,
  };

  swal({
    title: 'Are you sure?',
    text: 'Are you sure you want to delete this API key?',
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      reset: {
        text: 'Delete',
        value: true,
        className: 'swal-button swal-button--confirm swal-button--danger',
      },
    },
    dangeMode: true,
  }).then(function (response) {
    if (response) {
      baseApiAxios
        .createOrder(payload)
        .then(result => {
          displaySuccess(`<strong>Success:</strong> ${result.message}`);
        })
        .catch(err => {
          displayError(`<strong>Warning:</strong> ${err.message}`);
        })
        .finally(() => {
          removeSpinners();
        });
    }
  });
}

/**
 * Edit/Update a given API Key
 */
function editApiKey(row) {
  let api_key_data;
  if (typeof row === 'number') {
    let dt = $('#apiKeysTable').DataTable({
      retrieve: true,
    });
    api_key_data = dt.row(row).data();
  } else {
    api_key_data = row;
  }
  let edit_form_div = $('#edit-api-key-form');
  if (!edit_form_div.is(':visible')) edit_form_div.show();

  $('html, body').animate(
    {
      scrollTop: edit_form_div.offset().top,
    },
    500,
  );
  edit_form_div.empty().append(<ApiKeyEditForm />);
  fillEditApiKeyFields(api_key_data);
}

function fillEditApiKeyFields(api_key_data) {
  editApiKeyFields.forEach(function (item) {
    //in case we gonna add different input type
    if (api_key_data[item]) {
      $('#' + item)[0].value = api_key_data[item];
    } else {
      $('#' + item)[0].value = '';
    }
  });
}

export function hideApiKeyEditForm() {
  $('#edit-api-key-form').hide();
  const form = $('#edit-api-key-form').find('#edit-form');
  form[0].reset();
  removeApiKeyFormWarnings();
  window.location.search = '';
}

function removeApiKeyFormWarnings() {
  $('#form-error').hide();
  $('#form-success').hide();

  editApiKeyFields.forEach(function (item) {
    $('#form_group_' + item).removeClass('has-success');
    $('#form_group_' + item).removeClass('has-error');
  });
}

export function validateApiKeyEditForm() {
  //for future needs e.g. permissions
  updateApiKeyInDB();
}

function updateApiKeyInDB() {
  swal({
    title: 'Are you sure?',
    text: 'Are you sure you want to save this info into the database?',
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      okay: {
        text: 'Update',
        value: true,
        visible: true,
      },
    },
  }).then(function (response) {
    if (response) {
      //TODO - in addition we could check with the datatable values
      //if they try to update the api_key_id value or usage_plan_id
      const accountId = document.getElementById('provider_id')?.value;
      const targetApi = document.getElementById('target_api')?.value;
      const customName = document.getElementById('custom_name')?.value;
      const customDescription = document.getElementById('custom_description')?.value;
      const payload = {
        action: 'update-api-key-information',
        target: targetApi,
        account_id: accountId || undefined,
        api_key_information: {
          api_key_id: document.getElementById('api_key_id')?.value,
          usage_plan_id: document.getElementById('usage_plan_id')?.value,
          custom_name: customName || undefined,
          custom_description: customDescription || undefined,
        },
      };

      baseApiAxios
        .postApiKeyData(payload)
        .then(() => {
          if (targetApi === 'itsm') loadKeysForAccount();
          else if (targetApi === 'portal') loadKeysForUser();
          else {
            displayError('<strong>Error:</strong> Target not acceptable');
          }
        })
        .catch(err => {
          displayError(`<strong>Error:</strong> ${err.message}`);
        })
        .finally(() => {
          hideApiKeyEditForm();
          removeSpinners();
        });
    }
  });
}

function editApiKeyPermissions(row) {
  let api_key_data;
  if (typeof row === 'number') {
    let dt = $('#apiKeysTable').DataTable({
      retrieve: true,
    });
    api_key_data = dt.row(row).data();
  } else {
    api_key_data = row;
  }
  let edit_form_div = $('#edit-api-key-permissions-form');
  if (!edit_form_div.is(':visible')) edit_form_div.show();

  $('html, body').animate(
    {
      scrollTop: edit_form_div.offset().top,
    },
    500,
  );
  edit_form_div.empty().append(ApiKeyPermissionsEditForm());
  fillEditApiKeyPermissionsFields(api_key_data);
}

function fillEditApiKeyPermissionsFields(api_key_data) {
  api_key_data.permissions = formatAndOrderPermissions(api_key_data.permissions);

  $('#permissions-container').append(
    Object.entries(api_key_data.permissions).map(([key, value]) => {
      return $('<fieldset class="form-group row">')
        .attr('id', 'form_group_' + key)
        .append(
          $('<legend class="col-form-label pt-1">').text(key),
          $('<div class="col-auto d-inline-flex gap-3 align-items-center">').append(
            Object.entries(value).map(([method, allowed]) => {
              const inputId = key + '_' + method;
              return $('<div class="form-check">').append(
                $('<input type="checkbox" class="form-check-input">').attr({
                  id: inputId,
                  name: inputId,
                  resource: key,
                  method: method,
                  checked: allowed,
                }),
                $('<label class="form-check-label">').attr('for', inputId).text(method),
              );
            }),
          ),
        );
    }),
  );

  editApiKeyPermissionsFields.forEach(function (item) {
    //in case we gonna add different input type
    if (api_key_data[item]) {
      $('#' + item + '_permissions')[0].value = api_key_data[item];
    } else {
      $('#' + item + '_permissions')[0].value = '';
    }
  });
}

export function hideApiKeyPermissionsEditForm() {
  $('#edit-api-key-permissions-form').hide();
  const form = $('#edit-api-key-permissions-form').find('#edit-permissions-form');
  form[0].reset();
  removeApiKeyPermissionsFormWarnings();
  window.location.search = '';
}

function removeApiKeyPermissionsFormWarnings() {
  $('#form-error').hide();
  $('#form-success').hide();

  editApiKeyPermissionsFields.forEach(function (item) {
    $('#form_group_' + item).removeClass('has-success');
    $('#form_group_' + item).removeClass('has-error');
  });
}

export function validateApiKeyPermissionsEditForm() {
  //for future needs e.g. permissions
  updateApiKeyPermissionsInDB();
}

function updateApiKeyPermissionsInDB() {
  swal({
    title: 'Are you sure?',
    text: 'Are you sure you want to save the new set of permissions for this api key?',
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      okay: {
        text: 'Update',
        value: true,
        visible: true,
      },
    },
  }).then(function (response) {
    if (response) {
      let key_permissions = {};
      document.querySelectorAll('#permissions-container input[type=checkbox]').forEach(item => {
        if (item.getAttribute('resource') in key_permissions) {
          key_permissions[item.getAttribute('resource')][item.getAttribute('method')] = item.checked;
        } else {
          key_permissions[item.getAttribute('resource')] = { [item.getAttribute('method')]: item.checked };
        }
      });

      console.log(key_permissions);
      const payload = {
        action: 'update-api-key-permissions',
        target: document.getElementById('target_api_permissions')?.value,
        api_key_information: {
          api_key_id: document.getElementById('api_key_id_permissions')?.value,
          usage_plan_id: document.getElementById('usage_plan_id_permissions')?.value,
          permissions: key_permissions,
        },
      };

      baseApiAxios
        .postApiKeyData(payload)
        .then(() => {
          if ($('#api-keys').length !== 0) {
            loadAllApiKeys('portal');
            updateApiKeyInformation('portal', $('#api-keys').val());
          } else {
            loadKeysForUser();
          }
        })
        .catch(err => {
          displayError(`<strong>Error:</strong> ${err.message}`);
        })
        .finally(() => {
          hideApiKeyPermissionsEditForm();
          removeSpinners();
        });
    }
  });
}

function loadAllApiKeys(target) {
  const payload = {
    action: 'list-all-api-keys',
    target: target,
  };
  addSpinner();
  baseApiAxios
    .postApiKeyData(payload)
    .then(reloadApiKeyTable)
    .catch(err => displayError(`<strong>Error:</strong> ${err.message}`))
    .finally(() => removeSpinners());
}

function loadKeysForUser() {
  const payload = {
    action: 'list-api-keys',
    target: 'portal',
  };
  addSpinner();
  baseApiAxios
    .postApiKeyData(payload)
    .then(reloadApiKeyTable)
    .catch(err => displayError(`<strong>Error:</strong> ${err.message}`))
    .finally(() => removeSpinners());
}

export function loadKeysForAccount() {
  const payload = {
    account_id: document.getElementById('aws-account-id')?.value.split(';')[0],
    action: 'list-api-keys',
    target: 'itsm',
  };
  addSpinner();
  baseApiAxios
    .postApiKeyData(payload)
    .then(reloadApiKeyTable)
    .catch(err => displayError(`<strong>Error:</strong> ${err.message}`))
    .finally(() => removeSpinners());
}
