import { hasAccess, loadRegionsAndUpdateDropdown } from './js/portal/main';
import { centralFillVPCs, centralLoadVPCs, deleteVpcVerifyVpc } from './js/portal/networks';
import { addButtonSpinner, addSpinner } from './js/portal/sidebar';
import AccountDropdown from './jsx/components/forms/AccountDropdown';

export default function NetworksDestroyPage() {
  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form name="network-destroy-form" id="network-destroy-form" method="POST">
          <div class="card form-card">
            <div class="card-header">Destroy VPC</div>
            <div class="card-body">
              <div id="form-error" class="alert alert-danger" style="display: none">
                <ul />
              </div>

              <div id="form-success" class="alert alert-success" style="display: none">
                <ul />
              </div>

              <div id="infobox" class="card infobox">
                <div class="card-body">
                  With this form you can request to delete a VPC from your AWS account. Please be aware, that you need
                  to clean-up your VPC first. The deletion is only working, if the VPC does not contain any network
                  interfaces anymore (beside of Transit Gateway Attachments for a Private VPC). Find more information in
                  our documentation in{' '}
                  <a
                    href="https://developer.bmwgroup.net/docs/public-cloud-platform-aws/1_beforeyoustart/platformdoc/network/"
                    target="_blank"
                    rel="noopener noreferrer">
                    Developer Portal
                    <i class="px-1 fas fa-external-link-alt" />
                  </a>
                  .
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  {/* NETWORK A */}

                  <AccountDropdown
                    id="aws-account-id"
                    onchange={(_eventTypeId, _eventCategory, _selectedOption, accountId) => {
                      document.getElementById('submitButton').disabled = !hasAccess(accountId, ['manage_networks']);
                      centralLoadVPCs('region', 'aws-account-id', centralFillVPCs, { listId: 'network-id' });
                    }}
                    short
                    lookup
                  />

                  <div class="form-group row">
                    <label for="region" class="col-form-label">
                      Region
                    </label>
                    <div class="col">
                      <select
                        required
                        class="form-control form-select selectpicker"
                        data-live-search="true"
                        data-show-subtext="true"
                        data-size="10"
                        id="region"
                        name="region"
                        onchange={() => {
                          centralLoadVPCs('region', 'aws-account-id', centralFillVPCs, { listId: 'network-id' });
                        }}
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="network-id" class="col-form-label">
                      Network ID
                    </label>
                    <div class="col">
                      <select
                        required
                        class="form-control form-select selectpicker"
                        data-live-search="true"
                        data-show-subtext="true"
                        data-size="10"
                        id="network-id"
                        name="network-id">
                        <option value="" disabled selected>
                          - Select a Network ID -
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer" align="right">
              <input type="hidden" name="action" id="action" value="createJob" />
              <input type="hidden" name="jobType" id="jobType" value="destroy-vpc" />
              <input type="hidden" name="jobTypeDescription" id="jobTypeDescription" value="Destroy VPC" />
              <button type="submit" class="btn btn-success" id="submitButton" value="Destroy VPC">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                Destroy VPC
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function onPageReady() {
    loadRegionsAndUpdateDropdown('public', ['region']);

    $('#network-destroy-form')
      .on('submit', function (e) {
        e.preventDefault();
        $('#form-error').hide();
        $('#form-success').hide();
      })
      .validate({
        errorContainer: '#form-error',
        errorLabelContainer: '#form-error ul',
        wrapper: 'li',
        submitHandler: function () {
          addSpinner();
          addButtonSpinner();

          let vpc = document.getElementById('network-id');
          let vpc_id = vpc.value;
          let vpc_name = vpc[vpc.selectedIndex].getAttribute('vpc_name');
          let vpc_cidr = vpc[vpc.selectedIndex].getAttribute('cidr');
          let interfaces = vpc[vpc.selectedIndex].getAttribute('interfaces');
          let interfaces_customer = vpc[vpc.selectedIndex].getAttribute('interfaces_customer');

          let vpc_data = {
            vpc_id: vpc_id,
            vpc_name: vpc_name,
            cidr: vpc_cidr,
            interfaces: interfaces,
            interfaces_customer: interfaces_customer,
            account_id: $('#aws-account-id')[0].value.split(';')[0],
            region: $('#region')[0].value,
          };

          deleteVpcVerifyVpc(vpc_data);
        },
      });
  }

  return [page, onPageReady];
}
