import { baseApiAxios } from './js/portal/api';
import { hasAccess, loadRegionsAndUpdateDropdown } from './js/portal/main';
import { loadHostedZones, notifyDhcpChange, resultAssociateHostedZoneFunction } from './js/portal/zones';
import { centralFillVPCs, centralLoadVPCs } from './js/portal/networks';
import AccountDropdown from './jsx/components/forms/AccountDropdown';
import { addButtonSpinner, addSpinner } from './js/portal/sidebar';

export default function HostedZonesAssociatePage() {
  let userAccessAccountA, userAccessAccountB;

  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form name="associate-route53-hosted-zone-form" id="associate-route53-hosted-zone-form" method="POST">
          <div class="card form-card">
            {/* CARD HEADER */}
            <div class="card-header">Associate Route 53 Private Hosted Zone</div>

            {/* CARD BODY */}
            <div class="card-body">
              <div id="form-error" class="alert alert-danger" style="display: none">
                <ul />
              </div>

              <div id="form-success" class="alert alert-success" style="display: none">
                <ul />
              </div>

              <div id="infobox" class="card infobox">
                <div class="card-body">
                  With this form you can request the association of your Route 53 Private Hosted Zone with your VPC.
                </div>
              </div>

              <AccountDropdown
                label="Hosted Zone AWS Account Id"
                id="aws-account-id"
                onchange={(_eventTypeId, _eventCategory, _selectedOption, accountId) => {
                  document.getElementById('submitButton').disabled =
                    !userAccessAccountB && !hasAccess(accountId, ['manage_networks']);
                  loadHostedZones('hosted-zone-id', 'aws-account-id', 'aws.cloud.bmw');
                }}
                short
                lookup
              />

              <div class="form-group row">
                <label for="hosted-zone-id" class="col-form-label">
                  Hosted Zone
                  <span class="loading-form-inline">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </span>
                </label>
                <div class="col">
                  <select
                    required
                    class="form-control form-select selectpicker"
                    data-live-search="true"
                    data-show-subtext="true"
                    data-size="10"
                    id="hosted-zone-id"
                    name="hosted-zone-id">
                    <option value="" disabled selected>
                      - Select a Hosted Zone -
                    </option>
                  </select>
                </div>
              </div>

              <AccountDropdown
                label="Network AWS Account Id"
                id="aws-account-id-b"
                onchange={(_eventTypeId, _eventCategory, _selectedOption, accountId) => {
                  document.getElementById('submitButton').disabled =
                    !userAccessAccountA && !hasAccess(accountId, ['manage_networks']);
                  centralLoadVPCs('region', 'aws-account-id-b', centralFillVPCs, { listId: 'network-id-a' });
                }}
                short
                lookup
              />

              <div class="form-group row">
                <label for="region" class="col-form-label">
                  Network Region
                </label>
                <div class="col">
                  <select
                    required
                    class="form-control form-select selectpicker"
                    data-live-search="true"
                    data-show-subtext="true"
                    data-size="10"
                    id="region"
                    name="region"
                    onchange={() => {
                      centralLoadVPCs('region', 'aws-account-id-b', centralFillVPCs, { listId: 'network-id-a' });
                    }}
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="network-id-a" class="col-form-label">
                  Network ID
                </label>
                <div class="col">
                  <select
                    required
                    class="form-control form-select selectpicker"
                    data-live-search="true"
                    data-show-subtext="true"
                    data-size="10"
                    id="network-id-a"
                    name="network-id-a">
                    <option value="" disabled selected>
                      - Select a Network ID -
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label" />
                <div class="col">
                  <label class="checkbox-inline">
                    <input
                      type="checkbox"
                      value="create-dhcp"
                      id="create-dhcp"
                      name="create-dhcp"
                      onchange={() => notifyDhcpChange()}
                    />
                    <strong>Create new DHCP Option Set for my VPC</strong>
                  </label>
                </div>
              </div>
            </div>

            {/* CARD FOOTER */}
            <div class="card-footer">
              <input type="hidden" name="action" id="action" value="createJob" />
              <input type="hidden" name="jobType" id="jobType" value="associate-hostedzone" />
              <input
                type="hidden"
                name="jobTypeDescription"
                id="jobTypeDescription"
                value="Associate Route53 Private Hosted Zone to VPC"
              />
              <div class="row px-2">
                <div class="col">
                  <input
                    type="button"
                    name="resetFormBtn"
                    class="btn btn-secondary"
                    onclick={() => location.reload()}
                    value="Reset"
                  />
                </div>
                <div class="col" align="right">
                  <button
                    id="submitButton"
                    type="submit"
                    class="btn btn-success"
                    value="Associate existing Hosted Zone">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    Associate existing Hosted Zone
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function onPageReady() {
    loadRegionsAndUpdateDropdown('public', ['region']);

    $('#associate-route53-hosted-zone-form').on('submit', function (e) {
      e.preventDefault();
      $('#form-error').hide();
      $('#form-success').hide();
      addSpinner();
      addButtonSpinner();
      const zone_id = $('#hosted-zone-id')[0].value.split(',')[0].split('/')[2];
      const fqdn = $('#hosted-zone-id')[0].value.split(',')[1];

      const payload = {
        action: $('#jobType')[0].value,
        description: $('#jobTypeDescription')[0].value,
        account_id_route53: $('#aws-account-id')[0].value.split(';')[0],
        hosted_zone_id: zone_id,
        fqdn: fqdn,
        account_id_vpc: $('#aws-account-id-b')[0].value.split(';')[0],
        region: $('#region')[0].value,
        vpc_id: $('#network-id-a')[0].value,
        create_dhcp: $('#create-dhcp')[0].checked,
      };

      baseApiAxios
        .createOrder(payload)
        .then(resultAssociateHostedZoneFunction)
        .catch(err => {
          $('#form-error').html('<strong>Warning: </strong>' + err.message + ' ');
          $('#form-error').show();
        });
    });
  }

  return [page, onPageReady];
}
