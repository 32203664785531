export default function FqdnManagementPage() {
  const page = (
    <>
      <link rel="stylesheet" href="/micro_services/aws-customer-portal-fqdn-management/static/css/main.css" />
      <script defer="defer" src="/micro_services/aws-customer-portal-fqdn-management/static/js/main.js"></script>
      <div id="aws-customer-portal-fqdn-management"></div>
    </>
  );

  function onPageReady() {}

  return [page, onPageReady];
}
