import swal from 'sweetalert';
import { baseApiAxios } from './js/portal/api';
import { displayError, displaySuccess, showTab } from './js/portal/main';
import { addButtonSpinner, addSpinner, removeSpinners } from './js/portal/sidebar';

export default function UsersADUpdatePage() {
  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form name="ad-user-update-form" id="ad-user-update-form" method="POST">
          <div class="card form-card">
            <div class="card-header">Search and Update AD User</div>
            <div class="card-body">
              <div id="form-error" class="alert alert-danger" style="display: none">
                <ul />
              </div>
              <div id="form-success" class="alert alert-success" style="display: none">
                <ul />
              </div>
              <div id="infobox" class="card infobox">
                <div class="card-body">
                  <p>
                    With this form you can trigger the update of the IAM role trust policies, after the primary email of
                    an Active Directory user has changed (for example after a marriage). This function will lookup your
                    old primary email address and your new one based on your Q-account. If the email address has
                    changed, we will replace the old with the new one in all IAM roles that are known to us.
                  </p>
                  <p>If you want to update more than one user, please raise multiple orders.</p>
                </div>
              </div>

              <div id="tabs" class="tab-content">
                <div id="search" class="tab">
                  <div class="form-group row">
                    <label for="q-user" class="col-form-label">
                      Q-User
                    </label>
                    <div class="col">
                      <input type="text" class="form-control" id="q-user" name="q-user" placeholder="Q123456" />
                    </div>
                  </div>
                </div>

                <div id="update" class="tab">
                  {/* selectpicker options: https://developer.snapappointments.com/bootstrap-select/options/ */}
                  <div class="form-group row">
                    <label for="user-email" class="col-form-label">
                      AD User
                    </label>
                    <div class="col">
                      <select
                        required
                        class="form-control form-select selectpicker"
                        data-live-search="true"
                        data-show-subtext="true"
                        id="user-email"
                        name="user-email"
                        data-size="10"
                        data-none-selected-text="- Select one AD User -">
                        <option value="" disabled>
                          - Select an AD User -
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <input type="hidden" name="action" id="action" value="searchAdUser" />
              <input type="hidden" name="jobType" id="jobType" value="search-ad-user" />
              <input type="hidden" name="jobTypeDescription" id="jobTypeDescription" value="Search AD User" />
              <div class="row px-2">
                <div class="col">
                  <input
                    type="button"
                    name="resetFormBtn"
                    class="btn btn-secondary"
                    onclick={() => resetFormUpdateAdUser()}
                    value="Reset"
                  />
                </div>
                <div class="col" align="right">
                  <button type="submit" class="btn btn-success" id="submitButton" value="Search">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function resetFormUpdateAdUser() {
    removeSpinners();

    const form = document.getElementById('ad-user-update-form');
    form.reset();
    $('.selectpicker').selectpicker('refresh');
    $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
    $('#form-success').hide();
    $('#form-error').hide();
    $('#submitButton')[0].value = 'Search';
    showTab(0);

    $('#user-email').find('option').remove();
    $('#user-email').find('li').remove();
  }

  function updateFormUpdateAdUser(result) {
    const select = document.getElementById('user-email');
    $(select).find('optgroup').remove();
    $(select).find('option').remove();
    $(select).find('li').remove();

    if (result.users && result.users.length > 0) {
      for (const user of result.users) {
        let opt = document.createElement('option');
        let mainText = user.email;
        let subText = user.samaccountname + ', ' + user.department;
        opt.value = JSON.stringify(user);
        opt.innerText = mainText;
        opt.setAttribute('data-subtext', subText);
        select.appendChild(opt);
      }
    }
  }

  function onPageReady() {
    const currentTab = 0;
    showTab(currentTab);

    const submit_button = document.getElementById('submitButton');
    const form = document.getElementById('ad-user-update-form');

    $('#ad-user-update-form')
      .on('submit', function (e) {
        e.preventDefault();
      })
      .validate({
        rules: {
          email: {
            email: true,
          },
        },
        submitHandler: function () {
          addSpinner();
          addButtonSpinner();

          if (submit_button.value === 'Search') {
            const q_user = $('#q-user')[0].value;

            if (!q_user) {
              swal({
                title: 'Attention',
                text: 'Please enter a valid Q-User',
                icon: 'error',
              });
              return;
            }

            const payload = {
              action: 'search-ad-user',
              description: 'Search an Active Directory User',
              search_params: {
                q_user: $('#q-user')[0].value,
              },
            };
            baseApiAxios
              .searchADUser(payload)
              .then(result => {
                updateFormUpdateAdUser(result);
                showTab(1);
                submit_button.value = 'Update';
                submit_button.innerHTML =
                  '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Update';
              })
              .catch(err => {
                displayError(`<strong>Warning: </strong>${err.message}`);
              })
              .finally(() => {
                form.reset();
                $('.selectpicker').selectpicker('refresh');
                removeSpinners();
              });
          } else if (submit_button.value === 'Update') {
            const user_information = JSON.parse($('#user-email').val());
            const payload = {
              action: 'update-ad-user',
              description: 'Update IAM roles for Q-account "' + user_information.samaccountname + '"',
              user_information: user_information,
            };

            baseApiAxios
              .createOrder(payload)
              .then(result => {
                displaySuccess(`<strong>${result.message}</strong>`);
                showTab(0);
                submit_button.value = 'Search';
                submit_button.innerHTML =
                  '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Search';
              })
              .catch(err => {
                displayError(`<strong>Warning: </strong>${err.message}`);
              })
              .finally(() => {
                form.reset();
                $('.selectpicker').selectpicker('refresh');
                $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
                removeSpinners();
              });
          }
        },
      });
  }

  return [page, onPageReady];
}
