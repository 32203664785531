import { loadRegionsAndUpdateDropdown } from './js/portal/main';
import {
  deleteVpcFromDBForm,
  getFreeNetbondCIDR,
  getFreeNetworkCIDR,
  hideNetworkEditForm,
  initNetworkTable,
  showCompareVPCModal,
  validateNetworkForm,
} from './js/portal/networks';
import { Datatable } from './Datatable';

const tableId = 'table-vpcs';

export default function NetworksListPage() {
  const page = (
    <>
      <div class="row" id="edit-vpc-form" style="display: none">
        <div class="col-lg-12">
          <form name="policy-create-form" id="policy-create-form" method="POST">
            <div class="card form-card">
              <div class="card-header">Edit VPC</div>
              <div class="card-body">
                <div id="form-error" class="alert alert-danger" style="display: none">
                  <ul />
                </div>
                <div id="form-success" class="alert alert-success" style="display: none">
                  <ul />
                </div>

                <div id="id" style="display: none" />
                <div id="uuid" style="display: none" />
                <div id="item_type" style="display: none" />

                {/* AWS ACCOUNT ID */}
                <div class="form-group row" id="form_group_aws-account-id">
                  <label for="aws-account-id" class="col-form-label">
                    AWS Account ID
                  </label>
                  <div class="col">
                    <input type="text" class="form-control" id="aws-account-id" placeholder="123456789012" disabled />
                  </div>
                </div>

                {/* REGION */}
                <div class="form-group row" id="form_group_aws-region">
                  <label for="aws-region" class="col-form-label">
                    Region
                  </label>
                  <div class="col">
                    <select
                      required
                      class="form-control form-select selectpicker"
                      data-live-search="true"
                      data-show-subtext="true"
                      data-size="10"
                      id="aws-region"
                      name="aws-region"
                    />
                  </div>
                </div>

                {/* NETWORK TYPE */}
                <div class="form-group row" id="form_group_network-type">
                  <label for="network-type" class="col-form-label">
                    Network Type
                  </label>
                  <div class="col">
                    <select required class="form-select" id="network-type" name="network-type">
                      <option value="private" selected>
                        Private
                      </option>
                      <option value="public">Public</option>
                      <option value="special">Special</option>
                    </select>
                  </div>
                </div>

                {/* VPC ID */}
                <div class="form-group row" id="form_group_vpc-id">
                  <label for="vpc-id" class="col-form-label">
                    VPC ID
                  </label>
                  <div class="col">
                    <input type="text" class="form-control" id="vpc-id" placeholder="vpc-abcdefg" disabled />
                  </div>
                </div>

                {/* NETWORK CIDR */}
                <div class="form-group row" id="form_group_network-cidr">
                  <label class="col-form-label">Network CIDR</label>
                  <div class="col">
                    <div class="input-group">
                      <input type="text" class="form-control" id="network-cidr" placeholder="10.19.0.0/24" disabled />
                      <select required class="btn btn-outline-secondary" id="network-size" name="network-size">
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26" selected>
                          26
                        </option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                      </select>
                      <button
                        class="btn btn-outline-secondary"
                        type="button"
                        id="network-get-free"
                        onclick={() => getFreeNetworkCIDR()}>
                        Get Free Network CIDR
                      </button>
                    </div>
                  </div>
                </div>

                {/* NETBOND CIDR */}
                <div class="form-group row" id="form_group_netbond-cidr">
                  <label class="col-form-label">NetBond CIDR</label>
                  <div class="col">
                    <div class="input-group">
                      <input type="text" class="form-control" id="netbond-cidr" placeholder="e.g. 10.204.244.192/29" />
                      <select required class="btn btn-outline-secondary" id="netbond-size" name="netbond-size">
                        <option value="29" selected>
                          29
                        </option>
                      </select>
                      <button
                        class="btn btn-outline-secondary"
                        type="button"
                        id="netbond-get-free"
                        onclick={() => getFreeNetbondCIDR()}>
                        Get Free Netbond CIDR
                      </button>
                    </div>
                  </div>
                </div>

                {/* COMMENT */}
                <div class="form-group row" id="form_group_edit-comment">
                  <label for="edit-comment" class="col-form-label">
                    Comment
                  </label>
                  <div class="col">
                    <input type="text" class="form-control" id="edit-comment" placeholder="comments" />
                  </div>
                </div>

                {/* CREATION DATE */}
                <div class="form-group row" id="form_group_creation-date">
                  <label for="creation-date" class="col-form-label">
                    Creation Date
                  </label>
                  <div class="col">
                    <input
                      type="date"
                      class="form-control"
                      id="creation-date"
                      name="creation-date"
                      placeholder="2020-04-25"
                    />
                  </div>
                </div>
              </div>

              <div class="card-footer" align="right">
                <input
                  type="button"
                  class="btn btn-custom"
                  name="hide-edit-form"
                  id="hide-edit-form"
                  value="Cancel"
                  onclick={() => hideNetworkEditForm()}
                />
                <input
                  type="button"
                  class="btn btn-warning"
                  name="delete-vpc"
                  id="delete-vpc"
                  value="Delete"
                  onclick={() => deleteVpcFromDBForm()}
                />
                <input
                  type="button"
                  class="btn btn-success"
                  name="submit-vpc"
                  id="submit-vpc"
                  value="Update"
                  onclick={() => validateNetworkForm()}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="row row-cols-auto justify-content-end gy-3 mb-4">
        <button class="dt-button" onclick={() => showCompareVPCModal(true)}>
          <i class="fas fa-highlighter" style="margin-right:4px;" />
          Compare VPCs
        </button>
        <div id="show-all-networks-div" class="col col-auto align-self-center d-none">
          <div class="form-check form-switch">
            <input class="form-check-input danger" type="checkbox" id="show-all-networks-switch" />
            <label class="form-check-label" for="show-all-networks-switch">
              Show all VPCs
            </label>
          </div>
        </div>
      </div>

      <Datatable tableId={tableId} />
    </>
  );

  function onPageReady() {
    loadRegionsAndUpdateDropdown('public', ['aws-region']);
    initNetworkTable(tableId);
  }

  return [page, onPageReady];
}
