import AccountDropdown from './jsx/components/forms/AccountDropdown';
import {
  addToast,
  formatBytes,
  loadRegionsAndUpdateDropdown,
  createDisplayErrorFunction,
  hideMessages,
  proceedToNextStep,
  updateFormState,
  jumpToFirstStep,
} from './js/portal/main';
import DatasetsPaginated from './jsx/components/forms/DatasetsPaginated';
import { baseApiAxios } from './js/portal/api';
import { addButtonSpinner, addSpinner, removeSpinners } from './js/portal/sidebar';
import { COGNITO } from './js/portal/auth';
import { getSIEMCostEstimate } from './js/portal/pricing_calculator';

export default function SiemOnboardingRequestPage() {
  let logGroups = [];
  let logGroupsSelected = {};
  let subscriptionFilters = {};
  let nextToken;

  const displayError = createDisplayErrorFunction('Creation of <b>siem-log-forwarding</b> order failed:', false);

  let renderDatasetsPaginated = () => {
    return (
      <DatasetsPaginated
        id="log-group-datasets"
        headers={[
          { title: 'Log Group Name', key: 'logGroupName' },
          { title: 'Daily Volume', key: 'dailyVolume' },
          { title: 'Stored Volume', key: 'humanizedStoredBytes' },
          { title: 'Retention', key: 'retentionInDays' },
          { title: 'Creation Time', key: 'creationTime' },
        ]}
        items={logGroups}
        selectionCallback={storeSelection}
        loadNextPage={getLogGroupPage}
        loadPreviousPage={getLogGroupPage}
        nextToken={nextToken}
        searchCallback={async (pageSize, searchPhrase) => {
          addSpinner();
          addButtonSpinner();
          const result = await getLogGroupPage(null, pageSize, searchPhrase);
          removeSpinners();
          return result;
        }}
        prefixSearch={true}
      />
    );
  };

  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form name="create-onboarding-request" id="create-onboarding-request" method="POST">
          <div class="card form-card">
            {/* CARD HEADER */}
            <div class="card-header">Create new Onboarding Request</div>

            {/* CARD BODY */}
            <div class="card-body">
              <div id="form-error" class="alert alert-danger mb-4" style="display: none">
                <ul />
              </div>

              <div id="form-success" class="alert alert-success mb-4" style="display: none">
                <ul />
              </div>

              <div class="process-steps-container">
                <div class="process-step process-step--fill current-step">
                  <p>Verify Request</p>
                </div>
                <div class="process-step">
                  <p>Log Groups</p>
                </div>
                <div class="process-step">
                  <p>Log Settings</p>
                </div>
              </div>

              <div class="form-steps-container mx-4">
                <div id="verify-request" class="form-step current-step">
                  <div class="row mb-3 px-3">
                    <div id="infobox" class="card infobox">
                      <div class="card-body">
                        <p>
                          <p>
                            Create an order for onboarding to the BMW SIEM systems. The order create automation for log
                            forwarding to SIEM's log monitoring and threat detection service of your applications.
                          </p>
                          <p>
                            Contact{' '}
                            <a href="mailto:siem@bmwgroup.com" target="_blank">
                              siem@bmwgroup.com
                            </a>{' '}
                            to initiate the onboarding. Requirements for successful order creation:{' '}
                            <b>Encrypted SIEM OLA handle</b>.
                          </p>
                          <p>
                            Please find more information in the{' '}
                            <a
                              href="https://developer.bmwgroup.net/docs/public-cloud-platform-aws/3_runyourapplication/selfservices/customer_self_service_portal/self_service/#siem"
                              target="_blank">
                              developer portal{' '}
                              <i
                                class="fas fa-external-link-alt"
                                style="margin-left: 0.5em; font-size: smaller; align-self: center; margin-top: -0.1em"
                              />
                            </a>
                          </p>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="mx-5">
                    {/* SIEM KEY */}
                    <div class="d-flex form-group">
                      <div class="flex-grow-1 row">
                        <label for="key" class="col-form-label">
                          Encrypted SIEM OLA handle
                        </label>
                        <div class="col">
                          <input
                            id="siem_key"
                            type="text"
                            class="form-control"
                            placeholder="e.g. ASDF23485A2342KDSD$##143"
                            required={true}
                          />
                          <small className="form-text text-muted">
                            Request a SIEM OLA Handle from the SIEM Team, afterwards use encrypted value for
                            verification step.
                          </small>
                        </div>
                      </div>
                    </div>

                    {/* AWS ACCOUNT ID */}
                    <div class="d-flex form-group">
                      <div class="flex-grow-1 row w-100">
                        <label for="account_id" class="col-form-label">
                          AWS Account
                        </label>
                        <div class="col">
                          <AccountDropdown id="account_id" label="none" short required={true} />
                        </div>
                      </div>
                    </div>

                    {/* REGION */}
                    <div class="d-flex form-group">
                      <div class="flex-grow-1 row">
                        <label for="region" class="col-form-label">
                          Region
                        </label>
                        <div class="col">
                          <select
                            class="form-control form-select selectpicker"
                            data-live-search="true"
                            data-show-subtext="true"
                            data-size="10"
                            id="region"
                            name="region"></select>
                        </div>
                      </div>
                    </div>

                    {/* SIEM LOG GROUP */}
                    <div class="d-none">
                      <div class="flex-grow-1 row">
                        <label for="log_source" class="col-form-label">
                          Log Source
                        </label>
                        <div class="col">
                          <select
                            class="form-control form-select selectpicker"
                            data-live-search="true"
                            data-show-subtext="true"
                            data-size="10"
                            id="log_source"
                            name="log_source"
                            disabled={true}>
                            <option selected={true} data-subtext="CloudWatch Logs">
                              CloudWatch Logs
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="log-groups" class="form-step">
                  <div class="row mb-3 px-3">
                    <div id="infobox" class="card infobox">
                      <div class="card-body">
                        <p>Select a log group to consume for SIEM.</p>
                        <p>
                          Hint: Only log groups with less than 2 Subscription Filters can be used for SIEM onboarding.
                          You can also proceed without a log group selection. In this case you have to produce data
                          directly on the deployed Kinesis Data Stream which is deployed via this order. Already
                          onboarded log groups are not automatically preselected.
                        </p>
                        <p>
                          Please find more information in the{' '}
                          <a
                            href="https://developer.bmwgroup.net/docs/public-cloud-platform-aws/3_runyourapplication/selfservices/customer_self_service_portal/self_service/#siem"
                            target="_blank">
                            developer portal{' '}
                            <i
                              class="fas fa-external-link-alt"
                              style="margin-left: 0.5em; font-size: smaller; align-self: center; margin-top: -0.1em"
                            />
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div id="datasets-paginated-container" class="row px-5"></div>
                </div>

                <div id="log-settings" class="form-step">
                  <div class="mx-5 pb-4">
                    <div class="form-step-separator" data-label="Kinesis Data Stream"></div>

                    {/* LOG VOLUME */}
                    <div class="d-flex form-group">
                      <div class="flex-grow-1 row" id="log_volume_div">
                        <label for="log_volume" class="col-form-label text-truncate">
                          Log Volume
                        </label>
                        <div class="col">
                          <input type="text" id="log_volume" class="form-control" value="" />
                        </div>
                      </div>
                    </div>

                    {/* SHARDS */}
                    <div class="d-flex form-group">
                      <div class="flex-grow-1 row">
                        <label for="shards" class="col-form-label text-truncate">
                          Shards
                        </label>
                        <div class="col">
                          <select
                            id="shards"
                            name="shards"
                            class="form-control form-select selectpicker"
                            data-size="10"
                            data-live-search="true"
                            data-show-subtext="true">
                            <option key="0" value={0} selected>
                              auto-scaling
                            </option>
                            <option key="1" value={1}>
                              1
                            </option>
                            <option key="2" value={2}>
                              2
                            </option>
                            <option key="3" value={3}>
                              3
                            </option>
                            <option key="4" value={4}>
                              4
                            </option>
                            <option key="5" value={5}>
                              5
                            </option>
                            <option key="6" value={6}>
                              6
                            </option>
                            <option key="7" value={7}>
                              7
                            </option>
                            <option key="8" value={8}>
                              8
                            </option>
                            <option key="9" value={9}>
                              9
                            </option>
                            <option key="10" value={10}>
                              10
                            </option>
                          </select>
                          <small className="form-text text-muted">
                            The amount of shards for Kinesis Data Streams (between 1 and 10). Set to 0 on AWS to use on
                            auto-scaling setup. Set to 1 for the cheapest solution, recommended for dev environments.
                            Limited to 10 for avoiding unexpected costs. See{' '}
                            <a
                              href="https://aws.amazon.com/kinesis/data-streams/pricing/"
                              target="_blank"
                              rel="noopener noreferrer">
                              Amazon Kinesis Data Streams pricing{' '}
                              <i
                                class="fas fa-external-link-alt"
                                style="margin-left: 0.5em; font-size: smaller; align-self: center; margin-top: -0.1em"
                              />
                            </a>{' '}
                            or{' '}
                            <a
                              href="https://calculator.aws/#/addService/KinesisDataStreams"
                              target="_blank"
                              rel="noopener noreferrer">
                              AWS Calculator{' '}
                              <i
                                class="fas fa-external-link-alt"
                                style="margin-left: 0.5em; font-size: smaller; align-self: center; margin-top: -0.1em"
                              />
                            </a>
                          </small>
                        </div>
                      </div>
                    </div>

                    {/* KINESIS RETENTION */}
                    <div class="d-flex form-group">
                      <div class="flex-grow-1 row">
                        <label for="retention" class="col-form-label text-truncate">
                          Retention
                        </label>
                        <div class="col">
                          <div>
                            <select
                              id="retention"
                              name="retention"
                              class="form-control form-select selectpicker"
                              data-size="10"
                              data-live-search="true"
                              data-show-subtext="true">
                              <option key="1" value={1} selected>
                                1 day
                              </option>
                              <option key="2" value={2}>
                                2 days
                              </option>
                              <option key="3" value={3}>
                                3 days
                              </option>
                              <option key="4" value={4}>
                                4 days
                              </option>
                              <option key="5" value={5}>
                                5 days
                              </option>
                              <option key="6" value={6}>
                                6 days
                              </option>
                              <option key="7" value={7}>
                                7 days
                              </option>
                            </select>
                          </div>
                          <small className="form-text text-muted">
                            Kinesis Data Streams data retention period in days.
                          </small>
                        </div>
                      </div>
                    </div>

                    {/* ENCRYPTION + CREATE SUBSCRIPTION FILTERS */}
                    <div class="d-flex form-group">
                      <div class="flex-grow-1 row">
                        <label class="col-form-label text-truncate"></label>
                        <div class="col" id="encryption_div">
                          <div class="form-label-text text-start mx-2">
                            <input type="checkbox" id="encryption" name="encryption" checked />
                            <label for="encryption" class="px-3">
                              Encryption
                            </label>
                          </div>
                          <small className="form-text text-muted">
                            Encryption option MUST be turned on in production environments
                          </small>
                        </div>
                        <div class="d-none">
                          <div class="form-label-text text-start mx-2">
                            <input type="checkbox" id="subscription_filters" name="subscription_filters" />
                            <label for="subscription_filters" class="px-3">
                              Create Subscription Filters
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* COST ESTIMATE */}
                    <div class="d-flex form-group">
                      <div class="flex-grow-1 row" id="log_volume_div">
                        <label for="cost_estimate" class="col-form-label text-truncate">
                          Cost Estimate
                        </label>
                        <div class="col">
                          <input
                            type="text"
                            id="cost_estimate"
                            class="form-control"
                            value="~ 33.85 USD (monthly costs in your account)"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mx-5">
                    <div class="form-step-separator mt-5" data-label="SIEM"></div>

                    {/* SIEM RETENTION */}
                    <div class="d-flex form-group">
                      <div class="flex-grow-1 row">
                        <label for="siem_retention" class="col-form-label text-truncate">
                          SIEM Retention
                        </label>
                        <div class="col">
                          <div class="d-flex">
                            <input
                              type="number"
                              value="1"
                              min="1"
                              max="1000"
                              id="siem_retention"
                              name="siem_retention"
                              class="form-control flex-grow-1"
                              onchange={evt => {
                                evt.target.value = Math.max(1, Math.min(parseInt(evt.target.value), 1000));
                              }}></input>
                            <div class="form-inline-label-text">days</div>
                          </div>
                          <small className="form-text text-muted">
                            SIEM data retention period for logs in days (must be between 1 and 1000).
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* CARD FOOTER */}
            <div class="card-footer">
              <div class="row px-2">
                <div class="col">
                  <input type="button" id="backButton" class="btn btn-secondary d-none" value="Back" />
                </div>
                <div class="col" align="right">
                  <button id="submitButton" type="button" class="btn btn-success">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    <span id="submitButtonLabel">Proceed to next step</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function onPageReady() {
    $('.selectpicker').selectpicker('refresh');
    const costEstimateField = document.getElementById('cost_estimate');

    let state = {};
    loadRegionsAndUpdateDropdown('public', ['region']);
    const callbackFunctions = {
      backButton: document.getElementById('backButton'),
      error: displayError,
      'verify-request': async () => {
        const siemKeyField = document.getElementById('siem_key');
        const accountField = document.getElementById('account_id');
        const fieldNames = { siem_key: 'Encrypted SIEM OLA handle', account_id: 'AWS Account' };
        if (!siemKeyField?.value || !accountField?.value)
          throw `The field(s) ${[siemKeyField, accountField]
            .filter(field => !field.value)
            .map(field => fieldNames[field.id])
            .join(', ')} can't be empty`;
        try {
          await baseApiAxios.verifySIEMKey(siemKeyField?.value, accountField?.value);
        } catch (err) {
          throw `Invalid SIEM Key: ${siemKeyField?.value} - ${err.message}`;
        }
        hideMessages();
        state = updateFormState(state, ['siem_key', 'account_id', 'region', 'log_source']);
        await getLogGroupPage(null, 10, null);
        const datasetsContainer = document.getElementById('datasets-paginated-container');
        $(datasetsContainer).html(renderDatasetsPaginated());
        document.getElementById('submitButtonLabel').textContent = 'Proceed to next step';
        logGroupsSelected = {};
      },
      'log-groups': async () => {
        hideMessages();
        const logVolumeField = document.getElementById('log_volume');
        if (Object.keys(logGroupsSelected).length > 0 && logVolumeField) {
          state['log_volume'] = getApproximateVolume();
          document.getElementById('log_volume_div').style.display = '';
          document.getElementById('encryption').checked = true;
          logVolumeField.value = '~ ' + formatBytes(state['log_volume']);
          logVolumeField.disabled = true;
        } else {
          state['log_volume'] = 0;
          document.getElementById('log_volume_div').style.display = 'none';
        }
        state['log_groups'] = Object.keys(logGroupsSelected);
        state['log_arns'] = Object.values(logGroupsSelected).map(group => group.arn);
        const subscriptionFiltersAvailable = await checkFreeSubscriptionFilters();
        if (!subscriptionFiltersAvailable) {
          throw `For each selected log group at least one subscription filter is required (max. 2 per group) - ${Object.entries(
            subscriptionFilters
          )
            .map(([key, obj]) => key + ' (' + obj['subscription_filters'].length + ')')
            .join(', ')}`;
        }

        document.getElementById('encryption').dispatchEvent(new Event('change')); // trigger cost calculation
        document.getElementById('submitButtonLabel').textContent = 'Create order';
      },
      'log-settings': async () => {
        hideMessages();
        state = updateFormState(state, ['shards', 'encryption', 'retention', 'subscription_filters', 'siem_retention']);
        let payload = Object.assign(state, {
          action: 'siem-log-forwarding',
          description: `SIEM Onboarding request for account ${state['account_id']}`,
          email: COGNITO.user.email,
          retention: parseInt($('#retention').val()),
          shards: parseInt($('#shards').val()),
          siem_retention: parseInt($('#siem_retention').val()),
        });
        try {
          const response = await baseApiAxios.createOrder(payload);
          const formSuccess = document.getElementById('form-success');
          const formSuccessList = formSuccess.querySelector('ul');
          const listElement = document.createElement('li');
          listElement.innerHTML = 'Order <b>siem-log-forwarding</b> successfully created';
          formSuccessList.appendChild(listElement);
          formSuccess.style.display = '';
          addToast('Create Order', response.message, 6000);
          logGroupsSelected = {};
          jumpToFirstStep();
          resetForm();
          document.getElementById('submitButtonLabel').textContent = 'Proceed to next step';
          state = {};
        } catch (err) {
          displayError(err);
        }
      },
    };

    const encryptionField = document.getElementById('encryption');

    const updateCostEstimateField = async () => {
      const costEstimate = await getSIEMCostEstimate(
        state['region'],
        state['log_volume'] || 1,
        parseInt($('#shards').val()) || 0,
        parseInt($('#retention').val()) || 1,
        encryptionField.checked
      );
      costEstimateField.value = `~ ${costEstimate.toFixed(2)} USD (monthly costs in your account)`;
    };

    document.getElementById('shards')?.addEventListener('change', updateCostEstimateField);
    document.getElementById('retention')?.addEventListener('change', updateCostEstimateField);
    encryptionField?.addEventListener('change', updateCostEstimateField);

    document.getElementById('submitButton')?.addEventListener('click', () => {
      proceedToNextStep(callbackFunctions);
      $('.selectpicker').selectpicker('refresh');
    });

    document.getElementById('backButton')?.addEventListener('click', evt => {
      evt.target.disabled = true;
      proceedToNextStep(callbackFunctions, -1);
      $('.selectpicker').selectpicker('refresh');
    });
  }

  function resetForm() {
    document.getElementById('siem_key').value = '';
    document.getElementById('log_volume').value = '~ 0 Bytes';
    const $selectpicker = $('.selectpicker');
    $selectpicker.val(null);
    $selectpicker.selectpicker('refresh');
  }

  async function getLogGroupPage(pageToken, pageSize, searchPhrase) {
    addSpinner();
    addButtonSpinner();
    const newLogGroups = await baseApiAxios.getLogGroups(
      document.getElementById('account_id')?.value,
      document.getElementById('region')?.value,
      pageSize,
      pageToken,
      searchPhrase
    );
    logGroups = newLogGroups['log_groups'];
    logGroups = logGroups.map(logGroup => {
      return {
        ...logGroup,
        dailyVolume: `Max. Volume ${humanFileSize(logGroup['max_log_volume_per_day'])} (Avg. ${humanFileSize(
          logGroup['avg_log_volume_per_day']
        )})`,
      };
    });
    nextToken = newLogGroups['next_token'];
    removeSpinners();
    return [logGroups, newLogGroups['next_token']];
  }

  function storeSelection(selection) {
    logGroupsSelected = selection;
  }

  function getApproximateVolume() {
    return Object.values(logGroupsSelected)
      .map(group => group.storedBytes)
      .reduce((a, b) => a + b);
  }

  function humanFileSize(size) {
    const i = !size ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }

  async function checkFreeSubscriptionFilters() {
    const accountId = document.getElementById('account_id')?.value;
    const region = document.getElementById('region')?.value;
    const filterObjects = await Promise.all(
      Object.keys(logGroupsSelected).map(async key => {
        const filters = await baseApiAxios.getLogGroupSubscriptionFilters(accountId, key, region);
        return { [key]: filters };
      })
    );
    subscriptionFilters = Object.assign({}, ...filterObjects);
    return Object.values(subscriptionFilters).every(obj => obj['subscription_filters'].length < 2);
  }

  return [page, onPageReady];
}
