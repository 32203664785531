import { checkIfAnyInList } from './js/portal/main';
import { CONF } from './js/portal/environment';

import { showQuickActionsConfigModal } from './js/portal/dashboard';
import AccountDropdown from './jsx/components/forms/AccountDropdown';
import ClusterDropdown from './jsx/components/forms/ClusterDropdown';
import DXGWDropdown from './jsx/components/forms/DXGWDropdown';
import HostedZoneDropdown from './jsx/components/forms/HostedZoneDropdown';
import OrderDropdown from './jsx/components/forms/OrderDropdown';
import VPCDropdown from './jsx/components/forms/VPCDropdown';
import { default_quick_links } from './js/portal/navigation';
import { COGNITO } from './js/portal/auth';

export default async function DashboardPage() {
  const cognitoUser = COGNITO.userpool.getCurrentUser();
  const username = cognitoUser.getUsername();
  const savedQuickLinks = localStorage.getItem(`quick_links_${btoa(username)}`);
  const permissionsArray = localStorage.permissions.split(',');
  const vpcTileVisible = checkIfAnyInList(['view_networks'], permissionsArray);
  const orderTileVisible = checkIfAnyInList(['view_orders'], permissionsArray);
  const dxgwTileVisible = checkIfAnyInList(['manage_networks', 'view_all_networks'], permissionsArray);
  const hostedZoneTileVisible = checkIfAnyInList(['view_dns'], permissionsArray);
  const clusterTileVisible = checkIfAnyInList(['view_4wheels'], permissionsArray);

  const showAllSwitchVisible =
    localStorage.account_areas ||
    checkIfAnyInList(
      [
        'manage_accounts',
        'view_all_accounts',
        'manage_networks',
        'view_all_networks',
        'manage_orders',
        'manage_dns',
        'manage_4wheels',
      ],
      permissionsArray,
    );

  let dashboardShowAllResources = showAllSwitchVisible && sessionStorage.getItem('showAllResources') === 'true';

  // click on dropdown field refresh button
  const reloadDropdownOptions = (_event, selectField) => {
    const card_element = selectField.closest('.dashboard-card');
    if (card_element) card_element.classList.add('loading');
    const reload_event = new CustomEvent('reload-dropdown-options', {
      detail: {
        allResources: dashboardShowAllResources,
      },
    });
    selectField.dispatchEvent(reload_event);
  };

  const page = (
    <div id="dashboard_overview">
      <div class="dashboard-header">
        <div class="dashboard-title">
          <h3 class="overview">Dashboard</h3>
        </div>
        <span class="dashboard-controls">
          {showAllSwitchVisible && (
            <span style="display:inline-block;margin-left:10px;">
              <div id="show-all-resources" class="text-right">
                <div class="form-check form-switch">
                  <input class="form-check-input success" type="checkbox" id="show-all-resources-switch" />
                  <label class="form-check-label" for="show-all-resources-switch">
                    Show all resources
                  </label>
                </div>
              </div>
            </span>
          )}
        </span>
      </div>
      <div class="my-2">
        <div class="col-lg-12 col-xl-12 mb-4">
          <div class="card quick-links-card">
            <div id="quick-actions-container" class="card-body">
              <h5 class="card-title" style="margin-bottom:0">
                Quick Actions
              </h5>
              <div
                id="quick-actions-content"
                class={
                  'row px-3 collapse' +
                  (localStorage.getItem('customer_portal_aws_quick_actions_expanded') !== 'false' ? ' show' : '')
                }>
                <div id="quick-actions-static-links" class="row">
                  <strong class="dashboard-card-subtitle">Links</strong>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 dashboard-action">
                    <a
                      href={CONF.awsLoginDetails.webeamLoginUrl}
                      title="Login via WebEAM.Next Login (Public)"
                      class="overview btn">
                      <i class="fas fa-sign-in-alt" /> WebEAM.Next Login (Public)
                    </a>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 dashboard-action">
                    <a
                      href="https://atc.bmwgroup.net/bitbucket/projects/FPCBMW/repos/bmwaws-cli/browse"
                      title="Python based BMW AWS CLI (internal Repository)"
                      class="overview btn">
                      <i class="fas fa-terminal" /> BMW AWS CLI (Repository)
                    </a>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 dashboard-action">
                    <a
                      href="https://atc.bmwgroup.net/bitbucket/projects/FPCBMW/repos/bmwaws-cli/browse/README.md"
                      title="Python based BMW AWS CLI (internal Documentation)"
                      class="overview btn">
                      <i class="fas fa-book" /> BMW AWS CLI (Docs)
                    </a>
                  </div>
                </div>
                <div class="row">
                  <strong class="dashboard-card-subtitle">
                    <span>Your Links</span>
                    <div
                      id="link-settings"
                      class="dashboard-settings-icon link-icon"
                      onclick={evt => {
                        evt.stopPropagation();
                        showQuickActionsConfigModal(false);
                      }}>
                      <i class="fas fa-wrench"></i>
                    </div>
                  </strong>
                  {((savedQuickLinks && JSON.parse(savedQuickLinks)) || default_quick_links).map(linkItem => {
                    return (
                      <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 dashboard-action">
                        <a href={linkItem.link} class="overview btn">
                          <i class={'fas ' + linkItem.icon} /> {linkItem.label}
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div class="minus-icon dashboard-container-icon">
                <i class="fas fa-minus"></i>
              </div>
              <div class="plus-icon dashboard-container-icon">
                <i class="fas fa-plus"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4">
          <div id="account-card" class="card dashboard-card loading">
            <div class="card-body">
              <span class="dashboard-card-link">
                <a href="#listaccounts">
                  Accounts Overview<i class="fas fa-chevron-right"></i>
                </a>
              </span>
              <h4 class="card-title mb-4">AWS Accounts</h4>
              <div class="card-dropdown mt-2">
                <AccountDropdown
                  label="none"
                  disableUrlParams
                  selectedValues={[]}
                  onchange={redirectToDetailsPage}
                  onrefresh={reloadDropdownOptions}
                  listAll={dashboardShowAllResources}
                  inline
                />
              </div>
              <div class="row mt-4 mb-3">
                <div class="col-6 dashboard-metric">
                  <p class="card-text">Active Accounts</p>
                  <h2 id="number-accounts-active">0</h2>
                </div>
                <div class="col-6 dashboard-metric">
                  <p class="card-text">Default Accounts</p>
                  <h2 id="number-accounts-default">0</h2>
                </div>
                <div class="col-6 dashboard-metric">
                  <p class="card-text">Advanced Accounts</p>
                  <h2 id="number-accounts-advanced">0</h2>
                </div>
                <div class="col-6 dashboard-metric">
                  <p class="card-text">Other Accounts</p>
                  <h2 id="number-accounts-other">0</h2>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <a href="#addaduser">Assign User</a>
              <span class="card-footer-separator">&middot;</span>
              <a href="#removeaduser">Remove User</a>
              <span class="card-footer-separator">&middot;</span>
              <a href="#confirmusers">Confirm User</a>
            </div>
          </div>
        </div>
        {vpcTileVisible && (
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4">
            <div id="vpc-card" class="card dashboard-card loading">
              <div class="card-body">
                <span class="dashboard-card-link">
                  <a href="#listvpc">
                    VPC Overview<i class="fas fa-chevron-right"></i>
                  </a>
                </span>
                <h4 class="card-title mb-4">VPCs</h4>
                <div class="card-dropdown mt-2">
                  <VPCDropdown
                    label="none"
                    disableUrlParams
                    selectedValues={[]}
                    onchange={redirectToDetailsPage}
                    onrefresh={reloadDropdownOptions}
                    listAll={dashboardShowAllResources}
                    inline
                  />
                </div>
                <div class="row mt-4 mb-3">
                  <div class="col-6 dashboard-metric">
                    <p class="card-text">Active VPCs</p>
                    <h2 id="number-vpcs-active">0</h2>
                  </div>
                  <div class="col-6 dashboard-metric">
                    <p class="card-text">Private Network Type</p>
                    <h2 id="number-vpcs-private">0</h2>
                  </div>
                  <div class="col-6 dashboard-metric">
                    <p class="card-text">Public Network Type</p>
                    <h2 id="number-vpcs-public">0</h2>
                  </div>
                  <div class="col-6 dashboard-metric">
                    <p class="card-text">Other</p>
                    <h2 id="number-vpcs-other">0</h2>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <a href="#createnetwork">Order new VPC</a>
              </div>
            </div>
          </div>
        )}
        {orderTileVisible && (
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4">
            <div id="order-card" class="card dashboard-card loading">
              <div class="card-body">
                <span class="dashboard-card-link">
                  <a href="#orders">
                    Orders Overview<i class="fas fa-chevron-right"></i>
                  </a>
                </span>
                <h4 class="card-title mb-4">Orders</h4>
                <div class="card-dropdown mt-2">
                  <OrderDropdown
                    label="none"
                    disableUrlParams
                    selectedValues={[]}
                    daysThreshold={7}
                    onchange={redirectToDetailsPage}
                    onrefresh={reloadDropdownOptions}
                    listAll={dashboardShowAllResources}
                    inline
                  />
                </div>
                <div class="row mt-4 mb-3">
                  <div class="col-6 dashboard-metric">
                    <p class="card-text">Number of Orders (last 7 days)</p>
                    <h2 id="number-orders-sum" style="color:#000">
                      0
                    </h2>
                  </div>
                  <div class="col-6 dashboard-metric">
                    <p class="card-text">New Orders</p>
                    <a href="?approved=new#orders">
                      <h2 id="number-orders-new" style="color:#000">
                        0
                      </h2>
                    </a>
                  </div>
                  <div class="col-6 dashboard-metric">
                    <p class="card-text">Failed Orders (last 7 days)</p>
                    <a href="?executed=failed#orders">
                      <h2 id="number-orders-failed" style="color:#000">
                        0
                      </h2>
                    </a>
                  </div>
                </div>
                <a href="#" class="mt-5 d-none">
                  last 24 hrs
                </a>
              </div>
            </div>
          </div>
        )}
        {dxgwTileVisible && (
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4">
            <div id="dxgw-card" class="card dashboard-card loading">
              <div class="card-body">
                <span class="dashboard-card-link">
                  <a href="#listdxgw">
                    DXGWs Overview<i class="fas fa-chevron-right"></i>
                  </a>
                </span>
                <h4 class="card-title mb-4">DX Gateways</h4>
                <div class="card-dropdown mt-2">
                  <DXGWDropdown
                    label="none"
                    disableUrlParams
                    selectedValues={[]}
                    onchange={redirectToDetailsPage}
                    onrefresh={reloadDropdownOptions}
                    listAll={dashboardShowAllResources}
                    inline
                  />
                </div>
                <div class="row mt-4 mb-3">
                  <div class="col-6 dashboard-metric">
                    <p class="card-text">Active Gateways</p>
                    <h2 id="number-dxgws-active">0</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {hostedZoneTileVisible && (
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4">
            <div id="hosted-zone-card" class="card dashboard-card loading">
              <div class="card-body">
                <span class="dashboard-card-link">
                  <a href="#listhostedzone">
                    Zones Overview<i class="fas fa-chevron-right"></i>
                  </a>
                </span>
                <h4 class="card-title mb-4">Hosted Zones</h4>
                <div class="card-dropdown mt-2">
                  <HostedZoneDropdown
                    label="none"
                    disableUrlParams
                    selectedValues={[]}
                    onchange={redirectToDetailsPage}
                    onrefresh={reloadDropdownOptions}
                    listAll={dashboardShowAllResources}
                    inline
                  />
                </div>
                <div class="row mt-4 mb-3">
                  <div class="col-6 dashboard-metric">
                    <p class="card-text">Active Zones</p>
                    <h2 id="number-zones-active">0</h2>
                  </div>
                  <div class="col-6 dashboard-metric">
                    <p class="card-text">Private Zone Type</p>
                    <h2 id="number-zones-private">0</h2>
                  </div>
                  <div class="col-6 dashboard-metric">
                    <p class="card-text">Public Zone Type</p>
                    <h2 id="number-zones-public">0</h2>
                  </div>
                  <div class="col-6 dashboard-metric">
                    <p class="card-text">Other Zone Types</p>
                    <h2 id="number-zones-other">0</h2>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <a href="#createhostedzone">Order new Hosted Zone</a>
              </div>
            </div>
          </div>
        )}
        {clusterTileVisible && (
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4">
            <div id="cluster-card" class="card dashboard-card loading">
              <div class="card-body">
                <span class="dashboard-card-link">
                  <a href="#list4wheels">
                    Clusters Overview<i class="fas fa-chevron-right"></i>
                  </a>
                </span>
                <h4 class="card-title mb-4">4wheels Clusters</h4>
                <div class="card-dropdown mt-2">
                  <ClusterDropdown
                    label="none"
                    disableUrlParams
                    selectedValues={[]}
                    onchange={redirectToDetailsPage}
                    onrefresh={reloadDropdownOptions}
                    listAll={dashboardShowAllResources}
                    inline
                  />
                </div>
                <div class="row mt-4 mb-3">
                  <div class="col-6 dashboard-metric">
                    <p class="card-text">4wheels Projects</p>
                    <h2 id="number-cluster-projects">0</h2>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <a href="#create4wheels">Order new Cluster</a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  function onPageReady() {
    // ACCOUNT
    const account_dropdown = document.querySelector('#aws-account-id');
    const account_card = document.querySelector('#account-card');
    account_dropdown.addEventListener('dropdown-options-loaded', event => {
      if (event.detail && dashboardShowAllResources === event.detail.list_all) {
        account_card.classList.remove('loading');
        document.querySelector('#number-accounts-active').innerText = event.detail.number_active;
        document.querySelector('#number-accounts-default').innerText = event.detail.number_default;
        document.querySelector('#number-accounts-advanced').innerText = event.detail.number_advanced;
        document.querySelector('#number-accounts-other').innerText = event.detail.number_other;

        if (event.detail.is_cdh_user_roles && !document.querySelector('#cdh-login-link')) {
          document.querySelector('#quick-actions-static-links').appendChild(
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 dashboard-action">
              <a
                id="cdh-login-link"
                href={CONF.awsLoginDetails.cdhLoginUrl}
                title="Login via CDH Portal (Public)"
                class="overview btn">
                <i class="fas fa-sign-in-alt" /> CDH Login (Public)
              </a>
            </div>,
          );
        }
      }
    });

    // VPC
    if (vpcTileVisible) {
      const vpc_dropdown = document.querySelector('#vpc-id');
      const vpc_card = document.querySelector('#vpc-card');
      const vpc_nr_active = document.querySelector('#number-vpcs-active');
      const vpc_nr_private = document.querySelector('#number-vpcs-private');
      const vpc_nr_public = document.querySelector('#number-vpcs-public');
      const vpc_nr_other = document.querySelector('#number-vpcs-other');
      vpc_dropdown.addEventListener('dropdown-options-loaded', event => {
        if (event.detail && dashboardShowAllResources === event.detail.list_all) {
          if (event.detail.page === 0) {
            vpc_card.classList.remove('loading');
            vpc_nr_private.innerText = 0;
            vpc_nr_public.innerText = 0;
            vpc_nr_other.innerText = 0;
          }
          vpc_nr_active.innerText = event.detail.number_active;
          vpc_nr_private.innerText = parseInt(vpc_nr_private.innerText) + event.detail.number_private;
          vpc_nr_public.innerText = parseInt(vpc_nr_public.innerText) + event.detail.number_public;
          vpc_nr_other.innerText = parseInt(vpc_nr_other.innerText) + event.detail.number_other;
        }
      });
    }

    // ORDER
    if (orderTileVisible) {
      const order_dropdown = document.querySelector('#order-id');
      const order_card = document.querySelector('#order-card');
      const order_nr_sum = document.querySelector('#number-orders-sum');
      const order_nr_new = document.querySelector('#number-orders-new');
      const order_nr_failed = document.querySelector('#number-orders-failed');
      order_dropdown.addEventListener('dropdown-options-loaded', event => {
        if (event.detail) {
          if (event.detail.page === 0) {
            order_card.classList.remove('loading');
            order_nr_sum.innerText = 0;
            order_nr_new.innerText = 0;
            order_nr_failed.innerText = 0;
          }
          order_nr_sum.innerText = parseInt(order_nr_sum.innerText) + event.detail.number_sum;
          order_nr_new.innerText = parseInt(order_nr_new.innerText) + event.detail.number_new;
          order_nr_failed.innerText = parseInt(order_nr_failed.innerText) + event.detail.number_failed;
        }
      });
    }

    // DXGW
    if (dxgwTileVisible) {
      const dxgw_dropdown = document.querySelector('#dxgw-id');
      const dxgw_card = document.querySelector('#dxgw-card');
      const dxgw_nr_active = document.querySelector('#number-dxgws-active');
      dxgw_dropdown.addEventListener('dropdown-options-loaded', event => {
        if (event.detail && dashboardShowAllResources === event.detail.list_all) {
          dxgw_card.classList.remove('loading');
          dxgw_nr_active.innerText = event.detail.number_active;
        }
      });
    }

    // HOSTED ZONE
    if (hostedZoneTileVisible) {
      const hosted_zone_dropdown = document.querySelector('#hosted-zone-id');
      const hosted_zone_card = document.querySelector('#hosted-zone-card');
      hosted_zone_dropdown.addEventListener('dropdown-options-loaded', event => {
        if (event.detail && dashboardShowAllResources === event.detail.list_all) {
          hosted_zone_card.classList.remove('loading');
          document.querySelector('#number-zones-active').innerText = event.detail.number_active;
          document.querySelector('#number-zones-private').innerText = event.detail.number_private;
          document.querySelector('#number-zones-public').innerText = event.detail.number_public;
          document.querySelector('#number-zones-other').innerText = event.detail.number_other;
        }
      });
    }

    // CLUSTER
    if (clusterTileVisible) {
      const cluster_dropdown = document.querySelector('#cluster-id');
      const cluster_card = document.querySelector('#cluster-card');
      cluster_dropdown.addEventListener('dropdown-options-loaded', event => {
        if (event.detail) {
          cluster_card.classList.remove('loading');
          document.querySelector('#number-cluster-projects').innerText = event.detail.number_projects;
        }
      });
    }

    // QUICK ACTIONS
    $('#quick-actions-container').on('click', event => {
      if (event.target.tagName === 'A') return;
      $('#quick-actions-content').collapse('toggle');
    });
    const quick_actions_content = $('#quick-actions-content');
    quick_actions_content.on('hidden.bs.collapse', function () {
      localStorage.setItem('customer_portal_aws_quick_actions_expanded', false);
    });
    quick_actions_content.on('shown.bs.collapse', function () {
      localStorage.setItem('customer_portal_aws_quick_actions_expanded', true);
    });

    // ALL RESOURCES SWITCH
    $('#show-all-resources-switch')
      .attr('checked', dashboardShowAllResources)
      .on('change', function () {
        document.querySelectorAll('.dashboard-card').forEach(card_element => card_element.classList.add('loading'));
        sessionStorage.setItem('showAllResources', this.checked);
        dashboardShowAllResources = this.checked;
        const reload_event = new CustomEvent('reload-dropdown-options', {
          detail: {
            allResources: dashboardShowAllResources,
          },
        });

        document.querySelector('#aws-account-id').dispatchEvent(reload_event);
        if (vpcTileVisible) document.querySelector('#vpc-id').dispatchEvent(reload_event);
        if (orderTileVisible) document.querySelector('#order-id').dispatchEvent(reload_event);
        if (dxgwTileVisible) document.querySelector('#dxgw-id').dispatchEvent(reload_event);
        if (hostedZoneTileVisible) document.querySelector('#hosted-zone-id').dispatchEvent(reload_event);
        if (clusterTileVisible) document.querySelector('#cluster-id').dispatchEvent(reload_event);
      });

    // TODO: Remove in Feb 2023
    localStorage.removeItem('xmmodal');
  }

  return [page, onPageReady];
}

// Function to redirect to a details page as specified via the arguments
function redirectToDetailsPage(eventTypeId, eventCategory, _, selectedId) {
  $('.selectpicker').val('default');
  $('.selectpicker').selectpicker('refresh');
  if (eventTypeId && selectedId && eventCategory) {
    window.location.href = `?${eventTypeId}=${selectedId}#${eventCategory}details`;
  }
}
