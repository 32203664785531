import { hasAccess, loadRegionsAndUpdateDropdown } from './js/portal/main';
import { deleteVPCPeeringConfirmation, loadPeeringInfo, loadPeerings } from './js/portal/destroy_peering';
import AccountDropdown from './jsx/components/forms/AccountDropdown';
import { addButtonSpinner, addSpinner } from './js/portal/sidebar';

export default function NetworksPeerDestroyPage() {
  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form name="destroy-peering-form" id="destroy-peering-form" method="POST">
          <div class="card form-card">
            {/* CARD HEADER */}
            <div class="card-header">Destroy VPC Peering</div>

            {/* CARD BODY */}
            <div class="card-body">
              <div id="form-error" class="alert alert-danger" style="display: none">
                <ul />
              </div>

              <div id="form-success" class="alert alert-success" style="display: none">
                <ul />
              </div>

              <div id="infobox" class="card infobox">
                <div class="card-body">
                  <p>With this form you can request a the destruction of a VPC Peering Connection.</p>
                  <p>
                    <strong>Please be aware</strong>, that you can create this order, even if you don't have access to
                    both AWS accounts (requester and accepter). If you don't have access to both AWS accounts, you can't
                    re-establish the VPC Peering Connection by yourself.
                  </p>
                </div>
              </div>

              <div class="form-group row">
                <label for="aws-account-id" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                  <span class="float-sm-start float-md-start float-lg-end text-truncate">AWS Account Id</span>
                </label>
                <div class="col-md-12 col-lg-9 col-xl-10">
                  <AccountDropdown
                    id="aws-account-id"
                    label="none"
                    onchange={(_eventTypeId, _eventCategory, _selectedOption, accountId) => {
                      document.getElementById('submitButton').disabled = !hasAccess(accountId, ['manage_networks']);
                      loadPeerings();
                    }}
                    short
                    lookup
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="region" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                  <span class="float-sm-start float-md-start float-lg-end text-truncate">Region</span>
                </label>
                <div class="col-md-12 col-lg-9 col-xl-10">
                  <select
                    required
                    class="form-control form-select selectpicker"
                    data-live-search="true"
                    data-show-subtext="true"
                    data-size="10"
                    id="region"
                    name="region"
                    onchange={() => loadPeerings()}
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="peering" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                  <span class="float-sm-start float-md-start float-lg-end text-truncate">VPC Peering</span>
                </label>
                <div class="col-md-12 col-lg-9 col-xl-10">
                  <select
                    required
                    class="form-control form-select selectpicker"
                    data-live-search="true"
                    data-show-subtext="true"
                    data-size="10"
                    id="peering"
                    name="peering"
                    onchange={() => loadPeeringInfo()}>
                    <option value="eu-central-1" selected="selected">
                      - Select -
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label for="vpc_peering_status" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                  <span class="float-sm-start float-md-start float-lg-end text-truncate">VPC Peering Status</span>
                </label>
                <div class="col-md-12 col-lg-9 col-xl-10">
                  <input required class="form-control" id="vpc_peering_status" name="vpc_peering_status" disabled />
                </div>
              </div>

              <div class="form-group row">
                <label for="vpc_peering_connection_id" class="col-md-12 col-lg-3 col-xl-2 form-label-text">
                  <span class="float-sm-start float-md-start float-lg-end text-truncate">VPC Peering ID</span>
                </label>
                <div class="col-md-12 col-lg-9 col-xl-10">
                  <input
                    required
                    class="form-control"
                    id="vpc_peering_connection_id"
                    name="vpc_peering_connection_id"
                    disabled
                  />
                </div>
              </div>

              <div class="row">
                {/* NETWORK A */}
                <div class="col-lg-6">
                  <div class="card card-success my-sm-4">
                    <div class="card-header">
                      Network A <small>(VPC Peering Requester)</small>
                    </div>
                    <div class="card-body">
                      <div class="form-group row">
                        <label for="vpc_peering_requester_vpc_owner_id" class="col-form-label">
                          AWS Account ID
                        </label>
                        <div class="col my-auto">
                          <input
                            required
                            class="form-control"
                            id="vpc_peering_requester_vpc_owner_id"
                            name="vpc_peering_requester_vpc_owner_id"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="vpc_peering_requester_vpc_region" class="col-form-label">
                          Region
                        </label>
                        <div class="col">
                          <input
                            required
                            class="form-control"
                            id="vpc_peering_requester_vpc_region"
                            name="vpc_peering_requester_vpc_region"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="vpc_peering_requester_vpc_cidr" class="col-form-label">
                          CIDR
                        </label>
                        <div class="col">
                          <input
                            required
                            class="form-control"
                            id="vpc_peering_requester_vpc_cidr"
                            name="vpc_peering_requester_vpc_cidr"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="vpc_peering_requester_vpc_id" class="col-form-label">
                          Network ID
                        </label>
                        <div class="col">
                          <input
                            required
                            class="form-control"
                            id="vpc_peering_requester_vpc_id"
                            name="vpc_peering_requester_vpc_id"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* NETWORK B */}
                <div class="col-lg-6">
                  <div class="card card-info my-sm-4">
                    <div class="card-header">
                      Network B <small>(VPC Peering Accepter)</small>
                    </div>
                    <div class="card-body">
                      <div class="form-group row">
                        <label for="vpc_peering_accepter_vpc_owner_id" class="col-form-label">
                          AWS Account ID
                        </label>
                        <div class="col my-auto">
                          <input
                            required
                            class="form-control"
                            id="vpc_peering_accepter_vpc_owner_id"
                            name="vpc_peering_accepter_vpc_owner_id"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="vpc_peering_accepter_vpc_region" class="col-form-label">
                          Region
                        </label>
                        <div class="col">
                          <input
                            required
                            class="form-control"
                            id="vpc_peering_accepter_vpc_region"
                            name="vpc_peering_accepter_vpc_region"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="vpc_peering_accepter_vpc_cidr" class="col-form-label">
                          CIDR
                        </label>
                        <div class="col">
                          <input
                            required
                            class="form-control"
                            id="vpc_peering_accepter_vpc_cidr"
                            name="vpc_peering_accepter_vpc_cidr"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="vpc_peering_accepter_vpc_id" class="col-form-label">
                          Network ID
                        </label>
                        <div class="col">
                          <input
                            required
                            class="form-control"
                            id="vpc_peering_accepter_vpc_id"
                            name="vpc_peering_accepter_vpc_id"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* CARD FOOTER */}
            <div class="card-footer" align="right">
              <input type="hidden" name="action" id="action" value="createJob" />
              <input type="hidden" name="jobType" id="jobType" value="destroy-peering" />
              <input type="hidden" name="jobTypeDescription" id="jobTypeDescription" value="Destroy VPC Peering" />
              <button type="submit" class="btn btn-success" id="submitButton" value="Destroy VPC Peering" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                Destroy VPC Peering
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function onPageReady() {
    loadRegionsAndUpdateDropdown('public', ['region']);

    $('#destroy-peering-form')
      .on('submit', function () {
        $('#form-error').hide();
        $('#form-success').hide();
        addSpinner();
        addButtonSpinner();
      })
      .validate({
        errorContainer: '#form-error',
        errorLabelContainer: '#form-error ul',
        wrapper: 'li',
        submitHandler: () => {
          deleteVPCPeeringConfirmation();
        },
      });
  }

  return [page, onPageReady];
}
