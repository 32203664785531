import { Datatable } from './Datatable';
import { initAccountTable } from './js/portal/accounts_table';
import { OverviewSwitch, toggleOverview } from './js/portal/main';

const tableId = 'table-accounts';

export default function AccountListPage() {
  let summary_hidden = ['', 'true'].indexOf(localStorage.getItem('hidden_account_overview')) != -1 ? true : false;

  const page = (
    <>
      <div id="account-numbers" class={'collapse' + (summary_hidden ? '' : ' show') + ' statsOverview'}>
        <div class="statsContainer">
          <div class="col-12">
            <div class="statsCard">
              <div class="statsCardBody text-center row justify-content-center">
                <div class="col-xs-12 col-sm-6 col-lg-3 pb-4">
                  <h5>Total</h5>
                  <div class="statsContentValue">
                    <span id="account-active-value">0</span>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 pb-4">
                  <h5>Advanced</h5>
                  <div class="statsContentValue">
                    <span id="account-advanced-value">0</span>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 pb-4">
                  <h5>Default</h5>
                  <div class="statsContentValue">
                    <span id="account-default-value">0</span>
                  </div>
                </div>
                <div id="account-other-container" class="col-xs-12 col-sm-6 col-lg-3 pb-4 d-none">
                  <h5>Other</h5>
                  <div class="statsContentValue">
                    <span id="account-other-value">0</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>

      <div class="row row-cols-auto justify-content-end gy-3" style="margin-bottom: 20px">
        <div id="show-deleted-accounts-div" class="col col-auto align-self-center">
          <div class="form-check form-switch">
            <input class="form-check-input danger" type="checkbox" id="show-deleted-accounts-switch" />
            <label class="form-check-label" for="show-deleted-accounts-switch">
              Show deleted accounts
            </label>
          </div>
        </div>

        <div id="show-all-accounts-div" class="col col-auto align-self-center d-none">
          <div class="form-check form-switch">
            <input class="form-check-input danger" type="checkbox" id="show-all-accounts-switch" />
            <label class="form-check-label" for="show-all-accounts-switch">
              Show all accounts
            </label>
          </div>
        </div>

        <OverviewSwitch targets="#account-numbers" localStorageItem="hidden_account_overview" />
      </div>

      {/* ERROR MESSAGES AND EXPLANATION IF THERE ARE NO ACCOUNTS */}
      <div id="noaccountalert" class="alert alert-warning" role="alert" style="display: none">
        <h2>
          Attention,{' '}
          <small>there are no AWS accounts connected with your username. This could have three reasons:</small>
        </h2>
        <ol>
          <li>
            <strong>You don't have an AWS Account yet.</strong> Please have a look in{' '}
            <a
              href="https://atc.bmwgroup.net/confluence/display/CBSCC/How+to+get+an+AWS+Account"
              target="_blank"
              rel="noopener noreferrer">
              Confluence
            </a>{' '}
            for how to get an AWS Account.
          </li>
          <li>
            <strong>Your Portal User and your AWS User is not the same.</strong> Please register a new Portal User which
            spelled like your IAM User or your primary BMW mail address. You can find some more detailed description in
            our{' '}
            <a href="https://atc.bmwgroup.net/confluence/x/6CHVAQ" target="_blank" rel="noopener noreferrer">
              Confluence
            </a>
          </li>
          <li>
            <strong>You just got access to AWS or a new IAM Role assigned, but our DB is not yet up to date.</strong>{' '}
            Please try again in about half an hour.
          </li>
        </ol>
        If you still see no AWS Accounts, you can contact us via ITSM Service{' '}
        <strong>public-cloud-framework:global</strong>.
      </div>

      <Datatable tableId={tableId} />

      <div
        className="modal fade"
        id="dlWizard"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="dlWizardTitle"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="dlWizardTitle" />
              <button type="button" className="btn-close close-wizard" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body" id="dlWizardBody" />
            <div className="modal-footer" id="dlWizardFooter">
              <button type="button" className="btn btn-outline-secondary close-wizard" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  function onPageReady() {
    toggleOverview('hidden_account_overview');
    initAccountTable(tableId);
  }

  return [page, onPageReady];
}
