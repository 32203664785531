import swal from 'sweetalert';
import { addButtonSpinner, addSpinner, removeSpinners } from './js/portal/sidebar';
import { readIamUsersFromAccount } from './js/portal/user-management';
import AccountDropdown from './jsx/components/forms/AccountDropdown';
import { baseApiAxios, showError } from './js/portal/api';
import { hasAccess, addToast } from './js/portal/main';

export default function ServiceUsersDeletePage() {
  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form name="service-user-delete-form" id="service-user-delete-form" method="POST">
          <div class="card form-card">
            <div class="card-header">Delete IAM Service User</div>
            <div class="card-body">
              <div id="form-error" class="alert alert-danger" style="display: none">
                <ul />
              </div>

              <div id="form-success" class="alert alert-success" style="display: none">
                <ul />
              </div>

              <div id="infobox" class="card infobox">
                <div class="card-body">
                  With this form you can order to delete an <strong>AWS IAM Service User</strong> in your target AWS
                  Account. If you need to delete more than one user, please raise multiple orders. Please be aware, that
                  user names at AWS are <strong>case sensitive</strong>. For more information please have a look in our{' '}
                  <a
                    href="https://developer.bmwgroup.net/docs/public-cloud-platform-aws/"
                    target="_blank"
                    rel="noopener noreferrer">
                    Developer Portal
                    <i class="px-1 fas fa-external-link-alt" />
                  </a>
                  .
                </div>
              </div>

              <AccountDropdown
                id="aws-account-id"
                onchange={(_eventTypeId, _eventCategory, _selectedOption, accountId) => {
                  document.getElementById('submitButton').disabled = !hasAccess(accountId, [
                    'manage_users',
                    'manage_organizations',
                  ]);
                  readIamUsersFromAccount(accountId);
                }}
                short
                lookup
              />

              {/* selectpicker options: https://developer.snapappointments.com/bootstrap-select/options/ */}
              <div class="form-group row">
                <label for="target-user" class="col-form-label">
                  Target IAM User
                  <span class="loading-form-inline loading-target-iam-user">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </span>
                </label>
                <div class="col">
                  <select
                    required
                    class="form-control form-select selectpicker"
                    data-live-search="true"
                    data-show-subtext="true"
                    id="target-user"
                    name="target-user"
                    data-size="10"
                    data-actions-box="false"
                    data-none-selected-text="- Select target IAM User -">
                    <option value="" disabled>
                      - Select target IAM User -
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="card-footer" align="right">
              <input type="hidden" name="action" id="action" value="createJob" />
              <button type="submit" class="btn btn-success" id="submitButton" value="Delete">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                Delete
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function resultFunction() {
    removeSpinners();
    const form = document.getElementById('service-user-delete-form');
    form.reset();
    $('#target-user option:not([disabled])').remove();
    $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
    $('.selectpicker').selectpicker('refresh');
  }

  function onPageReady() {
    $('.selectpicker').selectpicker('refresh');

    $('#service-user-delete-form')
      .on('submit', function (e) {
        e.preventDefault();
        $('#form-error').hide();
        $('#form-success').hide();
      })
      .validate({
        errorContainer: '#form-error',
        errorLabelContainer: '#form-error ul',
        wrapper: 'li',
        submitHandler: function () {
          addSpinner();
          addButtonSpinner();

          const payload = {
            action: 'delete-service-user',
            description: 'Delete Service User "' + $('#target-user')[0].value + '"',
            user_name: $('#target-user')[0].value,
            account_id: $('#aws-account-id')[0].value.split(';')[0],
          };

          swal({
            title: 'Are you sure?',
            text: 'Are you sure you want to delete the service user ' + $('#target-user')[0].value,
            icon: 'warning',
            buttons: {
              cancel: {
                text: 'Cancel',
                value: null,
                visible: true,
              },
              okay: {
                text: 'Update',
                value: true,
                visible: true,
              },
            },
          }).then(function (response) {
            if (response) {
              baseApiAxios
                .createOrder(payload)
                .then(orderResponse => {
                  resultFunction();
                  addToast('Create Order', orderResponse.message, 6000);
                })
                .catch(orderError => {
                  showError(orderError);
                });
            } else {
              removeSpinners();
            }
          });
        },
      });
  }

  return [page, onPageReady];
}
