import axios from 'axios';
import swal from 'sweetalert';

import { authInterceptor } from './auth';
import { CONF } from './environment';
import { displayErrorPopup, displayInfoPopup } from './main';
import { addSpinner, removeSpinners } from './sidebar';

/**
 * Provides methods for accessing the accounts dl API in the backend.
 * @constructor
 */
export function DistributionListApi() {
  const axiosInstance = axios.create({
    baseURL: CONF.apiEndpoint + '/accounts',
  });
  axiosInstance.interceptors.request.use(authInterceptor);

  /**
   * Fetch the distribution list status.
   * @param {string} accountId the ID of the account that has the dl assigned
   * @param {string} dlType the type of distribution list (i.e operations_dl, billing_dl)
   * @returns {Promise<object>} The DL information
   */
  this.getDLinfo = (accountId, dlType) =>
    axiosInstance
      .get('/' + accountId + '/distributionlists/' + dlType)
      .then(response => {
        console.debug('GET ' + accountId + '/distributionlists/' + dlType + ' response:', response);
        return response.data;
      })
      .catch(displayErrorPopup)
      .finally(() => {
        removeSpinners();
      });

  /**
   * Update distribution list info.
   * @param {string} accountId the ID of the account that has the dl assigned
   * @param {string} dlType the type of distribution list (i.e operations_dl, billing_dl)
   * @param {object} patchData the data to send as payload
   * @returns {Promise<object>} The DL update status
   */
  this.patchDLinfo = (accountId, dlType, patchData) =>
    axiosInstance
      .patch('/' + accountId + '/distributionlists/' + dlType, patchData)
      .then(response => {
        console.debug('PATCH' + accountId + '/distributionlists/' + dlType + ' response:', response);
        return response;
      })
      .catch(displayErrorPopup)
      .finally(() => {
        removeSpinners();
      });
}

export function DistributionListOperations(distributionListApi, accountId, dlType, existingMembers) {
  /**
   * Perform the update of the DL members
   */
  this.callUpdateDL = () => {
    let addMembers = [];
    let keepMembers = [];
    let removeMembers = [];
    $('#table-dl-members tr').each(function (_i, _row) {
      const email = $(this).find('td:nth-child(1)').text();
      if (!existingMembers.includes(email)) {
        addMembers.push(email);
      } else {
        keepMembers.push(email);
      }
    });

    existingMembers.forEach(function (member) {
      if (!keepMembers.includes(member)) {
        removeMembers.push(member);
      }
    });

    if (addMembers.length === 0 && removeMembers.length === 0) {
      console.debug('There are no changes');
    } else {
      warnMakeDLChanges(accountId, dlType, addMembers, removeMembers, distributionListApi);
    }
  };

  this.addMemberToList = () => {
    const user = $('#user-email').val();
    $('#dl-member-invalid-feedback')[0].textContent = '';

    if (user === '') {
      $('#add-dl-member-button').attr('disabled', true);
    } else if ($('#table-dl-members tr:contains("' + user + '")').length > 0) {
      $('#dl-member-invalid-feedback')[0].textContent = 'Member was already added to the list';
    } else {
      $('#add-dl-member-button').attr('disabled', false);
      $('#save-modal').show();
      addDistributionListMember(user);
    }
  };

  this.addDistributionList = () => {
    const distributionListPrefix = $('#distribution-list-prefix').val();
    const distributionListSuffix = $('#distribution-list-suffix').text();
    const distributionListEmail = distributionListPrefix + distributionListSuffix;
    $('#dl-invalid-feedback')[0].textContent = '';

    if (distributionListPrefix === '') {
      $('#add-distribution-list-button').attr('disabled', true);
    } else if ($('#table-dl-members tr:contains("' + distributionListEmail + '")').length > 0) {
      $('#dl-invalid-feedback')[0].textContent = 'Member was already added to the list';
    } else {
      $('#dd-distribution-list-button').attr('disabled', false);
      $('#save-modal').show();
      addDistributionListMember(distributionListEmail);
      $('#distribution-list-prefix').val('');
    }
  };
}

export function enableAddDlMember() {
  if ($('#user-email').val() === '') {
    $('#add-dl-member-button').attr('disabled', true);
  } else {
    $('#add-dl-member-button').attr('disabled', false);
  }
}

export function enableAddDistributionList() {
  if ($('#distribution-list-prefix').val() == '' || $('#distribution-list-prefix').val().indexOf(' ') >= 0) {
    $('#add-distribution-list-button').attr('disabled', true);
  } else {
    const email = $('#distribution-list-prefix').val() + $('#distribution-list-suffix').text();
    // Validate email
    if (email.match(/^.+@list\.bmw\.com$/)) {
      $('#add-distribution-list-button').attr('disabled', false);
    } else {
      $('#add-distribution-list-button').attr('disabled', true);
    }
  }
}

function warnMakeDLChanges(accountId, dlType, addMembers, removeMembers, distributionListApi) {
  swal({
    title: 'Are you sure?',
    text: "This action will trigger Mailtools' updates for this distribution list",
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      okay: {
        text: 'Accept',
        value: true,
        visible: true,
      },
    },
  }).then(function (swal_response) {
    if (swal_response) {
      addSpinner();
      let dlData = { add: addMembers, remove: removeMembers };
      distributionListApi.patchDLinfo(accountId, dlType, dlData).then(function (response) {
        if (response.status !== 200) {
          swal({
            title: 'Cannot update distribution List',
            text: 'Something went wrong',
            icon: 'warning',
          }).then(function () {
            removeSpinners();
          });
        } else {
          displayInfoPopup(
            'The update of the distribution list has been triggered. This process takes a few minutes.',
            'Distribution List update initiated',
          ).then(function () {
            removeSpinners();
          });
        }
      });
      $('#dlWizard').modal('hide');
    }
  });
}

function addDistributionListMember(email) {
  $('#table-dl-members').append(
    $('<tbody>').append(
      $('<tr role="row" class="compressed">').append(
        $('<td class="name small-width">')
          .css('border-top', '1px solid rgba(0,0,0,.15)')
          .text(email.toLowerCase())
          .addCopyButton(),
        $('<td class="value small-width">').append(
          $('<button class="btn table-cell-button btn-outline-dark">')
            .on('click', function () {
              this.parentNode.parentNode.remove();
            })
            .html('<span class="fas fa-trash"></span>'),
        ),
      ),
    ),
  );
}
export function formatChildRowDistribuitonListMembers(dlData, editableMembersList) {
  removeSpinners();
  let membersEmails = [];
  $('#table-dl-members').append(
    $('<thead>').append(
      '<th class="table table-hover dataTable row-border nowrap compressed">Email</th>',
      '<th class="table table-hover dataTable row-border nowrap compressed" id="dl-actions" style="display: none">Action</th>',
    ),
    $('<tbody>').append(
      dlData.dl_info.members.map(member => {
        const memberEmail = member.mail.toLowerCase();
        if (memberEmail !== '') {
          membersEmails.push(memberEmail);
          let dlMemberRow = $('<tr role="row" class="compressed">');
          let dlMemberInfo = $('<td class="name small-width">').text(memberEmail).addCopyButton();
          dlMemberRow.append(dlMemberInfo);
          if (editableMembersList) {
            dlMemberRow.append(
              dlMemberInfo,
              $('<td class="value small-width">').append(
                $('<button class="btn table-cell-button btn-outline-dark">')
                  .on('click', function () {
                    $('#save-modal').show();
                    this.parentNode.parentNode.remove();
                  })
                  .html('<span class="fas fa-trash"></span>'),
              ),
            );
          }
          return dlMemberRow;
        }
      }),
    ),
  );
  if (editableMembersList) {
    $('#add-new-member-div').show();
    $('#dl-actions').show();
  }
  return membersEmails;
}
