import { removeSpinners } from './js/portal/sidebar';
import { change_password } from './js/portal/auth';

export default function LoginChangePasswordPage() {
  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form name="change-password-form" id="change-password-form" method="POST">
          <div class="card form-card">
            <div class="card-header">Change Password for your Portal User</div>
            <div class="card-body">
              <div id="form-error" class="alert alert-danger" style="display: none">
                <ul />
              </div>
              <div id="form-success" class="alert alert-success" style="display: none">
                <ul />
              </div>
              <div id="infobox" class="card infobox">
                <div class="card-body">
                  With this form you can change your password for the BMW Customer Portal for Amazon Web Service. You
                  can't change the password for your AWS IAM User with this dialog.
                </div>
              </div>

              <div class="form-group row">
                <label for="current-password" class="col-form-label">
                  Current Password
                </label>
                <div class="col">
                  <input
                    type="password"
                    class="form-control"
                    id="current-password"
                    name="current-password"
                    placeholder="Current password"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="new-password" class="col-form-label">
                  New Password
                </label>
                <div class="col">
                  <input
                    type="password"
                    class="form-control"
                    id="new-password"
                    name="new-password"
                    placeholder="New password"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="repeat-password" class="col-form-label">
                  Repeat Password
                </label>
                <div class="col">
                  <input
                    type="password"
                    class="form-control"
                    id="repeat-password"
                    name="repeat-password"
                    placeholder="Repeat new password"
                  />
                </div>
              </div>
            </div>
            <div class="card-footer" align="right">
              <input type="hidden" name="jobType" id="jobType" value="change-password" />
              <input type="hidden" name="jobTypeDescription" id="jobTypeDescription" value="Change Password" />
              <input type="submit" class="btn btn-success" value="Change Password" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function resultFunction(result) {
    const form = document.getElementById('change-password-form');
    removeSpinners();

    if (result === 'SUCCESS') {
      $('#form-success').html(
        '<strong>Success: </strong>Password is changed. Please update your password safe with your new credentials.'
      );
      $('#form-success').show();
      form.reset();
      $('.selectpicker').selectpicker('refresh');
      $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
    } else {
      $('#form-error').html('<strong>Warning: </strong>' + result + ' ');
      $('#form-error').show();
      form.reset();
      $('.selectpicker').selectpicker('refresh');
      $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
    }
  }

  function onPageReady() {
    $('#change-password-form')
      .on('submit', function (e) {
        e.preventDefault();
        $('#form-error').hide();
        $('#form-success').hide();
      })
      .validate({
        errorContainer: '#form-error',
        errorLabelContainer: '#form-error ul',
        wrapper: 'li',
        submitHandler: function () {
          const old_pw = document.getElementById('current-password').value;
          const new_pw = document.getElementById('new-password').value;
          const repeat_pw = document.getElementById('repeat-password').value;

          if (new_pw === repeat_pw) {
            change_password(old_pw, new_pw, resultFunction);
          } else {
            $('#form-error')
              .html("<strong>Warning: </strong>Your new password and the repeated password won't match.")
              .show();
          }
        },
        rules: {
          'current-password': 'required',
          'new-password': 'required',
          'repeat-password': 'required',
        },
        messages: {
          'current-password': 'Please enter your current password!',
          'new-password': 'Please enter your new password!',
          'repeat-password': 'Please repeat your new password!',
        },
      });
  }

  return [page, onPageReady];
}
