export const TemporaryRoleComment = () => (
  <>
    <div class="alert alert-primary">Comment your reasons why you need a temporary access before proceeding.</div>
    <div class="form-group row" id="form_group_comment">
      <div class="col">
        <textarea
          rows="12"
          class="form-control"
          id="temp-access-comment"
          name="temp-access-comment"
          placeholder="Comment here"
        />
      </div>
    </div>
  </>
);
