import swal from 'sweetalert';
import { baseApiAxios, showError } from '../../../js/portal/api';
import { addToast, checkValueAgainstList, InfoTable, InfoTableWide } from '../../../js/portal/main';
import { addSpinner, removeSpinners } from '../../../js/portal/sidebar';

async function deleteDxGwHandler(network_information) {
  swal({
    title: 'Are you sure?',
    text: 'Are you sure you want to delete "' + network_information.name + '" from the database?',
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      reset: {
        text: 'Delete',
        value: true,
        className: 'swal-button swal-button--confirm swal-button--danger',
      },
    },
    dangerMode: true,
  }).then(async response => {
    if (response) {
      addSpinner();
      let api_result;
      try {
        api_result = await baseApiAxios.deleteNetworkItem(network_information.id, network_information.uuid);
        window.location.hash = '#listdxgw';
        addToast('Delete DXGW', api_result.message, 6000);
      } catch (err) {
        removeSpinners();
        showError(err);
      }
    }
  });
}

export default function DXGWTabs(props) {
  const { data } = props;

  if (!data.network_item) data.network_item = {};
  if (!data.account_item) data.account_item = {};

  const { account_item, network_item } = data;

  // Direct Connect Gateway
  const permissions = localStorage.permissions ? localStorage.permissions : false;
  const network_data = {
    item_type: network_item.item_type,
    uuid: network_item.uuid,
    id: network_item.id,
    name: network_item.name,
  };

  // Associations
  const assoc_items = [];
  network_item.dx_associations?.forEach(item => {
    const cidrs = [];
    item.allowedPrefixesToDirectConnectGateway.forEach(prefix => {
      cidrs.push(prefix.cidr);
    });

    const assoc_item = {
      AssociationId: item.associationId,
      AssociationState: item.associationState,
      DirectConnectGatewayId: item.directConnectGatewayId,
      DirectConnectGatewayOwnerAccount: item.directConnectGatewayOwnerAccount,
      VirtualGatewayId: item.virtualGatewayId,
      VirtualGatewayOwnerAccount: item.virtualGatewayOwnerAccount,
      VirtualGatewayRegion: item.virtualGatewayRegion,
      CIDRs: cidrs.join(', '),
    };

    assoc_items.push(assoc_item);
  });
  const associationsEnabled = !!assoc_items.length;

  // Interfaces
  const interfacesEnabled = !!network_item.dx_virtual_interfaces?.length;

  return (
    <div class="detailsContainer">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a
            class="nav-link active"
            data-bs-target={'#dx_gw' + network_item.dx_gw_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="true"
            aria-expanded="true">
            Direct Connect Gateway
          </a>
        </li>
        <li class="nav-item">
          <a
            class={'nav-link' + (associationsEnabled ? '' : ' disabled')}
            data-bs-target={'#associations' + network_item.dx_gw_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="false"
            aria-expanded="false">
            Associations
          </a>
        </li>
        <li class="nav-item">
          <a
            class={'nav-link' + (interfacesEnabled ? '' : ' disabled')}
            data-bs-target={'#interfaces' + network_item.dx_gw_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="false"
            aria-expanded="false">
            Virtual Interfaces
          </a>
        </li>
      </ul>
      <div class="tab-content detailsTab">
        <div class="tab-pane fade active show" id={'dx_gw' + network_item.dx_gw_id} role="tabpanel">
          <div class="row" name="dx-gw-container">
            <div class="col-md-12 col-lg-6">
              <div class="detailsContent">
                <h4>Direct Connect Gateway Details</h4>
                <div>
                  <InfoTable
                    data={network_item}
                    rowInfos={[
                      { Name: 'Account Id', Value: 'aws_account_id' },
                      { Name: 'DX Gateway ID', Value: 'dx_gw_id' },
                      { Name: 'Name', Value: 'name' },
                      { Name: 'Region', Value: 'aws_region' },
                      { Name: 'Netbond CIDR', Value: 'netbond_cidr' },
                      { Name: 'DX Gateway Status', Value: 'status' },
                      { Name: 'Error Details', Value: 'error_details' },
                      { Name: 'Last Status Update (UTC)', Value: 'status_update' },
                    ]}
                  />
                </div>
              </div>
              {checkValueAgainstList('manage_networks', permissions) && (
                <div class="detailsContent">
                  <div class="btn-group detail-actions-button-group">
                    <button class="btn-sm" onclick={() => deleteDxGwHandler(network_data)}>
                      <span class="fas fa-trash-alt" /> Delete
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="detailsContent">
                <h4>Account Details</h4>
                <div>
                  <InfoTable
                    data={account_item}
                    rowInfos={[
                      { Name: 'Account Id', Value: 'account_id' },
                      { Name: 'Root Account', Value: 'account_root_email' },
                      { Name: 'Friendly Name', Value: 'account_friendly_name' },
                      { Name: 'Description', Value: 'description' },
                      { Name: 'FPC Status', Value: 'fpc_status' },
                      { Name: 'Account Area', Value: 'account_area' },
                      { Name: 'Account Type', Value: 'account_type' },
                      { Name: 'Account Stage', Value: 'account_stage' },
                      { Name: 'AWS Support Plan', Value: 'support_plan' },
                      { Name: 'IT Responsible', Value: 'it_responsible' },
                      { Name: 'Primary Responsible', Value: 'primary_responsible' },
                      { Name: 'Secondary Responsible', Value: 'sec_responsible' },
                      { Name: 'APP ID', Value: 'appid' },
                      { Name: 'APPD ID', Value: 'appdid' },
                      { Name: 'Cost Center', Value: 'cost_center' },
                      { Name: 'PSO/PSP', Value: 'pso' },
                      { Name: 'Creation Date', Value: 'creation_date' },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id={'associations' + network_item.dx_gw_id} role="tabpanel">
          <div class="row" name="associations-container">
            {associationsEnabled && (
              <div class="col-md-12 col-md-12">
                <div class="detailsContent">
                  <h4>Direct Connect Gateway Associations</h4>
                  <div>
                    <InfoTableWide
                      data={assoc_items}
                      colInfos={[
                        'VirtualGatewayOwnerAccount',
                        'VirtualGatewayId',
                        'VirtualGatewayRegion',
                        'CIDRs',
                        'AssociationId',
                        'AssociationState',
                        'DirectConnectGatewayId',
                        'DirectConnectGatewayOwnerAccount',
                      ]}
                      rowNumbers={true}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div class="tab-pane fade" id={'interfaces' + network_item.dx_gw_id} role="tabpanel">
          <div class="row" name="interfaces-container">
            {interfacesEnabled && (
              <div class="col-lg-12 col-md-12">
                <div class="detailsContent">
                  <h4>Direct Connect Interfaces</h4>
                  <div>
                    <InfoTableWide
                      data={network_item.dx_virtual_interfaces}
                      colInfos={[
                        'region',
                        'addressFamily',
                        'virtualGatewayId',
                        'virtualInterfaceId',
                        'virtualInterfaceName',
                        'virtualInterfaceState',
                        'virtualInterfaceType',
                        'amazonAddress',
                        'amazonSideAsn',
                        'customerAddress',
                        'asn',
                        'vlan',
                        'directConnectGatewayId',
                      ]}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
