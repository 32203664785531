import swal from 'sweetalert';
import { createITSMTicket, loadRegionsAndUpdateDropdown, removeDisabledOptions } from './js/portal/main';
import { addButtonSpinner, addSpinner, removeSpinners } from './js/portal/sidebar';
import AccountDropdown from './jsx/components/forms/AccountDropdown';

export default function ItsmCreateTicketPage() {
  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form name="create-itsm-ticket-form" id="create-itsm-ticket-form" method="POST">
          <div class="card form-card">
            {/* CARD HEADER */}
            <div class="card-header">Submit a request</div>

            {/* CARD BODY */}
            <div class="card-body">
              {/* Infobox */}
              <div id="infobox" class="card infobox">
                <div class="card-body">
                  <p>
                    With this form you can create an incident for our ITSM service{' '}
                    <code>public-cloud-framework:global</code>.
                  </p>
                  <p>
                    <ul>
                      <li>
                        Unfortunately we can only support for{' '}
                        <a
                          href="https://developer.bmwgroup.net/docs/public-cloud-platform-aws/1_beforeyoustart/platformdoc/"
                          target="_blank"
                          title="Developer Portal - AWS Platform Offer"
                          rel="noopener noreferrer">
                          services in our scope
                          <i class="px-1 fas fa-external-link-alt" />
                        </a>
                      </li>
                      <li>
                        We summarized frequently asked questions (FAQs) in the{' '}
                        <a
                          href="https://developer.bmwgroup.net/docs/public-cloud-platform-aws/1_beforeyoustart/faq/"
                          target="_blank"
                          title="Developer Portal - FAQ"
                          rel="noopener noreferrer">
                          Developer Portal
                          <i class="px-1 fas fa-external-link-alt" />
                        </a>
                      </li>
                      <li>
                        To ask new questions please visit{' '}
                        <a
                          href="https://atc.bmwgroup.net/confluence/display/DEVOPSPF/questions/all"
                          target="_blank"
                          title="Confluence Questions"
                          rel="noopener noreferrer">
                          Confluence Questions
                          <i class="px-1 fas fa-external-link-alt" />
                        </a>
                      </li>
                      <li>
                        For problems and questions about AWS which are outside of our scope, please login to your AWS
                        account and{' '}
                        <a
                          href="https://support.console.aws.amazon.com/support/"
                          target="_blank"
                          title="AWS Support Center"
                          rel="noopener noreferrer">
                          create an AWS support case
                          <i class="px-1 fas fa-external-link-alt" />
                        </a>
                      </li>
                    </ul>
                  </p>
                  <p>
                    Please read the notes next to the impact dropdown before you open a new incident to select a
                    reasonable option. The impact level <code>Minor</code> and <code>Moderate</code> can be selected for
                    all accounts. <code>Significant</code> and <code>Extensive</code> is only available for AWS accounts
                    with account stage <code>prod</code>.
                  </p>
                </div>
              </div>

              <div id="tabs" class="tab-content">
                <div id="submit_request" class="tab">
                  {/* Summary */}
                  <div class="form-group row">
                    <label for="summary" class="col-form-label">
                      <span class="float-sm-start float-md-start float-lg-end mx-xl-3">Summary</span>
                    </label>
                    <div class="col">
                      <input
                        required
                        type="text"
                        class="form-control"
                        id="summary"
                        name="summary"
                        placeholder="Summary"
                        maxlength="100"
                      />
                    </div>
                  </div>

                  {/* Service */}
                  <div class="form-group row">
                    <label for="service" class="col-form-label">
                      <span class="float-sm-start float-md-start float-lg-end mx-xl-3">Service</span>
                    </label>
                    <div class="col">
                      <select
                        class="form-control form-select selectpicker"
                        data-live-search="true"
                        data-show-subtext="true"
                        data-none-selected-text="- Select a Service for the Ticket -"
                        data-selected-text-format="count > 5"
                        data-size="10"
                        id="service"
                        name="service">
                        <option
                          value="public-cloud-framework:global"
                          selected
                          data-subtext="public-cloud-framework:global">
                          General/Cloud Foundation
                        </option>
                        <option value="4wheels-guided:global" data-subtext="4wheels-guided:global">
                          4wheels Guided
                        </option>
                        <option value="public-cloud-billing:global" data-subtext="public-cloud-billing:global">
                          Cost Data
                        </option>
                      </select>
                    </div>
                  </div>

                  {/* AWS Account Id */}
                  <div class="form-group row">
                    <label for="aws-account-id" class="col-form-label">
                      <span class="float-sm-start float-md-start float-lg-end mx-xl-3">AWS Account Id</span>
                    </label>
                    <div class="col">
                      <AccountDropdown label="none" id="aws-account-id" onchange={updateImpact} short multiple />
                    </div>
                  </div>

                  {/* Region */}
                  <div class="form-group row">
                    <label for="region" class="col-form-label">
                      <span class="float-sm-start float-md-start float-lg-end mx-xl-3">Region</span>
                    </label>
                    <div class="col">
                      <select
                        class="form-control form-select selectpicker"
                        data-live-search="true"
                        data-show-subtext="true"
                        data-none-selected-text="- Select an AWS region -"
                        data-selected-text-format="count > 5"
                        data-size="10"
                        multiple
                        id="region"
                        name="region"
                      />
                    </div>
                  </div>

                  {/* Impact */}
                  <div class="form-group row">
                    <label for="impact" class="col-form-label">
                      <span class="float-xs-start float-sm-start float-md-start float-lg-end mx-xl-3">Impact</span>
                    </label>
                    <div class="col">
                      <div class="row">
                        <div class="col-6" id="impact-dropdown-container">
                          <select
                            class="form-control form-select selectpicker"
                            required
                            id="impact"
                            onchange={() => toggleImpactAlert()}>
                            <option value="Minor" selected>
                              Minor/Localized
                            </option>
                            <option value="Moderate">Moderate/Limited</option>
                          </select>
                        </div>
                        <div class="col-6">
                          <div id="impact-extensive-alert" style="display:none">
                            <div class="alert alert-danger d-flex align-items-start" role="alert">
                              <span class="me-3">
                                <i class="fas fa-info-circle" />
                              </span>
                              <div>
                                <p class="p-0 m-0">Significant risk of loss of reputation</p>
                                <ul class="m-0">
                                  <li>or legal exposure for BMW</li>
                                  <li>or significant risk of financial loss {'>'} 100,000 €</li>
                                  <li>or 500+ users are affected</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div id="impact-significant-alert" style="display:none">
                            <div class="alert alert-warning d-flex align-items-start" role="alert">
                              <span class="me-3">
                                <i class="fas fa-info-circle" />
                              </span>
                              <div>
                                <p class="p-0 m-0">50+ users are affected</p>
                                <ul class="m-0">
                                  <li>or interruption of business process</li>
                                  <li>or significant risk of finacial loss {'>'} 50.000 €</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div id="impact-moderate-alert" style="display:none">
                            <div class="alert alert-info d-flex align-items-start" role="alert">
                              <span class="me-3">
                                <i class="fas fa-info-circle" />
                              </span>
                              <div>
                                <p class="p-0 m-0">Up to 50 users are affected and no business process interruption</p>
                                <ul class="m-0">
                                  <li>or significant risk of financial loss {'>'} 10,000 €</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div id="impact-minor-alert" style="display:none">
                            <div class="alert alert-info d-flex align-items-start" role="alert">
                              <span class="me-3">
                                <i class="fas fa-info-circle" />
                              </span>
                              <div>
                                <p class="p-0 m-0">Up to 5 users are affected and no business process interruption.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Urgency */}
                  <div class="form-group row">
                    <label for="urgency" class="col-form-label">
                      <span class="float-sm-start float-md-start float-lg-end mx-xl-3">Urgency</span>
                    </label>
                    <div class="col">
                      <select
                        class="form-control form-select selectpicker"
                        required
                        id="urgency"
                        data-show-subtext="true">
                        <option value="Low" selected data-subtext="Currently no impact forecast. Target date agreed.">
                          Low
                        </option>
                        <option value="Medium" data-subtext="Impact will occur within the next days.">
                          Medium
                        </option>
                        <option
                          value="High"
                          data-subtext="Impact will occur by the end of the current business day/ current shift.">
                          High
                        </option>
                        <option
                          value="Critical"
                          data-subtext="Impact has already occured or will occur within the next hours.">
                          Critical
                        </option>
                      </select>
                    </div>
                  </div>

                  {/* Preferred contact mode */}
                  <div class="form-group row">
                    <label for="contact-mode" class="col-form-label">
                      <span class="float-sm-start float-md-start float-lg-end mx-xl-3">Preferred contact mode</span>
                    </label>
                    <div class="col">
                      <select
                        class="form-control form-select selectpicker"
                        required
                        id="contact-mode"
                        data-show-subtext="true">
                        <option value="Email" selected>
                          Email
                        </option>
                        <option value="Phone" data-subtext="Please add your phone number in the description">
                          Phone
                        </option>
                        <option value="Teams">Teams</option>
                      </select>
                    </div>
                  </div>

                  {/* Description */}
                  <div class="form-group row">
                    <label for="description" class="col-form-label">
                      <span class="float-sm-start float-md-start float-lg-end mx-xl-3">Description</span>
                    </label>
                    <div class="col">
                      <textarea
                        class="form-control"
                        rows="4"
                        id="description"
                        name="description"
                        placeholder="Description"
                        required
                      />
                    </div>
                  </div>

                  {/* Attachment 1 */}
                  <div class="form-group row">
                    <label for="attachment" class="col-form-label">
                      <span class="float-sm-start float-md-start float-lg-end mx-xl-3">Attachment</span>
                    </label>
                    <div class="col">
                      <div class="col input-group">
                        <input class="form-control" type="file" id="attachment" />
                        <button
                          onclick={clearSelection}
                          type="button"
                          data-attachment-id="attachment"
                          class="btn btn-danger"
                          title="Delete Attachment">
                          <i class="fas fa-trash" />
                        </button>
                      </div>{' '}
                    </div>
                  </div>

                  {/* Attachment 2 */}
                  <div class="form-group row">
                    <label for="attachment2" class="col-form-label">
                      <span class="float-sm-start float-md-start float-lg-end mx-xl-3">Attachment</span>
                    </label>
                    <div class="col">
                      <div class="col input-group">
                        <input class="form-control" type="file" id="attachment2" />
                        <button
                          onclick={clearSelection}
                          type="button"
                          data-attachment-id="attachment2"
                          class="btn btn-danger"
                          title="Delete Attachment">
                          <i class="fas fa-trash" />
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Attachment 3 */}
                  <div class="form-group row">
                    <label for="attachment3" class="col-form-label">
                      <span class="float-sm-start float-md-start float-lg-end mx-xl-3">Attachment</span>
                    </label>
                    <div class="col">
                      <div class="col input-group">
                        {/* <label for="attachment3" class="btn btn-secondary">Select File</label> */}
                        <input
                          type="file"
                          class="form-control"
                          id="attachment3"
                          aria-describedby="inputGroupFileAddon03"
                          aria-label="Upload"
                        />
                        <button
                          onclick={clearSelection}
                          type="button"
                          data-attachment-id="attachment3"
                          class="btn btn-danger"
                          title="Delete Attachment">
                          <i class="fas fa-trash" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer" align="right">
              <button id="submitButton" type="submit" class="btn btn-success" value="Submit Request">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                Submit Request
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function onPageReady() {
    toggleImpactAlert();
    loadRegionsAndUpdateDropdown('public', ['region']);

    $('.selectpicker').selectpicker('refresh');

    const attachments = $('#create-itsm-ticket-form').find('input[type=file]');
    attachments.on('change', function () {
      let sizeOfAttachments = [attachments[0].files, attachments[1].files, attachments[2].files]
        .filter(file => file.length > 0)
        .map(file => file[0]['size'])
        .reduce(function (a, b) {
          return a + b;
        }, 0);
      if (sizeOfAttachments >= 5900000) {
        swal({
          title: 'The sum of the attachments exceds the limit of 6 MB.',
          icon: 'warning',
          buttons: {
            okay: {
              text: 'Understood',
              value: true,
              visible: true,
            },
          },
        }).then(function () {
          $(':submit').attr('disabled', true);
        });
      } else {
        $(':submit').attr('disabled', false);
      }
    });

    const resetForm = () => {
      const formElement = document.getElementById('create-itsm-ticket-form');
      if (formElement) {
        formElement.reset();
        const selectpickers = $('.selectpicker');
        selectpickers.selectpicker('deselectAll');
        $('#service').val('public-cloud-framework:global');
        $('#impact').val('Minor');
        $('#urgency').val('Low');
        $('#contact-mode').val('Email');
        selectpickers.selectpicker('refresh');
      }
    };

    $('#create-itsm-ticket-form').on('submit', function (e) {
      e.preventDefault();
      addSpinner();
      addButtonSpinner();

      const selectedAccounts = $('#aws-account-id').val();
      const selectedRegions = $('#region').val();
      const description = convertInputToDescription(
        selectedAccounts,
        selectedRegions,
        $('#contact-mode').val(),
        $('#description').val()
      );
      const body = {
        incident_payload: {
          service: $('#service').val(),
          summary: $('#summary').val(),
          urgency: $('#urgency').val(),
          impact: $('#impact').val(),
          description: description,
        },
      };

      let attachedFiles = [attachments[0].files, attachments[1].files, attachments[2].files].filter(
        file => file.length > 0
      );

      if (attachedFiles.length > 0) {
        const promisedFiles = attachedFiles.map(file => {
          return pFileReader(file[0]).then(data => data);
        });

        Promise.all(promisedFiles).then(readFiles => {
          body.attachments = readFiles;
          submitIncident(body, resetForm);
        });
      } else {
        submitIncident(body, resetForm);
      }
    });
  }

  return [page, onPageReady];
}

function clearSelection() {
  const clearAttachmentButton = $(this);
  let attachmentID = clearAttachmentButton.data('attachment-id');
  $('#' + attachmentID).val('');
  $('#' + attachmentID).trigger('change');
}

/**
 * Update the available impact options depending on the selected AWS accounts and their account stages.
 * While minor and moderate incidents can be created for all account stages, it's only possible to create significant
 * and extensive incidents for prod accounts.
 */
function updateImpact() {
  // Collect all selected AWS accounts and check their account stage. Set prodSelected to true
  // if any of the selected AWS accounts has acccount stage prod.
  const accounts = $('#aws-account-id').find('option:selected');
  let prodSelected = false;
  accounts.each(id => {
    if ($(accounts[id]).attr('account_stage') === 'prod') {
      prodSelected = true;
    }
  });

  // Clear all options from the impact dropdown and add them again, based on the available options.
  // The attribute onlyProd is defining which impact options can be added only for prod accounts.
  const impactDropdown = $('#impact')[0];
  removeDisabledOptions(impactDropdown);

  const impactOptions = [
    {
      value: 'Minor',
      text: 'Minor/Localized',
      default: true,
    },
    {
      value: 'Moderate',
      text: 'Moderate/Limited',
    },
    {
      value: 'Significant',
      text: 'Significant/Large',
      onlyProd: true,
    },
    {
      value: 'Extensive',
      text: 'Extensive/Widespread',
      onlyProd: true,
    },
  ];

  // Add the available options to the impact dropdown
  impactOptions.forEach(item => {
    if (!item.onlyProd || (item.onlyProd && prodSelected)) {
      let opt = document.createElement('option');
      opt.value = item.value;
      opt.text = item.text;
      opt.selected = item.default ? true : false;
      impactDropdown.append(opt);
    }
  });

  // Refresh the selectpicker and trigger the update of the impact info text
  $(impactDropdown).selectpicker('refresh');
  toggleImpactAlert();
}

function toggleImpactAlert() {
  $('#impact-extensive-alert').hide();
  $('#impact-significant-alert').hide();
  $('#impact-moderate-alert').hide();
  $('#impact-minor-alert').hide();

  switch ($('#impact').val()) {
    case 'Extensive':
      $('#impact-dropdown-container').removeClass().addClass('col-6');
      $('#impact-extensive-alert').show();
      break;
    case 'Significant':
      $('#impact-dropdown-container').removeClass().addClass('col-6');
      $('#impact-significant-alert').show();
      break;
    case 'Moderate':
      $('#impact-dropdown-container').removeClass().addClass('col-6');
      $('#impact-moderate-alert').show();
      break;
    case 'Minor':
      $('#impact-dropdown-container').removeClass().addClass('col-6');
      $('#impact-minor-alert').show();
  }
}

function convertInputToDescription(accounts, regions, contactMode, description) {
  let result = 'AWS-Account(s): ' + accounts.join(', ') + '\r\n';
  result += 'Region(s): ' + regions.join(', ') + '\r\n';
  result += 'Preferred contact mode: ' + contactMode + '\r\n';
  result += '-------' + '\r\n' + description;
  return result;
}

function submitIncident(body, callback) {
  swal({
    title: 'Are you sure that you want to submit this Incident?',
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: false,
        visible: true,
      },
      okay: {
        text: 'Accept',
        value: true,
        visible: true,
      },
    },
  }).then(function (response) {
    if (response) {
      const responsePromise = createITSMTicket(body, 'submitButton');
      responsePromise.finally(() => {
        removeSpinners();
        callback();
      });
    } else {
      removeSpinners();
    }
  });
}

function pFileReader(file) {
  return new Promise(resolve => {
    const fr = new FileReader();
    fr.onload = function () {
      return resolve(fr.result);
    };
    fr.readAsDataURL(file);
  });
}
