import AccountDropdown from './jsx/components/forms/AccountDropdown';
import { addButtonSpinner, addSpinner, removeSpinners } from './js/portal/sidebar';
import { displayErrorPopup, displaySuccessPopup } from './js/portal/main';
import { baseApiAxios } from './js/portal/api';

export default function ClassicLoginOptOutPage() {
  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form name="classic-login-opt-out-form" id="classic-login-opt-out-form" method="POST">
          <div class="card form-card">
            <div class="card-header">Classic Login Opt-Out</div>
            <div class="card-body">
              <div id="form-error" class="alert alert-danger" style="display: none">
                <ul />
              </div>
              <div id="form-success" class="alert alert-success" style="display: none">
                <ul />
              </div>
              <div id="infobox" class="card infobox">
                <div class="card-body">
                  Choose the AWS account to disable the classic (IAM center) Login. This means you can no longer singin
                  into this account using the bmwawscli. You have to use the official AWS CLI to login. For PAM this
                  means, your account is badged to be governance conform.
                </div>
              </div>

              <div class="form-group row">
                <label for="aws-account-id" class="col-form-label">
                  AWS Account ID
                </label>
                <div class="col my-auto">
                  <AccountDropdown id="aws-account-id" label="none" short />
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row px-2">
                <div class="col" align="right">
                  <button type="submit" class="btn btn-success" id="submitButton" value="Order">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    Order
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function onPageReady() {
    $('#classic-login-opt-out-form')
      .on('submit', function (e) {
        e.preventDefault();
      })
      .validate({
        debug: false,
        submitHandler: function () {
          addSpinner();
          addButtonSpinner();
          const accountId = $('#aws-account-id').val();
          const orderPayload = {
            action: 'classic-login-opt-out',
            account_id: accountId,
          };
          baseApiAxios
            .createOrder(orderPayload)
            .then(response => {
              removeSpinners();
              const form = document.getElementById('classic-login-opt-out-form');
              displaySuccessPopup(response.message, 'Create Order');
              form.reset();
              $('.selectpicker').selectpicker('refresh');
              $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
            })
            .catch(error => {
              removeSpinners();
              displayErrorPopup(error);
            });
        },
      });
  }

  return [page, onPageReady];
}
